import { GET_DEX_DATA, GET_HODL_RATE, GET_ORDERBOOK_DATA } from '../types'

const INITIAL_STATE = {
  contractBalanceEth: 0,
  distributionsLength: 0,
  hodlTAddr: 0,
  hodlerCount: 0,
  makerAddr: 0,
  rates: 0,
  reserveBalanceHodlc: 0,
  top10OrderData: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEX_DATA:
      return { ...state, ...action.payload }
    case GET_HODL_RATE:
      return { ...state, ...action.payload }
    case GET_ORDERBOOK_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
