import React from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'

import {
  depositEth,
  withdrawEth,
  getUserBalances,
  getUserDistributionBalances,
  getDexData
} from '../../redux/actions'
import BalanceCard from '../../components/BalanceCard'
import EventList from '../../components/EventList'
import { withTranslation } from 'react-i18next'
import DisabledBanner from '../../components/DisableBanner'
import connector from '../../connector'
import networkConfig from '../../global-config'
import MarketInfo from '../../components/MarketInfo/MobileMarketInfo'
class Wallet extends React.Component {
  componentDidMount() {
    document.title = 'HODL DEX | Wallet'
    this.props.getDexData()
    this.props.getUserBalances(this.props.web3, this.props.account)
    this.props.getUserDistributionBalances(this.props.web3, this.props.account)
  }

  handleClick = () => {
    const { web3 } = this.props
    connector.withDrawFromOldDex(web3)
  }

  renderUpgrade = () => {
    if (this.props.account && this.props.oldEthBalance > 0) {
      return (
        <div className="upgrade">
          <div className="title">
            You still have {this.props.oldEthBalance} ETH in the old Dex.
          </div>
          <button
            className="btn upgrade-btn"
            onClick={() => this.handleClick()}
          >
            Withdraw
          </button>
        </div>
      )
    } else {
      return (
        <>
          <BalanceCard
            type="eth"
            claimAmount={this.props.ethDistAmt}
            balance={this.props.eth}
            USDbalance={this.props.ethinUSD}
            button1="wallet.balances.card.eth.btn1"
            button2="wallet.balances.card.eth.btn2"
            max={this.props.eth}
          />
          <BalanceCard
            type="hodlc"
            claimAmount={this.props.hodlDistAmt}
            balance={this.props.hodlc}
            max={this.props.hodlc}
            USDbalance={this.props.hodlcinUSD}
            button1="wallet.balances.card.hodlc.btn1"
            button2="wallet.balances.card.hodlc.btn2"
          />
          <BalanceCard
            type="hodlt"
            claimAmount={new BigNumber(0)}
            balance={this.props.hodlt}
            max={this.props.hodlt}
            redeemPower={this.props.redeemPower}
            button1="wallet.balances.card.hodlt.btn1"
            button2="wallet.balances.card.hodlt.btn2"
          />
        </>
      )
    }
  }

  render() {
    const { t } = this.props
    return (
      <div className="wallet-container">
        <div className="container">
          <MarketInfo
            reserveBalanceHodlc={this.props.reserveBalanceHodlc}
            rates={this.props.rates}
          />
          {!networkConfig.enableWalletFunction && <DisabledBanner />}
          <div className="dashboard-session">
            <h2 className="ui header session-header">
              {t('wallet.balances.title')}
            </h2>
            <div id="balance-cards" className="balance-cards">
              {this.renderUpgrade()}
            </div>
          </div>
          <div className="dashboard-session">
            {/*<EventList scope="my" defaultTimes={24} />*/}
            <EventList scope="my" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ wallet, blockchain, trade }) => {
  return {
    eth: wallet.eth,
    hodlc: wallet.hodlc,
    hodlt: wallet.hodlt,
    ethinUSD: wallet.ethinUSD,
    hodlcinUSD: wallet.hodlcinUSD,
    redeemPower: wallet.redeemPower,
    ethDistAmt: wallet.ethDistAmt,
    hodlDistAmt: wallet.hodlDistAmt,
    account: blockchain.account,
    web3: blockchain.web3,
    oldEthBalance: wallet.oldEthBalance,
    reserveBalanceHodlc: trade.reserveBalanceHodlc,
    rates: trade.rates
  }
}

export default connect(mapStateToProps, {
  depositEth,
  withdrawEth,
  getUserBalances,
  getUserDistributionBalances,
  getDexData
})(withTranslation()(Wallet))
