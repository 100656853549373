import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getTransaction } from '../../redux/actions'
import { nameDict, eventName } from '../../utils/txnDetail'
import history from '../../utils/history'
const TxnDetail = ({ match, web3, getTransaction, txn }) => {
  const [txnHash, setTxnHash] = useState('')
  useEffect(() => {
    setTxnHash(match.params.txnHash)
    getTransaction(web3, match.params.txnHash)
  }, [match, web3])
  return (
    <div className="txn-detail-container">
      <div className="container">
        <div className="dashboard-session">
          <div className="back-btn" onClick={() => history.goBack()}>
            <img
              width={30}
              src={require('../../assets/images/arrow.svg')}
              alt="arrow"
            />
          </div>
          <h2 className="ui header session-header">Transaction Detail</h2>
          <div className="data-container">
            <div className="item">
              <div className="key">Transaction Hash</div>
              <div className="value">{txnHash}</div>
            </div>
            {renderData(txn)}
          </div>
        </div>
      </div>
    </div>
  )
}

const renderData = (data) => {
  const arr = []
  for (const i in data) {
    arr.push(
      <div className="item">
        <div className="key">{nameDict[i]}</div>
        <div className="value">{renderValue(i, data[i])}</div>
      </div>
    )
  }
  return arr
}

const renderValue = (key, value) => {
  switch (key) {
    case 'event':
      return eventName[value]
    case 'gas':
      return value.toLocaleString('en-US')
    case 'gasPrice':
      return `${value[0]} Ether (${value[1]} Gwei)`
    default:
      return value
  }
}
const mapStateToProps = ({ blockchain, txn }) => {
  return {
    web3: blockchain.web3,
    txn
  }
}
export default connect(mapStateToProps, { getTransaction })(TxnDetail)
