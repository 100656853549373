import { GET_TRANSACTION } from '../types'

const INITIAL_STATE = {
  blockHash: 'Pending',
  blockNumber: 'Pending',
  from: '',
  gas: 0,
  gasPrice: '',
  to: '',
  event: '',
  HODLCvalue: '',
  gwei: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
