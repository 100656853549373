import axios from 'axios'
import { cleanData, cleanEOSData } from '../cleanData'
import {
  GET_ALL_EOS_ORDER,
  GET_ALL_EOS_TXN,
  GET_ALL_ETH_ORDER,
  GET_ALL_ETH_TXN
} from './types'

export const getAllETHOrder = () => async (dispatch) => {
  return axios
    .get(
      'https://hodlcommunity-project.github.io/HETHUSD-historicalData/historyData.json'
    )
    .then(async (res) => {
      const data = await cleanData(res.data)
      dispatch({
        type: GET_ALL_ETH_ORDER,
        payload: data.dailyEthData
      })
      dispatch({
        type: GET_ALL_ETH_TXN,
        payload: data.txnCount
      })
    })
    .catch((err) => console.log(err))
}

export const getAllEOSOrder = () => async (dispatch) => {
  return axios
    .get(
      'https://amazingshellyyy.com/HEOSUSD-historicalData-shelly/historical.json'
    )
    .then(async (res) => {
      const data = await cleanEOSData(res.data)
      dispatch({
        type: GET_ALL_EOS_ORDER,
        payload: data.dailyEosData
      })
      dispatch({
        type: GET_ALL_EOS_TXN,
        payload: data.txnCount
      })
    })
    .catch((err) => console.log(err))
}
