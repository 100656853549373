import { INIT_BLOCKCHAIN_ENVIRONMENT } from '../types'

const INITIAL_STATE = {
  account: 'loading',
  web3: null,
  metamask: {
    installed: false,
    connected: false,
    web3: null
  },
  walletConnect: {
    connected: false,
    web3: null
  },
  walletLink: {
    connected: false,
    web3: null
  },
  fortmatic: {
    connected: false,
    web3: null
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_BLOCKCHAIN_ENVIRONMENT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
