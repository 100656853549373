const INFURA_KEY = '94841089cf24457ebaab3d909f3558d6' // current key
// const INFURA_KEY = '66efafb168404dabb831b9aca307627c' // andy's key
const FORTMATIC_KEY = 'pk_live_35F705B0A0BC12FC'
const PORTIS_KEY = 'e0ca989f-bac5-403b-81f2-60a3173cb47d'
// both FORTMATIC and PORTIS key is using Shelly's accont

const globalConfig = {
  INFURA_KEY,
  FORTMATIC_KEY,
  PORTIS_KEY,
  metadata: {
    calculatorDisclaimer:
      'The calculator is provided for illustrative purpose only. Results are hypothetical and may not reflect the actual growth of your investments. Information obtained by using the calculator does not constitute legal, financial, or investment advice. The calculator is not a subtitute for consulting a qualified professional. We strongly recommend you seek the advice of a financial services professional before making any investment. The HODLCommunity foundation, its representatives and affiliates are not responsible for the consequences of any decisions or actions taken as a result of the information provided by the calculator.',
    socialURLs: {
      'FB-EN': 'https://www.facebook.com/groups/hodlcommodityproject',
      'FB-FR': 'https://www.facebook.com/groups/projethodlcommodity',
      twitter: 'https://twitter.com/HODLCommunity',
      'Telegram-EN': 'https://t.me/joinchat/FqxjYhnbKDqPBnfEETW0Jg',
      'Telegram-FR': 'https://t.me/joinchat/FqxjYhykPUIsHkuVc9AIRw',
      youtube: 'https://www.youtube.com/channel/UCJY94BXU6YXqm71xadymjVw',
      medium: 'https://hodlcommunity.medium.com'
    },
    otherURLs: {
      website: 'https://hodlcommunity.org/',
      termsOfUse:
        'https://hodlcommunity.org/wp-content/uploads/2020/12/HODLCommunity-Terms-of-Use.pdf',
      whitepaper:
        'https://drive.google.com/file/d/141XYEy_KD_DoqeV0p8fLH3PQ9LPw4w1C/view',
      metamask: 'https://metamask.io/download.html',
      trustwallet: 'https://trustwallet.com/deeplink/'
    }
  },
  enableWalletFunction: true,
  enableTrade: true,
  enableDistribution: true,
  enableCalculator: true,
  enableGraph: true,
  enableSwitch: true,
  eth: {
    abi: {
      v1: {
        hodldex: './contracts/eth/v1/HodlDex.json',
        hodlt: './contracts/eth/v1/HodlT.json',
        hodlc: ''
      },
      v2: {
        hodldex: './contracts/eth/v2/HodlDex.json',
        hodlt: './contracts/eth/v2/HTEthUsd.json',
        hodlc: './contracts/eth/v2/HTokenReserve.json'
      }
    },
    networks: {
      main: {
        rpcEndpoint: `wss://mainnet.infura.io/ws/v3/${INFURA_KEY}`,
        dexContractAddress: '0xC310644A0Cf1cAE7E240A48090c3cdB9Edb53941', // HodlDex Proxy
        tokenContractAddress: '0xd7aDCFD07745Aec302423e16b44090F6579D7c78', // HTEthUsd
        tokenReserveContractAddress:
          '0xcD10182e9fB7c2f301fA1ffBdEF416419fa4A3E5' // HTokenReserve Proxy
      },
      ropsten: {
        rpcEndpoint:
          'wss://ropsten.infura.io/ws/v3/8b887912220249c793e91f8d4f80e569',
        dexContractAddress: '0x2223e9e22230A66e9dfd957b1058914499Ef259b', // HodlDex Proxy
        tokenContractAddress: '0xe0940A5eB3A4d8C98D969839725bcCebF4220FcF', // HTEthUsd
        tokenReserveContractAddress:
          '0x2806BfC432aBa03F03E499201fe96ce7a271827D' // HTokenReserve Proxy
      },
      rinkeby: {
        rpcEndpoint: `wss://rinkeby.infura.io/ws/v3/${INFURA_KEY}`,
        dexContractAddress: 'set_this_address', // HodlDex Proxy
        tokenContractAddress: 'set_this_address', // HTEthUsd
        tokenReserveContractAddress: 'set_this_address' // HTokenReserve Proxy
      },
      goerli: {
        rpcEndpoint: `wss://goerli.infura.io/ws/v3/${INFURA_KEY}`,
        dexContractAddress: '0x18a37a6f7CE7ce6bA4E894Ef772A7357602b666E', // HodlDex Proxy
        tokenContractAddress: '0x5AbD9351F36C1Dfd163e8DFD8c121Eb0B4659680', // HTEthUsd
        tokenReserveContractAddress:
          '0x133b6D506601B5dd01678a11B8f95065767718e2' // HTokenReserve Proxy
      },
      private: require('./private-network.json') || {}
    }
  },
  eosLink: {
    main: 'https://eos.hodldex.io',
    test: 'https://jungle3testnet.hodldex.io'
  },
  apis: {
    main: '',
    goerli: 'https://goerli.api'
  },
  vtbdexUrl: '',
  hodlcPrice: '4.9899',
  notice:{
    enabled: true,
    heading : "Hodldex",
    message : "Smart contract address: 0xC310644A0Cf1cAE7E240A48090c3cdB9Edb53941 "
  }
}

export default globalConfig
