import React from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import networkConfig from '../../global-config'
import JoinModal from '../JoinUs/Modal'
import { connect } from 'react-redux'
import { triggerNavigator } from '../../redux/actions'
import DexSeletor from './DexSeletor'
import globalConfig from '../../global-config'
const navItem = [
  {
    tag: 'trade-page',
    route: '/trade',
    name: 'trade'
  },
  {
    tag: 'wallet-page',
    route: '/wallet',
    name: 'wallet'
  },
  {
    tag: 'explorer-page',
    route: '/explorer',
    name: 'explorer'
  },
  {
    tag: 'calculator-page',
    route: '/calculator',
    name: 'calculator'
  },
  {
    tag: 'graph-page',
    route: '/graph',
    name: 'graph'
  },
  {
    tag: 'join-us-page',
    route: '',
    name: 'joinUs'
  }
]

class Navigator extends React.Component {
  state = {
    showModal: false,
    width: window.innerWidth
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  renderLink = () => {
    const { t } = this.props
    return navItem.map((ele) => {
      if (
        (!networkConfig.enableCalculator && ele.name === 'calculator') ||
        (!networkConfig.enableGraph && ele.name === 'graph')
      ) {
        return ''
      } else if (ele.name === 'joinUs') {
        return (
          <div
            key={ele.route}
            className="item"
            onClick={() => {
              this.setState({ showModal: true })
              this.props.triggerNavigator(false)
            }}
          >
            <img
              alt={ele.tag}
              src={require(`../../assets/images/${ele.tag}.svg`)}
            />
            <p className="nav-title">{t(`navigator.${ele.name}`)}</p>
          </div>
        )
      } else {
        return (
          <NavLink
            key={ele.route}
            to={ele.route}
            className="item"
            activeClassName="active"
            onClick={() => this.props.triggerNavigator(false)}
          >
            <img
              alt={ele.tag}
              src={require(`../../assets/images/${ele.tag}.svg`)}
            />
            <p className="nav-title">{t(`navigator.${ele.name}`)}</p>
          </NavLink>
        )
      }
    })
  }

  getCurrentLanguage = () => {
    return localStorage.getItem('i18nextLng')
  }

  render() {
    const config = networkConfig.metadata.otherURLs
    const { t } = this.props
    return (
      <div className={`side-nav ${this.props.navigator.open}`}>
        <div className="item-container">
          <div className="top-group">
            <div className="nav-logo">
              <img
                alt="logo"
                src={require('../../assets/images/HODLC_LogoFiles-04.svg')}
                onClick={() =>
                  this.props.triggerNavigator(!this.props.navigator.open)
                }
              />
              <p className="logo-title">
                HODL DEX {globalConfig.enableSwitch && <DexSeletor />}
              </p>
            </div>
            {this.renderLink()}
          </div>
          <div className="bottom-group">
            <div className="info-container">
              <div className="info-item">
                <span className="info-dot"> &#9679;</span>
                <a
                  className="info-link"
                  href={config.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('navigator.footer.website')}
                </a>
              </div>
              <div className="info-item">
                <span className="info-dot"> &#9679;</span>
                <a
                  className="info-link"
                  href={config.termsOfUse}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('navigator.footer.termsOfUse')}
                </a>
              </div>
              <div className="info-item">
                <span className="info-dot"> &#9679;</span>
                <a
                  className="info-link"
                  href={config.whitepaper}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('navigator.footer.whitepaper')}
                </a>
              </div>
              <div className="info-item">
                <span className="info-dot"> &#9679;</span>
                <a
                  className="info-link"
                  href={config.metamask}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MetaMask
                </a>
              </div>
              <div className="info-item">
                <span className="info-dot"> &#9679;</span>
                <a
                  className="info-link"
                  href={config.trustwallet}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trust Wallet
                </a>
              </div>
            </div>
            {this.state.showModal && (
              <JoinModal
                activeStatus={this.state.showModal}
                hideModal={this.hideModal}
                t={this.props.t}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ navigator }) => {
  return {
    navigator
  }
}

export default connect(mapStateToProps, { triggerNavigator })(
  withTranslation()(Navigator)
)
