import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Modal = ({
  innerStyle,
  children,
  mode,
  size,
  modalHeader,
  hideModal,
  clickToClose,
  pressToClose
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27 && pressToClose) {
        hideModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [hideModal, pressToClose])
  return (
    <div className="dimmer-2" onClick={clickToClose && hideModal}>
      <div
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        className={`${mode} cus-modal ${size} ${innerStyle}`}
      >
        <div className="title-group">
          <div className="modal-title">{modalHeader}</div>
        </div>
        <div className="modal-inner-content">{children}</div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  mode: PropTypes.oneOf(['primary', 'error']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  clickToClose: PropTypes.bool,
  pressToClose: PropTypes.bool
}

export default Modal
