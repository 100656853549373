import {
  GET_ALL_ETH_ORDER,
  GET_ALL_PRICE,
  GET_ALL_ETH_TXN,
  GET_ALL_EOS_ORDER,
  GET_ALL_EOS_TXN
} from './types'

const INITIAL_STATE = {
  ETHVolume: [{ total: 0, timeStamp: 1 }],
  EOSVolume: [{ total: 0, timeStamp: 1 }],
  price: [{ timeStamp: 0, HODLprice: 0 }],
  ETHTxn: [{ count: 0, timeStamp: 0 }],
  EOSTxn: [{ count: 0, timeStamp: 0 }]
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ETH_ORDER:
      return { ...state, ETHVolume: action.payload }
    case GET_ALL_ETH_TXN:
      return { ...state, ETHTxn: action.payload }
    case GET_ALL_PRICE:
      return { ...state, price: action.payload }
    case GET_ALL_EOS_ORDER:
      return { ...state, EOSVolume: action.payload }
    case GET_ALL_EOS_TXN:
      return { ...state, EOSTxn: action.payload }
    default:
      return state
  }
}
