import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  claimEthDistribution,
  claimHodlDistribution
} from '../../redux/actions'
import networkConfig from '../../global-config'

import Modal from './Modal'
import history from '../../utils/history'
import ConnectModal from '../Profile/ConnectModal'

const actionMapping = {
  'wallet.balances.card.eth.btn1': 'Deposit',
  'wallet.balances.card.eth.btn2': 'Withdraw',
  'wallet.balances.card.hodlc.btn1': 'Trade',
  'wallet.balances.card.hodlc.btn2': 'To HODLT',
  'wallet.balances.card.hodlt.btn1': 'Approve',
  'wallet.balances.card.hodlt.btn2': 'To HODLC'
}

class Index extends React.Component {
  state = {
    showModal: false,
    action: null,
    showWalletModal: false
  }

  hideWalletModal = () => {
    this.setState({ showWalletModal: false })
  }

  showWalletModal = () => {
    this.setState({ showWalletModal: true })
  }

  showModal = async (e) => {
    const btnVal = actionMapping[e.target.getAttribute('value')]
    if (btnVal === 'Trade') {
      history.push('/trade')
    } else {
      if (!this.props.web3) return this.showWalletModal()
      const account = this.props.account
      if (!account) {
        this.showWalletModal()
      } else {
        this.setState({ showModal: true, action: btnVal })
      }
    }
  }

  hideModal = () => {
    this.setState({ showModal: false })
    history.push('/wallet')
  }

  drawerOpen = (balance) => {
    return balance.isGreaterThan('0') && networkConfig.enableDistribution
  }

  claimNow = (type) => {
    if (type === 'eth') return this.props.claimEthDistribution(this.props.web3)
    if (type === 'hodlc') {
      return this.props.claimHodlDistribution(this.props.web3)
    }
  }

  render() {
    const { t } = this.props
    const drawerClass =
      this.props.type === 'hodlt'
        ? ''
        : `drawer-open-${this.drawerOpen(this.props.claimAmount)}`
    return (
      <div className={`balance-card-outter-container ${drawerClass}`}>
        {this.props.type !== 'hodlt' && (
          <div className={`balance-card-background-drawer ${this.props.type}`}>
            {this.props.claimAmount.toFixed(18)} {this.props.type.toUpperCase()}
            <div
              className={`balance-card-claim-button ${this.props.type}`}
              onClick={() => this.claimNow(this.props.type)}
            >
              {t('wallet.balances.claim')}
            </div>
          </div>
        )}
        <div className="balance-card-container">
          <div className={`balance-card ${this.props.type}`}>
            <div className="top">
              <img
                alt=""
                src={require(`../../assets/images/${this.props.type}.svg`)}
              ></img>
              <div className="title">{this.props.type.toUpperCase()}</div>
            </div>
            <img
              alt=""
              className="logo-mark"
              src={require(`../../assets/images/${this.props.type}-lg.svg`)}
            ></img>
            <div className="bottom">
              <div className="number">
                <span className="data">{this.props.balance.toFixed(18)} </span>
              </div>
              {this.props.type !== 'hodlt' ? (
                <div className="number">
                  <span className="fiat">
                    ${this.props.USDbalance.toFixed(2)} USD
                  </span>
                </div>
              ) : (
                <div className="t-group">
                  <div className="number">
                    <b>{(this.props.redeemPower || 0).toFixed(4)}</b>{' '}
                    {t('wallet.balances.card.hodlt.approveToRedeem')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="btn-group">
            <div
              className={`ui fluid button wallet-action-button ${
                !networkConfig.enableWalletFunction && 'trade-disabled'
              }`}
              value={this.props.button1}
              onClick={
                networkConfig.enableWalletFunction ? this.showModal : () => {}
              }
            >
              {t(this.props.button1)}
            </div>
            <div
              className={`ui fluid button wallet-action-button ${
                !networkConfig.enableWalletFunction && 'trade-disabled'
              }`}
              value={this.props.button2}
              onClick={
                networkConfig.enableWalletFunction ? this.showModal : () => {}
              }
            >
              {t(this.props.button2)}
            </div>
          </div>
          {this.state.showModal && (
            <Modal
              hideModal={this.hideModal}
              type={this.props.type}
              actionName={this.state.action}
              max={
                this.state.action === 'To HODLC'
                  ? this.props.redeemPower
                  : this.props.max
              }
            />
          )}
          {this.state.showWalletModal && (
            <ConnectModal hideModal={this.hideWalletModal} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps, {
  claimEthDistribution,
  claimHodlDistribution
})(withTranslation()(Index))
