import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import config from '../../connector/config'
import web3instance from '../../connector/web3'

class Bottom extends React.Component {
  state = {
    currenBlockHeight: '...'
  }
  // goTo = async (action) => {
  //   if (action === 'twitter') {
  //     const pathname = this.props.location.pathname
  //     console.log('pathname')
  //     console.log(pathname)
  //     const twitterShare = encodeURIComponent(
  //       `${this.props.t('bottom.twitterShare')} ${
  //         window.location.origin
  //       }/#${pathname}`
  //     )
  //     const url = `https://twitter.com/intent/tweet?text=${twitterShare}`
  //     window.open(url)
  //   }
  // }

  componentDidMount() {
    const localweb3 = web3instance(this.props.web3)
    localweb3.eth.getBlockNumber((error, blockNumber) => {
      if (!error) {
        this.setState({
          currenBlockHeight: blockNumber
        })
      }
    })
    if (localweb3) {
      localweb3.eth.subscribe('newBlockHeaders', (error, blockHeader) => {
        if (!error) {
          this.setState({
            currenBlockHeight: blockHeader.number
          })
        }
      })
    }
  }

  render() {
    let { HODLDEX_ADDR, network } = config.refreshBlockchainInfraInfo()
    const hodlDexInfraInfo = JSON.parse(
      localStorage.getItem('hodlDexInfraInfo')
    ) || {
      blockchain: 'eth',
      version: 'v2',
      network: process.env.REACT_APP_DEFAULT_NETWORK
    }
    const willReload = hodlDexInfraInfo.network !== network
    if (willReload) {
      setTimeout(() => {
        window.location.reload()
      }, 300)
    }
    network = willReload
      ? `fetching ${hodlDexInfraInfo.network}`
      : hodlDexInfraInfo.network
    const networkForURL = network === 'main' ? 'www' : network
    const url = `https://${networkForURL}.etherscan.io/address/${HODLDEX_ADDR}`
    const networkUnit = network === 'main' ? 'net' : ' testnet'
    return (
      <div className={`info-nav ${network}`}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {`${hodlDexInfraInfo.blockchain.toUpperCase()} ${network}${networkUnit} #${
            this.state.currenBlockHeight
          }`}
        </a>
        {/*<div onClick={() => this.goTo('twitter')}>share HODL</div>*/}
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    blockchain,
    web3: blockchain.web3
  }
}
const withRouterBottom = withRouter((props) => <Bottom {...props} />)

export default connect(mapStateToProps)(withTranslation()(withRouterBottom))
