import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import connector from '../../connector'
import web3instance from '../../connector/web3'
import ExplorerMarketInfo from '../MarketInfo/ExplorerMarketInfo'

import './styles.scss'
const ONE_HOUR = 4 * 60

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      height: 10000,
      from: 0,
      times: this.props.defaultTimes || 168,
      loading: true,
      currentUser: ''
    }
  }

  componentDidMount = async () => {
    const web3 = web3instance(this.props.web3)
    this.setState({ currentUser: this.props.account })
    const height = await web3.eth.getBlockNumber()
    const from = height - ONE_HOUR * this.state.times // past 24 hrs
    const filter = [from, 'latest', this.props.scope]
    this.setState({ height, from })
    const evnts = await connector.getEvents(this.props.web3, ...filter)
    if (evnts.length >= 0) this.setState({ loading: false })
    this.setState({ data: evnts })
  }

  renderAmount = (price) => {
    const i18nextLng = localStorage.getItem('i18nextLng')
    const arr = price.toString().split('.')
    arr[1] = arr[1] ? arr[1] : '0'
    const firstPart = BigNumber(arr[0]).toLocaleString(i18nextLng)
    if (isNaN(firstPart)) return <></>
    return (
      <span>
        {firstPart}
        <span className="decimal">.</span>
        <span className="light">
          {BigNumber(`0.${arr[1]}`).toFixed(18).split('.')[1]}
        </span>
      </span>
    )
  }

  renderList = () => {
    const { t } = this.props
    if (this.state.data.length > 0) {
      return this.state.data.map((ele) => {
        return (
          <div className="event-container event-item" key={ele.transactionHash}>
            <div className="ui stackable padded grid">
              <div className="three wide column hash">
                {/* <a
                  href={ele.txlink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className=""
                >
                  {ele.transactionHash}
                </a>
                {/* <Link to={`/explorer/${ele.transactionHash}`}>
                  {ele.transactionHash}
                </Link> */}
              </div>
              <div className="three wide column hash">
                <a
                  href={ele.userAddrlink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className=""
                >
                  <span
                    className={`${
                      this.props.account === ele.userAddr
                    }-user-address`}
                  >
                    {ele.userAddr}
                  </span>
                </a>
              </div>
              <div className="two wide column">
                <a
                  href={ele.blocklink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block-number"
                >
                  {ele.blockNumber}
                </a>
              </div>
              <div className="three wide column">
                {' '}
                <div className={`ui label action-label ${ele.type}`}>
                  {t(`eventList.list.content.type.${ele.type}`)}
                </div>
              </div>
              <div className="five wide column">
                <div className="amount">
                  <span> {this.renderAmount(ele.amount)} </span>
                  <span className="unit-span">{ele.unit}</span>
                </div>
              </div>
            </div>
          </div>
        )
      })
    } else {
      return (
        <div className="event-container event-item no-event">
          {t('eventList.list.noEvents')}
        </div>
      )
    }
  }

  changeDays = async (times) => {
    const web3 = web3instance(this.props.web3)
    this.setState({ times, data: [], loading: true })
    if (times === 'all') {
      const filter = [0, 'latest', this.props.scope]
      const evnts = await connector.getEvents(this.props.web3, ...filter)
      if (evnts.length >= 0) this.setState({ loading: false })
      this.setState({ data: evnts })
    } else {
      const height = await web3.eth.getBlockNumber()
      const from = height - ONE_HOUR * times
      this.setState({ from })
      const evnts = await connector.getEvents(
        this.props.web3,
        from,
        'latest',
        this.props.scope
      )
      if (evnts.length >= 0) this.setState({ loading: false })
      this.setState({ data: evnts })
    }
  }

  render() {
    const { t, scope } = this.props
    return (
      <div>
        {scope === 'global' && (
          <div className="dashboard-session">
            <div className="time-selector explorer">
              <select
                value={this.state.times}
                id="days"
                name="days"
                onChange={(e) => this.changeDays(e.target.value)}
              >
                <option value="1">{t('eventList.lengthOptions.1hour')}</option>
                <option value="6">{t('eventList.lengthOptions.6hour')}</option>
                <option value="24">
                  {t('eventList.lengthOptions.24hour')}
                </option>
                <option value="72">
                  {t('eventList.lengthOptions.72hour')}
                </option>
                <option value="168">
                  {t('eventList.lengthOptions.168hour')}
                </option>
                <option value="all">{t('eventList.lengthOptions.all')}</option>
              </select>
            </div>
          </div>
        )}
        {scope === 'global' && (
          <div className="dashboard-session">
            <h2 className="ui header session-header">Insights</h2>
            <ExplorerMarketInfo events={this.state.data} />
          </div>
        )}
        <div className="dashboard-session event-list-component">
          <h2 className="ui header session-header">
            {t(`eventList.title.${this.props.scope}`, {
              number: this.state.data.length
            })}
          </h2>
          <div className="time-selector">
            {scope !== 'global' && (
              <select
                value={this.state.times}
                id="days"
                name="days"
                onChange={(e) => this.changeDays(e.target.value)}
              >
                <option value="1">{t('eventList.lengthOptions.1hour')}</option>
                <option value="6">{t('eventList.lengthOptions.6hour')}</option>
                <option value="24">
                  {t('eventList.lengthOptions.24hour')}
                </option>
                <option value="72">
                  {t('eventList.lengthOptions.72hour')}
                </option>
                <option value="168">
                  {t('eventList.lengthOptions.168hour')}
                </option>
                <option value="all">{t('eventList.lengthOptions.all')}</option>
              </select>
            )}
          </div>
          <div className="event-list">
            <div className="event-container history-header">
              <div className="ui grid">
                <div className="three wide column left-column">
                  <div className="hash">
                    {t('eventList.list.header.txnHash')}
                  </div>
                </div>
                <div className="three wide column left-column">
                  <div className="hash">
                    {t('eventList.list.header.address')}
                  </div>
                </div>
                <div className="two wide column">
                  <div className="block-number">
                    {t('eventList.list.header.block')}
                  </div>
                </div>
                <div className="three wide column">
                  {' '}
                  <div className="type">
                    {t('eventList.list.header.action')}
                  </div>
                </div>
                <div className="five wide column"></div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="loading-component">
                <div className="sk-chase">
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                </div>
              </div>
            ) : (
              <div>{this.renderList()}</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps)(withTranslation()(Index))
