import React from 'react'
import Modal from '../Modal/BaseModal'
import networkConfig from '../../global-config'

const JoinModal = ({ hideModal, t }) => {
  const { socialURLs } = networkConfig.metadata
  return (
    <Modal
      mode="primary"
      size="sm"
      className="join-modal small"
      hideModal={hideModal}
      modalHeader={t('joinUs.title')}
      clickToClose
      pressToClose
      innerStyle="join-modal"
    >
      <div className="join-modal-inner">
        <div className="social-link-title">{t('joinUs.content.title')}</div>
        <div className="social-link-container">
          <a
            className="social-link"
            href={socialURLs['FB-EN']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook(EN)
          </a>
          <a
            className="social-link"
            href={socialURLs['FB-FR']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook(FR)
          </a>
          <a
            className="social-link"
            href={socialURLs.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            className="social-link"
            href={socialURLs['Telegram-EN']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram(EN)
          </a>
          <a
            className="social-link"
            href={socialURLs['Telegram-FR']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram(FR)
          </a>
          <a
            className="social-link"
            href={socialURLs.youtube}
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
          <a
            className="social-link"
            href={socialURLs.medium}
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium
          </a>
        </div>
        <button
          onClick={hideModal}
          className="btn md secondary right"
          type="submit"
          style={{ marginTop: '1rem' }}
        >
          {t('joinUs.content.close')}
        </button>
      </div>
    </Modal>
  )
}

export default JoinModal
