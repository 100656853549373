import BigNumber from 'bignumber.js'
const DecayPeriod = 10957
const Start = 100 / 100
const End = 5 / 100
const DailyAPRDecay = Math.pow(1 + (End - Start), 1 / DecayPeriod)
const contractStartDay = new Date('10/24/2020')
const DPRStart = Math.pow(2, 1 / 365)
const DPREnd = Math.pow(1.05, 1 / DecayPeriod)
const DPRDecay = Math.pow(1 + (DPREnd - DPRStart), 1 / DecayPeriod)

const calDPR = (day) => {
  const preAPR = Math.pow(DailyAPRDecay, day - 2)
  const preEDCR = Math.pow(Start * preAPR + 1, 1 / 365) - 1
  return preEDCR * DPRDecay
}

export const HODLCPriceSimulate = (
  amount,
  currentHODLCPrice,
  HODLCount,
  length,
  avgTransaction = 500,
  currentTime,
  currency
) => {
  const daysDiff = Math.round(
    (currentTime - contractStartDay.valueOf()) / (1000 * 60 * 60 * 24)
  )
  let count = daysDiff
  let calHODLPrice = currentHODLCPrice
  const chartData = []
  let value = 0
  while (count < length + daysDiff) {
    count++
    calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
    if (currency === 'USD') {
      value = calHODLPrice * HODLCount
      chartData.push({
        timeLength: `${count - daysDiff} days`,
        value,
        growth: value - amount,
        cost: amount,
        currentHODLCPrice: calHODLPrice
      })
    } else {
      value = amount * calHODLPrice
      chartData.push({
        timeLength: `${count - daysDiff} days`,
        value,
        growth: value - amount * currentHODLCPrice,
        cost: amount * currentHODLCPrice,
        currentHODLCPrice: calHODLPrice
      })
    }
  }
  return chartData
}
export const HODLCSimpleCashOut = (
  amount,
  currentHODLCPrice,
  length,
  avgTransaction = 500,
  currentTime,
  currency,
  timePeriod,
  timeString
) => {
  const daysDiff = Math.round(
    (currentTime - contractStartDay.valueOf()) / (1000 * 60 * 60 * 24)
  )
  let count = daysDiff
  let calHODLPrice = currentHODLCPrice
  const chartData = []
  let HODLCount = currency === 'USD' ? amount / currentHODLCPrice : amount
  let value = currency === 'USD' ? amount : amount * currentHODLCPrice
  let cost = currency === 'USD' ? amount : amount * currentHODLCPrice
  let cashOutHODLAmount = 0
  let accCashOutValue = 0
  let accCashOutHODLAmt = 0
  if (count === daysDiff) {
    chartData.push({
      timeLength: 0,
      value,
      growth: 0,
      cost: cost,
      rate: currentHODLCPrice,
      HODLCount,
      accCashOutValue
    })
  }
  while (count < length + daysDiff) {
    count++
    calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
    if ((count - daysDiff) % timePeriod === 0 && count - daysDiff !== 0) {
      // the cashout day
      if (currency === 'USD') {
        value = calHODLPrice * HODLCount
        const cashOutValue = value - cost
        cashOutHODLAmount = cashOutHODLAmount + (value - cost) / calHODLPrice
        const valueAfterCashout = cost
        HODLCount = valueAfterCashout / calHODLPrice
        accCashOutValue = accCashOutValue + cashOutValue
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value,
          valueAfterCashout,
          growth: value - cost,
          cost,
          currentHODLCPrice: calHODLPrice,
          cashOutHODLAmount,
          accCashOutValue,
          cashOutValue,
          HODLCount
        })
      } else {
        value = HODLCount * calHODLPrice
        cashOutHODLAmount = (value - cost) / calHODLPrice
        HODLCount = HODLCount - cashOutHODLAmount
        accCashOutValue = accCashOutValue + cashOutHODLAmount * calHODLPrice
        accCashOutHODLAmt = accCashOutHODLAmt + cashOutHODLAmount
        const valueAfterCashout = value - cashOutHODLAmount * calHODLPrice
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value,
          valueAfterCashout,
          growth: value - cost,
          cost: cost,
          currentHODLCPrice: calHODLPrice,
          cashOutHODLAmount,
          accCashOutValue,
          HODLCount,
          cashOutValue: value - cost,
          accCashOutHODLAmt
        })
      }
    } else {
      if (currency === 'USD') {
        value = HODLCount * calHODLPrice
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value,
          HODLCount,
          valueAfterCashout: value,
          growth: value - amount,
          cost: amount,
          cost2: cost,
          currentHODLCPrice: calHODLPrice,
          cashOutamount: 0,
          accCashOutValue,
          cashOutValue: 0
        })
      } else {
        value = HODLCount * calHODLPrice
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value,
          valueAfterCashout: value,
          growth: value - cost,
          cost: cost,
          currentHODLCPrice: calHODLPrice,
          accCashOutValue,
          cashOutamount: 0,
          cashOutValue: 0,
          HODLCount,
          accCashOutHODLAmt
        })
      }
    }
  }
  return chartData
}

export const dailyDeposit = (
  depositValue,
  lengthInDays,
  avgTransaction = 500,
  currentTime,
  timePeriod,
  timeString,
  currentHODLCPrice,
  currency
) => {
  const chartData = []
  const daysDiff = Math.round(
    (currentTime - contractStartDay.valueOf()) / (1000 * 60 * 60 * 24)
  )
  let count = daysDiff
  let HODLCount =
    currency === 'USD' ? depositValue / currentHODLCPrice : depositValue
  let cost = currency === 'USD' ? depositValue : HODLCount * currentHODLCPrice
  if (count === daysDiff) {
    chartData.push({
      timeLength: 0,
      value: cost,
      growth: 0,
      rate: currentHODLCPrice,
      HODLCount: HODLCount,
      cost: cost
    })
  }
  let calHODLPrice = currentHODLCPrice
  while (count < lengthInDays + daysDiff + 1) {
    count++
    const countOfDeposit = Math.floor((count - daysDiff) / timePeriod) + 1
    calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
    if (count === daysDiff) {
      chartData.push({
        timeLength: 0,
        value:
          currency === 'USD' ? depositValue : HODLCount * currentHODLCPrice,
        growth: 0,
        cost: cost,
        rate: currentHODLCPrice
      })
    }
    if (count === lengthInDays + daysDiff + 1) {
      // the last day - no deposit
      if (currency === 'USD') {
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: Number(HODLCount) * calHODLPrice,
          growth:
            Number(HODLCount) * calHODLPrice -
            (countOfDeposit - 1) * depositValue,
          rate: calHODLPrice,
          HODLCount: Number(HODLCount),
          depositHODLCount: BigNumber(depositValue)
            .dividedBy(calHODLPrice)
            .toFixed(18),
          cost: (countOfDeposit - 1) * depositValue
        })
      } else {
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: (countOfDeposit - 1) * depositValue * calHODLPrice,
          growth: (countOfDeposit - 1) * depositValue * calHODLPrice - cost,
          rate: calHODLPrice,
          HODLCount: (countOfDeposit - 1) * depositValue,
          cost: cost
        })
      }
    } else {
      // other days besides the last day - add deposit
      if (currency === 'USD') {
        cost = cost + depositValue
        HODLCount = HODLCount + depositValue / calHODLPrice
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: Number(HODLCount) * calHODLPrice,
          growth:
            Number(HODLCount) * calHODLPrice - countOfDeposit * depositValue,
          rate: calHODLPrice,
          HODLCount: Number(HODLCount),
          depositHODLCount: BigNumber(depositValue)
            .dividedBy(calHODLPrice)
            .toFixed(18),
          cost: countOfDeposit * depositValue
        })
      } else {
        cost = cost + depositValue * calHODLPrice
        HODLCount = HODLCount + depositValue
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: countOfDeposit * depositValue * calHODLPrice,
          growth: countOfDeposit * depositValue * calHODLPrice - cost,
          rate: calHODLPrice,
          HODLCount: countOfDeposit * depositValue,
          cost: cost
        })
      }
    }
  }
  return chartData
}

export const calRecurring = (
  depositValue,
  lengthInDays,
  avgTransaction = 500,
  currentTime,
  timePeriod,
  timeString,
  currentHODLCPrice,
  currency
) => {
  const chartData = []
  const daysDiff = Math.round(
    (currentTime - contractStartDay.valueOf()) / (1000 * 60 * 60 * 24)
  )
  let count = daysDiff
  let HODLCount =
    currency === 'USD' ? depositValue / currentHODLCPrice : depositValue
  let cost = currency === 'USD' ? depositValue : HODLCount * currentHODLCPrice
  if (count === daysDiff) {
    chartData.push({
      timeLength: 0,
      value: cost,
      growth: 0,
      cost: cost,
      rate: currentHODLCPrice
    })
  }
  let calHODLPrice = currentHODLCPrice
  let accCost = HODLCount * currentHODLCPrice
  while (count < lengthInDays + daysDiff + 1) {
    count++
    //  how far away from the current, if it is a month/week/day away, then deposit
    if (
      (count - daysDiff) % timePeriod === 0 &&
      count === daysDiff + lengthInDays + 1
    ) {
      const countOfDeposit = Math.ceil((count - daysDiff) / timePeriod)
      calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
      // the last day
      if (currency === 'USD') {
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value: HODLCount * calHODLPrice,
          growth: HODLCount * calHODLPrice - countOfDeposit * depositValue,
          cost: countOfDeposit * depositValue,
          rate: calHODLPrice
        })
      } else {
        accCost = accCost + depositValue * calHODLPrice
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value: countOfDeposit * depositValue * calHODLPrice,
          growth: countOfDeposit * depositValue * calHODLPrice - cost,
          cost: cost,
          rate: calHODLPrice,
          HODLCount: HODLCount
        })
      }
    } else if (
      (count - daysDiff) % timePeriod === 0 &&
      count !== daysDiff + lengthInDays - 1
    ) {
      // deposit day
      calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
      if (currency === 'USD') {
        cost = cost + depositValue
        HODLCount = HODLCount + depositValue / calHODLPrice
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: HODLCount * calHODLPrice,
          growth: HODLCount * calHODLPrice - cost,
          cost: cost,
          rate: calHODLPrice,
          HODLCount: HODLCount
        })
      } else {
        cost = cost + depositValue * calHODLPrice
        HODLCount = HODLCount + depositValue
        chartData.push({
          timeLength: `${
            Math.floor(count - daysDiff) / timePeriod
          } ${timeString}`,
          value: HODLCount * calHODLPrice,
          growth: HODLCount * calHODLPrice - cost,
          cost: cost,
          rate: calHODLPrice,
          HODLCount: HODLCount
        })
      }
    } else {
      // days beside deposit day
      let countOfDeposit = Math.ceil((count - daysDiff) / timePeriod)
      calHODLPrice = calHODLPrice * (1 + calDPR(count)) + avgTransaction / 10000
      if (countOfDeposit > lengthInDays / timePeriod) {
        countOfDeposit = Math.floor(lengthInDays / timePeriod)
      }
      if (currency === 'USD') {
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value: HODLCount * calHODLPrice,
          growth: HODLCount * calHODLPrice - cost,
          cost: cost,
          rate: calHODLPrice,
          HODLCount: HODLCount
        })
      } else {
        chartData.push({
          timeLength: `${count - daysDiff} days`,
          value: HODLCount * calHODLPrice,
          growth: HODLCount * calHODLPrice - cost,
          cost: cost,
          rate: calHODLPrice,
          HODLCount: HODLCount
        })
      }
    }
  }
  return chartData
}
