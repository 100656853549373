import React, { useEffect } from 'react'
import history from '../../utils/history'

const Ambassador = ({ match }) => {
  useEffect(() => {
    console.log('ambassadorID', match.params.ambassadorID)
    if (match.params.ambassadorID) {
      localStorage.setItem('ambassadorID', match.params.ambassadorID)
    }
    history.replace('/')
  }, [])
  return <div className="container">hi is ambassadorID</div>
}

export default Ambassador
