import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ConnectModal from '../Profile/ConnectModal'
import i18n from 'i18next'
import MarketInfo from '../MarketInfo'
import { triggerNavigator } from '../../redux/actions'
import DexSeletor from './DexSeletor'
import globalConfig from '../../global-config'
const Loader = () => (
  <div className="loading-component navbar">
    <div className="sk-chase">
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  </div>
)

class Navbar extends React.Component {
  state = {
    showModal: false,
    loadingWallet: true
  }

  componentDidMount() {
    this.myLoadingWallet = setTimeout(async () => {
      this.setState({ loadingWallet: false })
    }, 1000)
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  renderCurrentWallet = (account) => {
    const acc = account.length
    if (account === 'loading') {
      return (
        <div
          className="item connect-item"
          onClick={() => this.setState({ showModal: true })}
        >
          <Loader />
        </div>
      )
    } else {
      if (acc > 10) {
        return (
          <div
            className="item connect-item"
            onClick={() => this.setState({ showModal: true })}
          >
            <span className="non-mobile-only">
              {account.slice(0, 6)}...{account.slice(acc - 5, acc)}
            </span>
            <span className="mobile-only">{account.slice(0, 6)}</span>
          </div>
        )
      } else {
        return (
          <div
            className="item connect-item empty-wallet"
            onClick={() => this.setState({ showModal: true })}
          >
            <span className="non-mobile-only">
              {this.props.t('wallet-connect.btn')}
            </span>
            <span className="mobile-only">
              {this.props.t('wallet-connect.mobile-btn')}
            </span>
          </div>
        )
      }
    }
  }

  getCurrentLanguage = () => {
    return localStorage.getItem('i18nextLng')
  }

  render() {
    return (
      <div className="top-nav">
        <div className="title non-mobile-only">
          HODL DEX {globalConfig.enableSwitch && <DexSeletor />}
        </div>

        <div
          className="title mobile-only mobile-logo"
          onClick={() => window.location.reload(true)}
        >
          <img
            alt="logo"
            src={require('../../assets/images/HODLC_LogoFiles-04.svg')}
          />
        </div>
        <div className="left-group">
          <MarketInfo
            reserveBalanceHodlc={this.props.reserveBalanceHodlc}
            rates={this.props.rates}
          />
        </div>
        <div className="right-group">
          <div className="item">
            <select
              id="language-dropdown"
              value={this.getCurrentLanguage()}
              className="language-dropdown"
              name="language"
              onChange={(e) => i18n.changeLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="fr">Français</option>
              <option value="de">Deutsch</option>
              <option value="es">Español</option>
              <option value="fil">Filipino</option>
              <option value="pt">Português</option>
              <option value="ru">Русский</option>
              <option value="ta_IN">தமிழ்</option>
              <option value="zh_CN">简体中文</option>
              <option value="zh_TW">繁體中文</option>
            </select>
          </div>
          {this.renderCurrentWallet(this.props.account)}
          <div
            className="item humberger mobile-only"
            onClick={() =>
              this.props.triggerNavigator(!this.props.navigator.open)
            }
          >
            <img
              src={require('../../assets/images/hamburger-button.svg')}
              alt="humberger"
            />
          </div>
          {this.state.showModal && <ConnectModal hideModal={this.hideModal} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ trade, blockchain, navigator }) => {
  return {
    reserveBalanceHodlc: trade.reserveBalanceHodlc,
    rates: trade.rates,
    account: blockchain.account,
    web3: blockchain.web3,
    navigator
  }
}

export default connect(mapStateToProps, { triggerNavigator })(
  withTranslation()(Navbar)
)
