import React from 'react'
import ReactDOM from 'react-dom'
import { withTranslation } from 'react-i18next'

class CancelModal extends React.Component {
  render() {
    const t = this.props.t
    return ReactDOM.createPortal(
      <div
        className="ui dimmer modals visible active"
        onClick={() => this.props.hideModal()}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="ui small modal visible active main-modal"
        >
          <div className="ui top attached big label modal-title">
            {t('trade.modal.header')}
          </div>

          <div className="modal-body">
            <p className="cancel-content">{t('trade.modal.content')}</p>

            <div className="modal-button-group">
              <button
                className="ui button bg-modal-button cancel"
                onClick={() => this.props.hideModal()}
              >
                {t('trade.modal.no')}
              </button>
              <button
                className="ui button bg-modal-button highlight"
                onClick={() => this.props.cancelOrder(this.props.id)}
              >
                {t('trade.modal.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  }
}

export default withTranslation()(CancelModal)
