import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { HashRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import './assets/scss/style.scss'
import App from './pages/App'
import reducers from './redux/reducers'
import history from './utils/history'
import i18n from './utils/i18n'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Suspense>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
)
