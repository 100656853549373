import React, { useState, useEffect } from 'react'
import Modal from '../../components/Modal/BaseModal'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import config from '../../global-config'
import { Simple, Recurring, CashOut } from '../../shared-components/calculator'
import web3instance from '../../connector/web3'
import connector from '../../connector'
import history from '../../utils/history'
const AVERAGE_LENGTH = 7

const Calculator = ({ web3, t, match, rates }) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(true)
  const [avgTransaction, setAvgTransaction] = useState('...(calculating)')

  useEffect(() => {
    document.title = 'HODL DEX | Calculator'
  }, [])

  useEffect(() => {
    async function getEvents() {
      if (avgTransaction > 0) return
      const localweb3 = web3instance(web3)
      const height = await localweb3.eth.getBlockNumber()
      const from = height - 4 * 60 * 24 * 7 // past 7 days
      const filter = [from, 'latest', 'global']
      const evnts = await connector.getBuyEvents(localweb3, ...filter)
      const avg = Number(evnts / AVERAGE_LENGTH).toFixed(2)
      setAvgTransaction(avg)
    }
    setTimeout(() => {
      getEvents()
    }, 500)
  }, [avgTransaction, rates])

  return (
    <div className="calulator-component container-fluid">
      {showDisclaimerModal && (
        <Modal
          mode="primary"
          size="sm"
          clickToClose
          pressToClose
          modalHeader={t('calculator.disclaimer.title')}
          hideModal={() => setShowDisclaimerModal(false)}
        >
          <div className="disclaimer-paragraph">
            {config.metadata.calculatorDisclaimer}
          </div>
          <div className="button-group">
            <button
              onClick={() => history.push('/')}
              className="btn sm grey md"
              style={{ margin: 0 }}
            >
              {t('calculator.disclaimer.cancel')}
            </button>
            <button
              onClick={() => setShowDisclaimerModal(false)}
              className="btn secondary md"
            >
              {t('calculator.disclaimer.agree')}
            </button>
          </div>
        </Modal>
      )}
      <div className="session-body">
        <div className="ui header session-header">{t('calculator.title')}</div>
        <div className="calculator-type-switcher">
          <Link
            to={`${match.url}/simple`}
            className={`item hodling ${window.location.href.includes(
              'simple'
            )}`}
          >
            <div>{t('calculator.mode.simple')}</div>
          </Link>
          <Link
            className={`item recurring ${window.location.href.includes(
              'recurring'
            )}`}
            to={`${match.url}/recurring`}
          >
            <div>{t('calculator.mode.resurring')}</div>
          </Link>
          <Link
            className={`item cashout ${window.location.href.includes(
              'cashout'
            )}`}
            to={`${match.url}/cashout`}
          >
            <div>{t('calculator.mode.cashOut')}</div>
          </Link>
        </div>
        <div>
          <Switch>
            <Route exact path={`${match.path}`}>
              <Redirect to={`${match.path}/simple`} />
            </Route>
            <Route
              path={`${match.path}/simple`}
              component={() => (
                <Simple
                  avgDay={AVERAGE_LENGTH}
                  avgTransaction={avgTransaction}
                  rates={rates}
                />
              )}
            />
            <Route
              path={`${match.path}/recurring`}
              component={() => (
                <Recurring
                  avgDay={AVERAGE_LENGTH}
                  avgTransaction={avgTransaction}
                  rates={rates}
                />
              )}
            />
            <Route
              path={`${match.path}/cashout`}
              component={() => (
                <CashOut
                  avgDay={AVERAGE_LENGTH}
                  avgTransaction={avgTransaction}
                  rates={rates}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Calculator)
