import React, { useState, useEffect, useCallback } from 'react'
import calculator from './util/calculator'
import _ from 'lodash'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { useTranslation, Trans } from 'react-i18next'

const CALCULATOR_MODE = 'cashOut'
const SimpleCashOutCalculator = ({ avgDay = 7, avgTransaction, rates }) => {
  const [amount, setAmount] = useState(10000)
  const [currency, setCurrency] = useState('USD')
  const [length, setLength] = useState(365)
  const [cashOutPeriod, setCashOutPeriod] = useState('weekly')
  const [transactions, setTransactions] = useState(avgTransaction)
  const [totalaccCashOutValue, setTotalaccCashOutValue] = useState(0)
  const [currentHODLCount, setCurrentHODLCount] = useState(0)
  const [avgCashOutValue, setAvgCashOutValue] = useState(0)
  const [chartData, setChartData] = useState([])
  const { t } = useTranslation()

  const getResult = () => {
    const currentDate = new Date()
    if (rates > 0) {
      const result =
        calculator(
          amount,
          length,
          transactions,
          currentDate.valueOf(),
          CALCULATOR_MODE,
          null,
          rates,
          currency,
          cashOutPeriod
        ) || 0
      const totalaccCashOutValue = result.totalaccCashOutValue
      const avgCashOutValue = result.avgCashOutValue
      const currentHODLCount = result.currentHODLCount
      const chartData = result.chartData
      setChartData(chartData)
      setTotalaccCashOutValue(totalaccCashOutValue)
      setAvgCashOutValue(avgCashOutValue)
      setCurrentHODLCount(currentHODLCount)
    }
  }
  const callBackGetResult = useCallback(_.debounce(getResult, 1000), [
    amount,
    length,
    transactions,
    currency,
    cashOutPeriod
  ])
  useEffect(() => {
    callBackGetResult(amount, length, transactions, currency, cashOutPeriod)
  }, [amount, length, transactions, currency, cashOutPeriod, callBackGetResult])

  return (
    <>
      <div className="info-session">
        <form>
          <div>
            <>
              {t('calculator.cashOut.form.inputAmount.1')}
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="HODLC">HODLC</option>
              </select>
              {t('calculator.cashOut.form.inputAmount.2')}
              <input
                type="number"
                min={1}
                value={length}
                onChange={(e) =>
                  setLength(e.target.value ? Number(e.target.value) : '')
                }
              />
              {t('calculator.cashOut.form.inputAmount.3')}
              <br />
            </>
            {t('calculator.cashOut.form.aboutTransactionPerDay.1')}
            <div className="ui input">
              <input
                type="number"
                value={transactions}
                onChange={(e) => setTransactions(e.target.value)}
              />
            </div>
            {t('calculator.cashOut.form.aboutTransactionPerDay.2')}
            <br />
            <small className="notes">
              <Trans
                i18nKey="calculator.simple.form.references"
                values={{ avgTransaction, avgDay }}
                components={{ b: <b /> }}
              />
            </small>
            <br />
            {t('calculator.cashOut.form.cashOutFrquency.1')}
            <select
              value={cashOutPeriod}
              onChange={(e) => setCashOutPeriod(e.target.value)}
            >
              <option value="daily">
                {t('calculator.cashOut.form.cashOutFrquency.1-options.daily')}
              </option>
              <option value="weekly">
                {t('calculator.cashOut.form.cashOutFrquency.1-options.weekly')}
              </option>
              <option value="monthly">
                {t('calculator.cashOut.form.cashOutFrquency.1-options.monthly')}
              </option>
            </select>
            .
          </div>
        </form>
        <div className="result-text">
          <Trans
            i18nKey="calculator.cashOut.summary.canCashOutEvg"
            values={{
              result: avgCashOutValue && avgCashOutValue.toFixed(2),
              cashOutPeriod
            }}
            components={{ b: <b /> }}
          />
        </div>
        <div className="result-text">
          <Trans
            i18nKey="calculator.cashOut.summary.endCashOutAmount"
            values={{
              result: totalaccCashOutValue && totalaccCashOutValue.toFixed(2)
            }}
            components={{ b: <b /> }}
          />
        </div>
        <div className="result-text">
          <Trans
            i18nKey="calculator.cashOut.summary.endHODLCAmount"
            values={{ result: currentHODLCount && currentHODLCount.toFixed(2) }}
            components={{ b: <b /> }}
          />
        </div>
      </div>
      <div className="chart-container">
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 10,
              right: 20,
              left: 10,
              bottom: 10
            }}
          >
            <XAxis dataKey="timeLength" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              unit=" USD"
              dataKey="value"
              strokeWidth="3px"
              stroke="#3a2bac"
              dot={false}
              name="Value"
            />
            <Line
              type="monotone"
              unit=" USD"
              dataKey="growth"
              strokeWidth="0px"
              stroke="#008000"
              dot={false}
              activeDot={false}
              name="Growth"
              legendType="none"
            />
            <Line
              type="monotone"
              unit=" USD"
              dataKey="cashOutValue"
              strokeWidth="0px"
              stroke="#ef1552"
              dot={false}
              activeDot={false}
              name="Cash Out Value"
              legendType="none"
            />
            <Line
              type="monotone"
              unit=" USD"
              dataKey="accCashOutValue"
              strokeWidth="3px"
              stroke="#00bfc7"
              activeDot={{ r: 8 }}
              dot={false}
              name="Accumalated Cash Out Value"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default SimpleCashOutCalculator
