export const APPORVE_HODLT = 'APPORVE_HODLT'
export const CANCEL_BUY = 'CANCEL_BUY'
export const CANCEL_SELL = 'CANCEL_SELL'
export const DEPOSIT_ETH = 'DEPOSIT_ETH'
export const GET_BALANCE_IN_OLD_DEX = 'GET_BALANCE_IN_OLD_DEX'
export const GET_DEX_DATA = 'GET_DEX_DATA'
export const GET_USER_BALANCES = 'GET_USER_BALANCES'
export const GET_USER_DISTRIBUTION_BALANCES = 'GET_USER_DISTRIBUTION_BALANCES'
export const INIT_BLOCKCHAIN_ENVIRONMENT = 'INIT_BLOCKCHAIN_ENVIRONMENT'
export const RESET_CLAIMABLE_ETH = 'RESET_CLAIMABLE_ETH'
export const RESET_CLAIMABLE_HODLC = 'RESET_CLAIMABLE_HODLC'
export const GET_HODL_RATE = 'GET_HODL_RATE'
export const GET_ALL_ETH_ORDER = 'GET_ALL_ETH_ORDER'
export const GET_ALL_PRICE = 'GET_ALL_PRICE'
export const TRIGGER_NAVIGATOR = 'TRIGGER_NAVIGATOR'
export const GET_ALL_ETH_TXN = 'GET_ALL_ETH_TXN'
export const GET_ORDERBOOK_DATA = 'GET_ORDERBOOK_DATA'
export const GET_TRANSACTION = 'GET_TRANSACTION'
export const GET_ALL_EOS_ORDER = 'GET_ALL_EOS_ORDER'
export const GET_ALL_EOS_TXN = 'GET_ALL_EOS_TXN'
