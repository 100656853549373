import {
  CANCEL_BUY,
  CANCEL_SELL,
  GET_USER_BALANCES,
  GET_USER_DISTRIBUTION_BALANCES,
  GET_DEX_DATA,
  INIT_BLOCKCHAIN_ENVIRONMENT,
  GET_BALANCE_IN_OLD_DEX,
  RESET_CLAIMABLE_ETH,
  RESET_CLAIMABLE_HODLC,
  GET_HODL_RATE,
  GET_ALL_PRICE,
  TRIGGER_NAVIGATOR,
  GET_ORDERBOOK_DATA,
  GET_TRANSACTION
} from '../types'
import connector from '../../connector'
import blockchain from './blockchain'
import axios from 'axios'
import globalConfig from '../../global-config'

export const initBlockchainEnvironment = (amount) => (dispatch) => {
  return new Promise((resolve, reject) => {
    blockchain
      .initBlockchainEnvironment(amount)
      .then((data) => {
        resolve(data)
        dispatch({
          type: INIT_BLOCKCHAIN_ENVIRONMENT,
          payload: data
        })
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const connectWithMetaMask = (b) => async (dispatch) => {
  try {
    await blockchain.connectWithMetaMask(b)
  } catch (err) {
    console.error(err)
  }
}

export const disconnectWithWalletConnect = () => async (dispatch) => {
  try {
    await blockchain.disconnectWithWalletConnect()
  } catch (err) {
    console.error(err)
  }
}

export const connectWithWalletConnect = (b) => async (dispatch) => {
  try {
    await blockchain.connectWithWalletConnect(b)
  } catch (err) {
    console.error(err)
  }
}

export const disconnectWithWalletLink = () => async (dispatch) => {
  try {
    await blockchain.disconnectWithWalletLink()
  } catch (err) {
    console.error(err)
  }
}

export const connectWithWalletLink = (b) => async (dispatch) => {
  try {
    await blockchain.connectWithWalletLink(b)
  } catch (err) {
    console.error(err)
  }
}

export const disconnectWithFortmatic = () => async (dispatch) => {
  try {
    await blockchain.disconnectWithFortmatic()
  } catch (err) {
    console.error(err)
  }
}

export const connectWithFortmatic = (b) => async (dispatch) => {
  try {
    await blockchain.connectWithFortmatic(b)
  } catch (err) {
    console.error(err)
  }
}

export const disconnectWithPortis = () => async (dispatch) => {
  try {
    await blockchain.disconnectWithPortis()
  } catch (err) {
    console.error(err)
  }
}

export const connectWithPortis = (b) => async (dispatch) => {
  try {
    await blockchain.connectWithPortis(b)
  } catch (err) {
    console.error(err)
  }
}

export const depositEth = (web3, amount) => (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .depositEth(web3, amount)
    .then((res) => {
      console.log('[depositr eth res]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err depositEth]', err)
      return err.code
    })
}

export const withdrawEth = (web3, amount) => (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .withdrawEth(web3, amount)
    .then((res) => {
      console.log('[withdrawEth eth]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err withdrawEth]', err)
      return err.code
    })
}

export const depositEthReturnImmediately = (
  web3,
  amount,
  amountToBuy,
  amountinHODlToBuy,
  t,
  orderData
) => (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .depositEthReturnImmediately(
      web3,
      amount,
      amountToBuy,
      amountinHODlToBuy,
      t,
      orderData
    )
    .then((res) => {
      console.log('[depositEthReturnImmediately eth]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err depositEthReturnImmediately', err)
      return err
    })
}

export const buyHodlC = (
  web3,
  amountInEther,
  amountInHodl,
  orderData
) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .buyHodlC(web3, amountInEther, amountInHodl, orderData)
    .then((res) => {
      console.log('[buyHodlC eth]', res)
      dispatch(getUserBalances(web3, account))
      const txn = res.transactionHash
      const ambassadorID = localStorage.getItem('ambassadorID')
      if (ambassadorID) {
        dispatch(sendBuyData(txn, account, ambassadorID))
      }
    })
    .catch((err) => {
      console.log('[err buyHodlC]', err)
      return err.code
    })
}

export const sendBuyData = (txn, account, ambassadorID) => async (dispatch) => {
  console.log('in sendBuyData', txn, account, ambassadorID)
  console.log('api', globalConfig.apis[process.env.REACT_APP_DEFAULT_NETWORK])
  return axios
    .post(globalConfig.apis[process.env.REACT_APP_DEFAULT_NETWORK], {
      account,
      ambassadorID,
      chain: 'eth',
      txn
    })
    .then((res) => console.log('sendBuyData', res))
    .catch((err) => console.log('err', err))
}

export const sellHodlC = (web3, amount) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .sellHodlC(web3, amount)
    .then((res) => {
      console.log('[sellHodlC eth]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err sellHodlC]', err)
      return err.code
    })
}

export const cancelbuy = (web3, orderId) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .cancelbuy(web3, orderId)
    .then((res) => {
      console.log('[cancelBuy]', res)
      dispatch(getUserBalances(web3, account))
      dispatch({
        type: CANCEL_BUY,
        payload: orderId
      })
    })
    .catch((err) => {
      console.log('[err cancelBuy]', err)
      return err.code
    })
}

export const cancelsell = (web3, orderId) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .cancelsell(web3, orderId)
    .then((res) => {
      console.log('[cancelSell]', res)
      dispatch(getUserBalances(web3, account))
      dispatch({
        type: CANCEL_SELL,
        payload: orderId
      })
    })
    .catch((err) => {
      console.log('[err cancelSell]', err)
      return err.code
    })
}

export const hodlTIssue = (web3, amount) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .hodlTIssue(web3, amount)
    .then((res) => {
      console.log('[hodlTIssue]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err hodlTIssue]', err)
      return err.code
    })
}

export const hodlTRedeem = (web3, amount) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .hodlTRedeem(web3, amount)
    .then((res) => {
      console.log('[hodlTRedeem]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err hodlTRedeem]', err)
      return err.code
    })
}

export const approveHodlT = (web3, amount) => async (dispatch, getState) => {
  const account = getState().blockchain.account
  return connector
    .approveHodlT(web3, amount)
    .then((res) => {
      console.log('[hodlTIssue]', res)
      dispatch(getUserBalances(web3, account))
    })
    .catch((err) => {
      console.log('[err hodlTIssue]', err)
      return err.code
    })
}

export const getUserBalances = (web3, account) => async (dispatch) => {
  if (!web3) return
  try {
    const response = await connector.getUserBalances(web3, account)
    dispatch({
      type: GET_USER_BALANCES,
      payload: response
    })
  } catch (err) {
    console.error(err)
  }
}

export const getUserDistributionBalances = (web3, account) => async (
  dispatch
) => {
  if (!web3) return
  try {
    const response = await connector.getUserDistributionBalances(web3, account)
    dispatch({
      type: GET_USER_DISTRIBUTION_BALANCES,
      payload: response
    })
  } catch (err) {
    console.error(err)
  }
}

export const getDexData = (web3) => async (dispatch) => {
  try {
    const response = await connector.getDexData(web3)
    dispatch({
      type: GET_DEX_DATA,
      payload: response
    })
  } catch (err) {
    console.error(err)
  }
}

export const getHODLRate = (web3) => (dispatch) => {
  return connector
    .getHODLRate(web3)
    .then((res) => {
      dispatch({
        type: GET_HODL_RATE,
        payload: res
      })
    })
    .catch((err) => console.log(err))
}

export const getBalanceInOldDex = (web3) => (dispatch) => {
  connector
    .getBalanceInOldDex(web3)
    .then((res) => {
      dispatch({
        type: GET_BALANCE_IN_OLD_DEX,
        payload: res
      })
    })
    .catch((err) => console.log())
}

export const claimEthDistribution = (web3) => async (dispatch) => {
  const claimer = new connector.claimDistributionEmitter(web3)

  claimer
    .on('transactionHash', (hash) => {
      dispatch({ type: RESET_CLAIMABLE_ETH })
    })
    .on('confirmation', ({ web3, account }) => {
      dispatch(getUserDistributionBalances(web3, account))
    })
  claimer.start('claimEthDistribution')
}

export const claimHodlDistribution = (web3) => async (dispatch) => {
  const claimer = new connector.claimDistributionEmitter(web3)

  claimer
    .on('transactionHash', (hash) => {
      dispatch({ type: RESET_CLAIMABLE_HODLC })
    })
    .on('confirmation', ({ web3, account }) => {
      dispatch(getUserDistributionBalances(web3, account))
    })
  claimer.start('claimHodlDistribution')
}

export const triggerNavigator = (status) => (dispatch) => {
  dispatch({ type: TRIGGER_NAVIGATOR, payload: status })
}

export const getOrderBookData = (data) => (dispatch) => {
  dispatch({ type: GET_ORDERBOOK_DATA, payload: { top10OrderData: data } })
}

export const getAllETHPrice = (web3, options = {}) => async (dispatch) => {
  return axios
    .get(
      'https://hodlcommunity-project.github.io/HETHUSD-historicalData/HODLUSD.json'
    )
    .then((res) => {
      const data = res.data.map((ele) => {
        return {
          timeStamp: ele.dateTime,
          HODLprice: Number(web3.utils.fromWei(ele.HODLUSDinWei, 'ether'))
        }
      })
      dispatch({
        type: GET_ALL_PRICE,
        payload: data
      })
      if (options.return) {
        const defaultLength = 8 // 7 days
        let lengthInDays = options.lengthInDays || defaultLength
        return data[data.length - lengthInDays].HODLprice
      }
    })
    .catch((err) => console.log(err))
}

export const getTransaction = (web3, hash) => (dispatch) => {
  return connector
    .getTransaction(web3, hash)
    .then((data) => {
      dispatch({ type: GET_TRANSACTION, payload: data })
    })
    .catch((err) => console.log(err))
}
