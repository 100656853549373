import React from 'react'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Cell
} from 'recharts'
import { withTranslation } from 'react-i18next'
const VolumeChart = ({ data, t, focusBar, onMouseMove }) => {
  return (
    <ResponsiveContainer height={600}>
      <ComposedChart data={data} onMouseMove={(state) => onMouseMove(state)}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="timeStamp" />
        <YAxis
          yAxisId="left"
          orientation="left"
          domain={['auto', 'dataMax']}
          type="number"
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={['0', (dataMax) => Math.ceil(dataMax + 4000)]}
          type="number"
        />
        <Tooltip
          formatter={(value, name, props) => [
            props.dataKey.includes('EOS')
              ? value.toFixed(4)
              : value.toFixed(12),
            name,
            props
          ]}
        />
        <Legend />
        <Bar
          barSize={20}
          stackId="a"
          dataKey="ETHTotal"
          unit="USD"
          fill="#71AAC1"
          yAxisId="right"
          name={t('graph.graph1.dataKey2')}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? '#b8d5e0' : '#71AAC1'}
            />
          ))}
        </Bar>
        <Bar
          barSize={20}
          stackId="a"
          dataKey="EOSTotal"
          unit="USD"
          fill="#8f8f8f"
          yAxisId="right"
          name={t('graph.graph1.dataKey3')}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? '#b8b8b8' : '#8f8f8f'}
            />
          ))}
        </Bar>
        <Line
          dataKey="HODLprice"
          unit="USD"
          type="monotone"
          stroke="#4687a1"
          yAxisId="left"
          dot={false}
          activeDot={{ r: 6 }}
          strokeWidth="3px"
          name={t('graph.graph1.dataKey1')}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default withTranslation()(VolumeChart)
