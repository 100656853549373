import React from 'react'

import config from '../../connector/config'

import { connect } from 'react-redux'

const DEFAULT_GAS = config.DEFAULT_GAS
// const INIT_AMOUNT_HODLC = '10000'
// const ANDY = '0xA32e5865041DaB482D96AFdef81a06830C919991'
// const SHELLY = '0x3C0Ae95E3cE82B7b0081B5D619746238F183065b'
class Admin extends React.Component {
  // state = {
  //   user: [
  //     {
  //       addr: '0xA32e5865041DaB482D96AFdef81a06830C919991',
  //       hold: '100'
  //     },
  //     {
  //       addr: '0x3C0Ae95E3cE82B7b0081B5D619746238F183065b',
  //       hold: '100'
  //     }
  //   ]
  // }
  state = {
    ETH_USD: 0,
    migration_admin_address: this.props.account,
    init_user_recipient_address: '',
    init_user_recipient_hodlc_amount: 10000,
    hodlc_price_in_usd: '',
    eth_price_in_usd: 399.99
  }

  renounceOwnership = async () => {
    console.log('renounceOwnership')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    instance.methods
      .renounceOwnership()
      .send({ from: account, gas: DEFAULT_GAS })
      .then((result) => {
        console.log('[HodlDex] renounceOwnership() result:', result)
      })
  }

  initSetMaker = async () => {
    console.log('initSetMaker')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    instance.methods
      .initSetMaker(process.env.REACT_APP_MAKER_ADDR)
      .send({ from: account, gas: DEFAULT_GAS })
      .then((result) => {
        console.log('[HodlDex] initSetMaker() result:', result)
      })
  }

  initSetHodlUsd = async () => {
    console.log('initSetHodlUsd')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    instance.methods
      .initSetHodlUsd('1041000000000000000')
      .send({ from: account, gas: DEFAULT_GAS })
      .then((result) => {
        console.log('[HodlDex] initSetMaker() result:', result)
      })
  }

  initSetHodlTAddress = async () => {
    console.log('initSetHodlTAddress')
  }

  initUser = async (person, amount) => {
    console.log('initUser')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    instance.methods
      .initUser(person, web3.utils.toWei(amount.toString(), 'ether'))
      .send({ from: account, gas: DEFAULT_GAS })
      .then((r) => {
        console.log(`[initUser] grant ${person} ${amount} HODLC`)
      })
      .catch((err) => {
        console.error('errrrrrr', err)
      })
  }

  grantRole = async (person, role) => {
    console.log('grantRole')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    const MIGRATION_ROLE = await instance.methods[role]().call()
    console.log('MIGRATION_ROLE', MIGRATION_ROLE)
    instance.methods
      .grantRole(MIGRATION_ROLE, person)
      .send({ from: account, gas: DEFAULT_GAS })
      .then((r) => {
        console.log(`[grantRole] grant ${person} ${role}`)
      })
      .catch((err) => {
        console.error('errrrrrr', err)
      })
  }

  revokeRole = async (person, role) => {
    console.log('revokeRole')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    const MIGRATION_ROLE = await instance.methods[role]().call()
    console.log('MIGRATION_ROLE', MIGRATION_ROLE)
    instance.methods
      .revokeRole(MIGRATION_ROLE, person)
      .send({ from: account, gas: DEFAULT_GAS })
      .then((r) => {
        console.log(`[revokeRole] revoke ${person} ${role}`)
      })
      .catch((err) => {
        console.error('errrrrrr', err)
      })
  }

  oracleSetEthUsd = async (price) => {
    console.log('oracleSetEthUsd')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const account = this.props.account
    instance.methods
      .oracleSetEthUsd(web3.utils.toWei(price.toString(), 'ether'))
      .send({ from: account, gas: DEFAULT_GAS })
      .then((r) => {
        console.log(`[oracleSetEthUsd] set ETH at $${price} USD`)
      })
      .catch((err) => {
        console.error('errrrrrr', err)
      })
  }

  getEthUsd = async () => {
    console.log('getEthUsd')
    const web3 = this.props.web3
    const instance = config.HodlDex(web3)
    const ETH_USD = await instance.methods.ETH_USD().call()
    this.setState({
      ETH_USD: web3.utils.fromWei(ETH_USD.toString(), 'ether')
    })
  }

  render() {
    return (
      <div className="container">
        <div
          className="alert alert-danger"
          role="alert"
          style={{ marginTop: '20px' }}
        >
          This is a dangerous admin tool, you should not touch it if you don't
          know what you are doing.
        </div>
        <h2>Admin</h2>
        {/* <h2>Version 1</h2> */}
        {/* <hr /> */}
        {/* <button className="ui basic button" onClick={()=>this.initUser(ANDY, INIT_AMOUNT_HODLC)}>initUser ANDY</button> */}
        {/* <button className="ui basic button" onClick={()=>this.initUser(SHELLY, INIT_AMOUNT_HODLC)}>initUser SHELLY</button> */}
        {/* <hr/> */}
        {/* /!* <button className="ui button" onClick={this.initSetHodlTAddress}>initSetHodlTAddress</button> *!/ */}
        {/* <button className="ui button teal" onClick={this.initSetMaker}>v1:initSetMaker</button> */}
        {/* <button className="ui button" onClick={this.initSetHodlUsd}>v1:initSetHodlUsd</button> */}
        {/* <hr /> */}
        {/* <button className="ui button red" onClick={this.renounceOwnership}>v1:renounceOwnership</button> */}
        {/* <hr /> */}
        <hr />
        <form>
          <h5>Step 1: initUser</h5>
          <div className="form-group row">
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-4 col-form-label"
            >
              HODLC recipient
            </label>
            <div className="col-xs-12 col-sm-8">
              <input
                value={this.state.init_user_recipient_address}
                type="text"
                className="form-control"
                placeholder="address"
                onChange={(e) =>
                  this.setState({
                    init_user_recipient_address: e.target.value
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-4 col-form-label"
            >
              HODLC amount
            </label>
            <div className="col-xs-12 col-sm-8">
              <input
                value={this.state.init_user_recipient_hodlc_amount}
                type="number"
                className="form-control"
                placeholder="HODLC amount"
                onChange={(e) =>
                  this.setState({
                    init_user_recipient_hodlc_amount: e.target.value
                  })
                }
              />
            </div>
          </div>
          <button
            className="btn btn-dark"
            onClick={() =>
              this.initUser(
                this.state.init_user_recipient_address,
                this.state.init_user_recipient_hodlc_amount
              )
            }
          >
            init {this.state.init_user_recipient_address}{' '}
            {this.state.init_user_recipient_hodlc_amount} HODLC
          </button>
        </form>
        {/* <button className="ui basic button" onClick={()=>this.initUser(ANDY, INIT_AMOUNT_HODLC)}>initUser ANDY</button> */}
        {/* <button className="ui basic button" onClick={()=>this.initUser(SHELLY, INIT_AMOUNT_HODLC)}>initUser SHELLY</button> */}
        <hr />
        <form>
          <h5>Step 2: grant MIGRATION_ROLE role</h5>
          <div className="form-group row">
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-4 col-form-label"
            >
              Address (default to yourself)
            </label>
            <div className="col-xs-12 col-sm-8">
              <input
                value={this.state.migration_admin_address}
                type="text"
                className="form-control"
                placeholder="address"
                onChange={(e) =>
                  this.setState({
                    migration_admin_address: e.target.value
                  })
                }
              />
            </div>
          </div>
          <button
            className="btn btn-danger"
            onClick={() =>
              this.grantRole(
                this.state.migration_admin_address,
                'MIGRATION_ROLE'
              )
            }
          >
            grant MIGRATION_ROLE role
          </button>
        </form>
        <hr />
        <form>
          <h5>Step 3: set ETH USD value</h5>
          <div className="form-group row">
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-4 col-form-label"
            >
              USD Price (default to 399.99)
            </label>
            <div className="col-xs-12 col-sm-8">
              <input
                value={this.state.eth_price_in_usd}
                type="text"
                className="form-control"
                placeholder="address"
                onChange={(e) =>
                  this.setState({
                    eth_price_in_usd: e.target.value
                  })
                }
              />
            </div>
          </div>
          <button
            className="btn btn-dark"
            onClick={() => this.oracleSetEthUsd(this.state.eth_price_in_usd)}
          >
            oracleSetEthUsd
          </button>
        </form>
        <div style={{ marginTop: '10px' }}>
          <button className="btn btn-info" onClick={this.getEthUsd}>
            try to getEthUsd: {this.state.ETH_USD}
          </button>
        </div>
        <hr />
        <form>
          <h5>Step 4: revoke and open the dex</h5>
          <div className="alert alert-light" role="alert">
            it will execute revokeRole(
            {this.state.migration_admin_address}, MIGRATION_ROLE)
          </div>
          <button
            className="btn btn-success"
            onClick={() =>
              this.revokeRole(
                this.state.migration_admin_address,
                'MIGRATION_ROLE'
              )
            }
          >
            Open the Dex
          </button>
        </form>
        <hr />
        <form>
          <h5>Step 5: Go to wallet and try to deposit ETH</h5>
          <div className="alert alert-success" role="alert">
            if you can deposit ETH and see ETH price. CONGRATULATIONS, you are
            all set to try out all features!
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps)(Admin)
