export const nameDict = {
  blockHash: 'Block Hash',
  blockNumber: 'Block Number',
  from: 'From',
  gas: 'Gas Limit',
  gasPrice: 'Gas Price',
  to: 'To',
  transactionHash: 'Transaction Hash',
  event: 'Event',
  HODLCvalue: 'HODLC Value'
}

export const eventName = {
  sellHodlC: 'Sell HODLC'
}
