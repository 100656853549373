// import axios from 'axios'

import config from '../config'
import BigNumber from 'bignumber.js'

const COLD_PERIOD_FOR_REFRESHING_PRICING =
  config.COLD_PERIOD_FOR_REFRESHING_PRICING

async function checkEthInfo(web3) {
  const setMarketInfo = async (web3) => {
    // const r = await axios.get('https://api.coingecko.com/api/v3/coins/ethereum')
    // const v = r.data.market_data.current_price.usd
    // localStorage.setItem('ethInUsd', `${v}@${Date.now()}`)
    const instance = config.HodlDex(web3)
    if (instance) {
      const r = await instance.methods.ETH_USD().call()
      const v = web3.utils.fromWei(r.toString(), 'ether')
      localStorage.setItem('ethInUsd', `${v}@${Date.now()}`)
    }
  }
  let local = localStorage.getItem('ethInUsd')
  if (!local) {
    await setMarketInfo(web3)
  } else {
    local = local.split('@')
    const timestamp = local[1]
    const period = Date.now() - timestamp
    if (period > COLD_PERIOD_FOR_REFRESHING_PRICING) {
      // 3 minute
      await setMarketInfo(web3)
    }
  }
}

async function checkHodlInfo(web3) {
  const setMarketInfo = async (web3) => {
    const instance = config.HodlDex(web3)
    if (instance) {
      const r = await instance.methods.rates().call()
      const rate = web3.utils.fromWei(r.hodlUsd.toString(), 'ether')
      localStorage.setItem('hodlInUsd', `${rate}@${Date.now()}`)
    }
  }
  let local = localStorage.getItem('hodlInUsd')
  if (!local) {
    await setMarketInfo(web3)
  } else {
    local = local.split('@')
    const timestamp = local[1]
    const period = Date.now() - timestamp
    if (period > COLD_PERIOD_FOR_REFRESHING_PRICING) {
      // 3 minute
      await setMarketInfo(web3)
    }
  }
}

const converters = {
  convertEthToUsd: async (web3, amount = 0) => {
    await checkEthInfo(web3)
    if (localStorage.getItem('hodlInUsd')) {
      const rate = localStorage.getItem('ethInUsd').split('@')[0]
      const eth = new BigNumber(amount)
      return eth.times(rate)
    }
  },
  convertHodlToUsd: async (web3, amount) => {
    await checkHodlInfo(web3)
    if (localStorage.getItem('hodlInUsd')) {
      const latestPrice = localStorage.getItem('hodlInUsd').split('@')[0]
      const hodl = new BigNumber(amount)
      return hodl.multipliedBy(latestPrice)
    }
  },
  convertHodlCToHodlT: async (web3, amount) => {
    await checkHodlInfo(web3)
    if (localStorage.getItem('hodlInUsd')) {
      const hodlInUsd = localStorage.getItem('hodlInUsd').split('@')[0]
      const hodlc = new BigNumber(amount)
      return hodlc.multipliedBy(hodlInUsd)
    }
  },
  convertHodlTToHodlC: async (web3, amount) => {
    await checkHodlInfo(web3)
    if (localStorage.getItem('hodlInUsd')) {
      const hodlInUsd = localStorage.getItem('hodlInUsd').split('@')[0]
      const hodlt = new BigNumber(amount)
      return hodlt.dividedBy(hodlInUsd)
    }
  }
}

export default converters
