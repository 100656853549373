import config from '../config'
import web3instance from '../web3'
import notify from '../../utils/notify'
import connector from '../../connector'

const EventEmitter = require('events').EventEmitter
const depositEth = async (web3, amountInEther = 0) => {
  console.log('depositEth')
  return new Promise(async (resolve, reject) => {
    const instance = config.HodlDex(web3)
    const etherInWei = web3.utils.toWei(amountInEther, 'ether')
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods
      .depositEth()
      .send({
        from: accounts[0],
        value: etherInWei
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt)
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err)
      })
  })
}

const depositEthReturnImmediately = async (
  web3,
  amountInEther = 0,
  amountToBuy = 0,
  amountinHODlToBuy = 0,
  t,
  orderData
) => {
  console.log('depositEthReturnImmediately')
  return new Promise(async (resolve, reject) => {
    const instance = config.HodlDex(web3)
    const etherInWei = web3.utils.toWei(amountInEther, 'ether')
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods
      .depositEth()
      .send({
        from: accounts[0],
        value: etherInWei
      })
      .on('transactionHash', function (hash) {
        notify.success(
          t('trade.notify.successBuy', {
            hodlc: amountinHODlToBuy
          })
        )
        connector
          .buyHodlC(web3, amountToBuy, amountinHODlToBuy, orderData)
          .then(() => {
            const system = t('system.blockchain.confirmed')
            notify.success(`${system}`)
          })
          .catch(() => {
            notify.error(t('system.blockchain.failed'))
          })
      })
      .on('receipt', function (receipt) {
        console.log('receipt')
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err.code)
      })
  })
}
const withdrawEth = async (web3, amountInEther = 0) => {
  console.log('withdrawEth')
  return new Promise(async (resolve, reject) => {
    const instance = config.HodlDex(web3)
    const etherInWei = web3.utils.toWei(amountInEther, 'ether')
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods
      .withdrawEth(etherInWei)
      .send({
        from: accounts[0]
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt)
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err)
      })
  })
}

const hodlTIssue = async (web3, amount = 0) => {
  // convert HODLC to HODLT
  console.log('in HODLTIssue', amount)
  return new Promise(async (resolve, reject) => {
    const instance = config.HodlDex(web3)
    const etherInWei = web3.utils.toWei(amount, 'ether')
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods
      .hodlTIssue(etherInWei)
      .send({
        from: accounts[0]
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt)
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err)
      })
  })
}
const approveHodlT = async (web3, amount = 0) => {
  console.log('approveHodlT')
  return new Promise(async (resolve, reject) => {
    const hodlTinstance = config.HodlT(web3)
    const etherInWei = web3.utils.toWei(amount, 'ether')
    const { HTOKEN_RESERVE_PROXY } = config.refreshBlockchainInfraInfo()
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    hodlTinstance.methods
      .approve(HTOKEN_RESERVE_PROXY, etherInWei)
      .send({
        from: accounts[0]
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt)
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err)
      })
  })
}
const hodlTRedeem = async (web3, amountUsd = 0) => {
  // convert HODLT to HODLC
  console.log('hodlTRedeem')
  return new Promise(async (resolve, reject) => {
    const instance = config.HodlDex(web3)
    const etherInWei = web3.utils.toWei(amountUsd, 'ether')
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods
      .hodlTRedeem(etherInWei)
      .send({
        from: accounts[0]
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt)
        resolve(receipt)
      })
      .on('error', function (err) {
        console.log('error', err)
        reject(err)
      })
  })
}

class claimDistributionEmitter extends EventEmitter {
  constructor(web3) {
    super()
    this.web3 = web3instance(web3)
  }

  async start(type) {
    const instance = config.HodlDex(this.web3)
    const accounts = await this.web3.eth.getAccounts()
    if (accounts.length === 0) return
    instance.methods[type]()
      .send({ from: accounts[0] })
      .on('transactionHash', (hash) => {
        console.log(`[${type}] transactionHash`, hash)
        this.emit('transactionHash', hash)
      })
      .on('receipt', (receipt) => {
        console.log(`[${type}] receipt`, receipt)
        this.emit('receipt', receipt)
      })
      .on('confirmation', (confirmationNumber, receipt, latestBlockHash) => {
        console.log(
          `[${type}] confirmationNumber`,
          confirmationNumber,
          receipt,
          latestBlockHash
        )
        this.emit('confirmation', { web3: this.web3, account: accounts[0] })
      })
      .on('error', (err) => {
        // this.emit('error', err)
      })
  }
}

export {
  depositEthReturnImmediately,
  depositEth,
  withdrawEth,
  hodlTIssue,
  approveHodlT,
  hodlTRedeem,
  claimDistributionEmitter
}
