import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// dependent on global redux
import { getAllETHPrice } from '../../redux/actions'

// from Graph context
import './styles.scss'
import { getAllETHOrder, getAllEOSOrder } from './redux/actions'
import {
  mergeTwoData,
  mergeEOSETHVolumeData,
  mergeEOSETHTxnData,
  groupData,
  sliceData
} from './cleanData'
import VolumeChart from './VolumeChart'
import TxnChart from './TxnChart'

class Graph extends React.Component {
  state = {
    data: [],
    txnData: [],
    focusBar: null,
    time: 0,
    originalData: [],
    rangeInDays: '90',
    originalTxnData: [],
    txnTime: 0,
    txnRangeInDays: '90'
  }
  componentDidMount() {
    document.title = 'HODL DEX | Graph'
    this.props.getAllETHOrder()
    this.props.getAllETHPrice()
    this.props.getAllEOSOrder()
    const allData = mergeEOSETHVolumeData(
      this.props.ETHVolumeData,
      this.props.EOSVolumeData
    )
    const allTxnData = mergeEOSETHTxnData(
      this.props.ETHTxnData,
      this.props.EOSTxnData
    )
    this.formatData(allData, allTxnData)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.rangeInDays !== prevState.rangeInDays ||
      this.state.time !== prevState.time
    ) {
      this.changeSelect(this.state.originalData, 'volume')
    }
    if (
      this.state.txnRangeInDays !== prevState.txnRangeInDays ||
      this.state.txnTime !== prevState.txnTime
    ) {
      this.changeSelect(this.state.originalTxnData, 'txn')
    }
  }
  changeSelect(data, type) {
    if (type === 'volume') {
      const slicedData = sliceData(data, this.state.rangeInDays)
      const groupedData = groupData(slicedData, this.state.time, type)
      this.setState({ data: groupedData })
    } else {
      const slicedData = sliceData(data, this.state.txnRangeInDays)
      const groupedData = groupData(slicedData, this.state.txnTime, type)
      this.setState({ txnData: groupedData })
    }
  }

  formatData(allData, allTxnData) {
    const data = mergeTwoData(allData, this.props.priceData)
    const txnData = mergeTwoData(allTxnData, this.props.priceData)
    this.setState({ originalData: data, originalTxnData: txnData }, () => {
      this.changeSelect(this.state.originalData, 'volume')
      this.changeSelect(this.state.originalTxnData, 'txn')
    })
  }

  onMouseMove = (state) => {
    if (state.isTooltipActive) {
      this.setState({ focusBar: state.activeTooltipIndex })
    } else {
      this.setState({ focusBar: null })
    }
  }

  render() {
    const { t } = this.props
    return (
      <div className="graph-component container-fluid">
        <div className="container">
          <h2 className="ui header session-header">{t('graph.title')}</h2>
          <div className="session-body">
            <div className="ui header session-sub-header">
              {t('graph.graph1.header')}
              <div className="time-selector explorer graph">
                <select
                  value={this.state.time}
                  id="days"
                  name="days"
                  onChange={(e) => this.setState({ time: e.target.value })}
                >
                  <option value="0">{t('graph.intervalOptions.day')}</option>
                  <option value="0.25">
                    {t('graph.intervalOptions.week')}
                  </option>
                  <option value="0.5">
                    {t('graph.intervalOptions.biweek')}
                  </option>
                  <option value="1">{t('graph.intervalOptions.month')}</option>
                  <option value="3">
                    {t('graph.intervalOptions.quarter')}
                  </option>
                </select>
              </div>
              <div className="time-selector explorer graph">
                <select
                  value={this.state.rangeInDays}
                  id="days"
                  name="days"
                  onChange={(e) =>
                    this.setState({ rangeInDays: e.target.value })
                  }
                >
                  <option value="14">{t('graph.lengthOptions.14days')}</option>
                  <option value="30">{t('graph.lengthOptions.30days')}</option>
                  <option value="60">{t('graph.lengthOptions.60days')}</option>
                  <option value="90">{t('graph.lengthOptions.90days')}</option>
                  <option value="180">
                    {t('graph.lengthOptions.180days')}
                  </option>
                  <option value="all">{t('graph.lengthOptions.all')}</option>
                </select>
              </div>
            </div>
            <VolumeChart
              data={this.state.data}
              t={t}
              focusBar={this.state.focusBar}
              onMouseMove={this.onMouseMove}
            />
            <div className="ui header session-sub-header">
              {t('graph.graph2.header')}
              <div className="time-selector explorer graph">
                <select
                  value={this.state.txnTime}
                  id="days"
                  name="days"
                  onChange={(e) => this.setState({ txnTime: e.target.value })}
                >
                  <option value="0">{t('graph.intervalOptions.day')}</option>
                  <option value="0.25">
                    {t('graph.intervalOptions.week')}
                  </option>
                  <option value="0.5">
                    {t('graph.intervalOptions.biweek')}
                  </option>
                  <option value="1">{t('graph.intervalOptions.month')}</option>
                  <option value="3">
                    {t('graph.intervalOptions.quarter')}
                  </option>
                </select>
              </div>
              <div className="time-selector explorer graph">
                <select
                  value={this.state.txnRangeInDays}
                  id="days"
                  name="days"
                  onChange={(e) =>
                    this.setState({ txnRangeInDays: e.target.value })
                  }
                >
                  <option value="14">{t('graph.lengthOptions.14days')}</option>
                  <option value="30">{t('graph.lengthOptions.30days')}</option>
                  <option value="60">{t('graph.lengthOptions.60days')}</option>
                  <option value="90">{t('graph.lengthOptions.90days')}</option>
                  <option value="180">
                    {t('graph.lengthOptions.180days')}
                  </option>
                  <option value="all">{t('graph.lengthOptions.all')}</option>
                </select>
              </div>
            </div>
            <TxnChart txnData={this.state.txnData} t={t} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ graph }) => {
  return {
    ETHVolumeData: graph.ETHVolume,
    priceData: graph.price,
    ETHTxnData: graph.ETHTxn,
    EOSVolumeData: graph.EOSVolume,
    EOSTxnData: graph.EOSTxn
  }
}

export default connect(mapStateToProps, {
  getAllETHOrder,
  getAllETHPrice,
  getAllEOSOrder
})(withTranslation()(Graph))
