import NETWORKS from '../global-config'

const hodlDexInfraInfo = JSON.parse(
  localStorage.getItem('hodlDexInfraInfo')
) || {
  blockchain: 'eth',
  version: 'v2',
  network: process.env.REACT_APP_DEFAULT_NETWORK
}

const getExplorerBaseURI = () => {
  const { blockchain, network } = hodlDexInfraInfo
  if (blockchain === 'eth') {
    const subdomain = network === 'main' ? 'www' : network
    return `https://${subdomain}.etherscan.io`
  }
}

const refreshBlockchainInfraInfo = () => {
  // uncomment this to enable ropsten
  const { blockchain, version, network } = hodlDexInfraInfo

  const abiOfVersionNetwork = NETWORKS[blockchain].abi[version]
  const HodlDexContract = require(`${abiOfVersionNetwork.hodldex}`)
  const HodlTContract = require(`${abiOfVersionNetwork.hodlt}`)
  const currentNetworkIsDeployed = NETWORKS[blockchain].networks[network]
  const HODLDEX_ADDR =
    (currentNetworkIsDeployed &&
      NETWORKS[blockchain].networks[network].dexContractAddress) ||
    NETWORKS[blockchain].networks.main.dexContractAddress
  const HODLT_ADDR =
    (currentNetworkIsDeployed &&
      NETWORKS[blockchain].networks[network].tokenContractAddress) ||
    NETWORKS[blockchain].networks.main.tokenContractAddress
  const HTOKEN_RESERVE_PROXY =
    (currentNetworkIsDeployed &&
      NETWORKS[blockchain].networks[network].tokenReserveContractAddress) ||
    NETWORKS[blockchain].networks.main.tokenReserveContractAddress
  const INFURA_KEY = NETWORKS.INFURA_KEY
  const INFURA_ENDPOINT =
    (currentNetworkIsDeployed &&
      NETWORKS[blockchain].networks[network].rpcEndpoint) ||
    NETWORKS[blockchain].networks.main.rpcEndpoint

  return {
    HodlDexContract,
    HodlTContract,
    HODLDEX_ADDR,
    HODLT_ADDR,
    INFURA_ENDPOINT,
    INFURA_KEY,
    HTOKEN_RESERVE_PROXY,
    blockchain,
    version,
    network
  }
}

const config = {
  refreshBlockchainInfraInfo,
  COLD_PERIOD_FOR_REFRESHING_PRICING: 600000, // 600 secs
  DEFAULT_LOW_GAS: '200000',
  DEFAULT_BUY_GAS: '450000',
  DEFAULT_SELL_GAS: '400000',
  DEFAULT_INTERVAL: '250000',
  HodlDex: (web3) => {
    if (!web3) return null
    if (web3.utils) {
      const { HODLDEX_ADDR, HodlDexContract } = refreshBlockchainInfraInfo()
      const address = web3.utils.toChecksumAddress(HODLDEX_ADDR)
      if (address) {
        return new web3.eth.Contract(HodlDexContract.abi, address)
      } else {
        // window.alert('HodlDex contract not deployed to detected network.')
        return { error: true }
      }
    }
  },
  HodlT: (web3) => {
    if (!web3) return null
    if (web3.utils) {
      const { HODLT_ADDR, HodlTContract } = refreshBlockchainInfraInfo()
      const address = web3.utils.toChecksumAddress(HODLT_ADDR)
      if (address) {
        return new web3.eth.Contract(HodlTContract.abi, address)
      } else {
        // window.alert('HodlTContract contract not deployed to detected network.')
        return { error: true }
      }
    }
  },
  explorerBaseURI: getExplorerBaseURI
}

export default config
