import React from 'react'
import { connect } from 'react-redux'

import config from '../../connector/config'
import CancelModal from './CancelModel'
import { withTranslation } from 'react-i18next'
import web3instance from '../../connector/web3'
import { with18Decimals, notify } from '../../utils'
import BigNumber from 'bignumber.js'
import networkConfig from '../../global-config'
import { cancelsell, cancelbuy } from '../../redux/actions'

export const labels = {
  sell: 'sell',
  buy: 'buy',
  userSell: 'sell',
  userBuy: 'buy'
}

function convertMoney(web3, amount) {
  web3 = web3instance(web3)
  const dollar = web3.utils.fromWei(amount, 'ether')
  if (isNaN(dollar)) {
    return '$0'
  } else {
    return `$${BigNumber(dollar).toFixed(18)}`
  }
}
function convertSellerId(id) {
  if (id) {
    const Id = id.slice(0)
    return <span>{Id}</span>
  }
}
function convertOrderId(id) {
  if (id) {
    const Id = id.slice(0)
    return <span>{Id}</span>
  }
}

const actionName = {
  sell: 'sell',
  buy: 'buy',
  userSell: 'sell',
  userBuy: 'buy'
}

class List extends React.Component {
  state = {
    showModal: false,
    orderId: null
  }

  showModal = (id) => {
    this.setState({
      showModal: true,
      orderId: id
    })
  }

  hideModal = () => {
    this.setState({
      showModal: false
    })
  }

  cancelOrder = (id) => {
    if (actionName[this.props.type]) {
      this.props[`cancel${actionName[this.props.type]}`](
        this.props.web3,
        id
      ).then((res) => {
        if (res) {
          notify.error(this.props.t('system.blockchain.failed'))
        } else {
          notify.success(this.props.t('system.blockchain.confirmed'))
          this.props.removeOrderFromBookAfterCanceling(id)
        }
      })
      this.hideModal()
      return notify.success(this.props.t('trade.notify.cancel', { id }))
    }
  }

  renderHodlcBalance = (balance) => {
    const web3 = web3instance(this.props.web3)
    balance = web3.utils.fromWei(balance.toString(), 'ether')
    return with18Decimals(balance)
  }

  renderData = (type) => {
    const { orders } = this.props

    if (
      !orders ||
      orders.length === 0 ||
      orders[0].buyer === '0x0000000000000000000000000000000000000000' ||
      orders[0].seller === '0x0000000000000000000000000000000000000000'
    ) {
      return (
        <div className="order">{this.props.t('trade.orderBook.noEvent')}</div>
      )
    }

    return orders.map((o, i) => {
      return (
        <div className={'order'} key={o.orderId}>
          <div className="ui stackable grid">
            <div className="one wide column">
              {i + this.props.accumulatedPage + 1}
            </div>
            <div className="three wide column">
              <div className="sm-label">
                {this.props.t('trade.orderBook.title.orderId')}
              </div>
              {o.orderId && convertOrderId(o.orderId)}
            </div>
            <div className="three wide column">
              <div className="sm-label">
                {this.props.t(`trade.orderBook.title.${type}`)}
              </div>
              <a
                href={`${config.explorerBaseURI()}/address/${o.seller}`}
                rel="noopener noreferrer"
                target="_blank"
                className="seller"
              >
                {o.seller && convertSellerId(o.seller)}
              </a>
            </div>
            <div className="four wide column">
              <div className="sm-label">HODLC</div>
              <div className="amount">
                {o.volumeHodl && this.renderHodlcBalance(o.volumeHodl)}
              </div>
            </div>
            <div className="three wide column price-row">
              <div className="sm-label">
                {this.props.t('trade.orderBook.title.price')}
              </div>
              <div className="price">
                {o.askUsd && convertMoney(this.props.web3, o.askUsd)}
              </div>
            </div>
            <div
              className={`two wide column action cancel-column ${
                o.seller === this.props.account
              }`}
            >
              <div
                className={`cancel-your-order-btn ui mini button ${
                  !networkConfig.enableTrade && 'disable-cancel'
                }`}
                onClick={
                  networkConfig.enableTrade
                    ? () => this.showModal(o.orderId)
                    : () => {}
                }
              >
                {this.props.t('trade.orderBook.cancel')}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    const t = this.props.t
    return (
      <div className="list-component">
        <div className="order order-header">
          <div className="ui stackable grid">
            <div className="one wide column">#</div>
            <div className="three wide column">
              <div className="order-id">
                {t('trade.orderBook.title.orderId')}
              </div>
            </div>
            <div className="three wide column">
              <div className="user">
                {t(`trade.orderBook.title.${labels[this.props.type]}`)}
              </div>
            </div>
            <div className="four wide column">
              <div className="amount">HODLC</div>
            </div>
            <div className="three wide column">
              <div className="price">{t('trade.orderBook.title.price')}</div>
            </div>
            <div className="two wide column"></div>
          </div>
        </div>
        {this.renderData(labels[this.props.type])}
        {this.state.showModal && (
          <CancelModal
            hideModal={this.hideModal}
            cancelOrder={this.cancelOrder}
            id={this.state.orderId}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account,
    web3: blockchain.web3
  }
}
export default connect(mapStateToProps, { cancelsell, cancelbuy })(
  withTranslation()(List)
)
