import config from '../../connector/config'

export const getEstimatedGas = (web3, hodl, orderBook) => {
  const GAS_INTERVAL = Number(config.DEFAULT_INTERVAL)
  const BASE_GAS = Number(config.DEFAULT_BUY_GAS)
  let totalToSpend = hodl
  const newOrderBook = orderBook.map((evt) => {
    return Number(web3.utils.fromWei(evt.volumeHodl, 'ether'))
  })
  let count = 0
  for (let i = 0; i < newOrderBook.length; i++) {
    const amount = newOrderBook[i]
    totalToSpend -= amount
    if (totalToSpend > 0 || totalToSpend === 0) {
      count++
    }
  }
  return BASE_GAS + GAS_INTERVAL * count
}
