import { TRIGGER_NAVIGATOR } from '../types'

const INITIAL_STATE = {
  open: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRIGGER_NAVIGATOR:
      return { ...state, open: action.payload }
    default:
      return state
  }
}
