import config from '../config'
import web3instance from '../web3'

const EventEmitter = require('events').EventEmitter

const ZERO =
  '0x0000000000000000000000000000000000000000000000000000000000000000'
const ZERO2 =
  '0x0000002000000000000000000000000000000000000000000000000000000000'

class OrderBook extends EventEmitter {
  constructor(web3) {
    super()
    this.web3 = web3instance(web3)
  }

  async subscribe(
    type = 'sell',
    limit = Number.MAX_SAFE_INTEGER,
    leadOrderId = ''
  ) {
    const actionName = {
      sell: 'sell',
      buy: 'buy',
      userSell: 'sell',
      userBuy: 'buy'
    }
    type = actionName[type]
    let tempLimit = limit
    const web3 = web3instance(this.web3)
    const instance = config.HodlDex(web3)
    if (instance && instance.methods) {
      if (!instance.methods[`${type}OrderCount`]) return
      const total = await instance.methods[`${type}OrderCount`]().call()
      this.emit('init', total)
      if (total > 0) {
        const firstOrderId = leadOrderId
          ? leadOrderId
          : await instance.methods[`${type}OrderFirst`]().call()
        try {
          const firstOrderIdInfo = await instance.methods[
            `${type}OrderIterate`
          ](firstOrderId).call()
          const getNext = async (id) => {
            tempLimit--
            const orderIdInfo = await instance.methods[`${type}OrderIterate`](
              id
            ).call()
            if (tempLimit <= 0) {
              this.emit('finishAndReturnNextLeadOrderId', id)
              return
            }
            const orderDetails = await instance.methods[`${type}Order`](
              id
            ).call()
            orderDetails.orderId = id
            orderDetails.count = limit - tempLimit
            this.emit('newOrder', orderDetails)
            if (orderIdInfo.idAfter !== ZERO && orderIdInfo.idAfter !== ZERO2) {
              getNext(orderIdInfo.idAfter)
            } else {
              this.emit('end')
            }
          }
          const firstOrderDetails = await instance.methods[`${type}Order`](
            firstOrderId
          ).call()
          firstOrderDetails.orderId = firstOrderId
          firstOrderDetails.count = 0
          this.emit('firstOrder', firstOrderDetails)
          if (
            firstOrderIdInfo.idAfter !== ZERO &&
            firstOrderIdInfo.idAfter !== ZERO2
          ) {
            getNext(firstOrderIdInfo.idAfter)
          }
        } catch (err) {
          console.warn('MetaMask - FIFOSet: key not found - can be ignored')
        }
      }
    }
  }
}

export default OrderBook
