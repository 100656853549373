import React from 'react'
import BigNumber from 'bignumber.js'

const withDecimals = (price, i18nextLng, decimals = 18) => {
  const arr = price.toString().split('.')
  arr[1] = arr[1] ? arr[1] : '0'
  return (
    <span>
      {BigNumber(arr[0]).toLocaleString(i18nextLng)}
      <span className="decimal">.</span>
      <span className="light">
        {BigNumber(`0.${arr[1]}`).toFixed(decimals).split('.')[1]}
      </span>
    </span>
  )
}

const with18Decimals = (price, i18nextLng = 'en-US') => {
  return withDecimals(price, i18nextLng, 18)
}

export default with18Decimals
