import {
  GET_USER_BALANCES,
  GET_BALANCE_IN_OLD_DEX,
  GET_USER_DISTRIBUTION_BALANCES,
  RESET_CLAIMABLE_ETH,
  RESET_CLAIMABLE_HODLC
} from '../types'
import BigNumber from 'bignumber.js'

const INITIAL_STATE = {
  eth: new BigNumber(0),
  hodlc: new BigNumber(0),
  hodlt: new BigNumber(0),
  ethinUSD: new BigNumber(0),
  hodlcinUSD: new BigNumber(0),
  redeemPower: new BigNumber(0),
  oldEthBalance: new BigNumber(0),
  ethDistAmt: new BigNumber(0),
  hodlDistAmt: new BigNumber(0)
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_BALANCES:
      return { ...state, ...action.payload }
    case RESET_CLAIMABLE_ETH:
      return { ...state, ethDistAmt: new BigNumber(0) }
    case RESET_CLAIMABLE_HODLC:
      return { ...state, hodlDistAmt: new BigNumber(0) }
    case GET_USER_DISTRIBUTION_BALANCES:
      return { ...state, ...action.payload }
    case GET_BALANCE_IN_OLD_DEX:
      return { ...state, oldEthBalance: action.payload }
    default:
      return state
  }
}
