import React from 'react'
import networkConfig from '../global-config'

const renderContent = () => {
  let tag = ''
  let content = ''
  const { enableTrade, enableWalletFunction } = networkConfig
  if (!enableTrade && !enableWalletFunction) {
    content =
      'The Dex is currently under maintainance and feature upgrade, Please check back in a few day!'
    tag = 'All Disabled'
  } else if (!enableTrade && enableWalletFunction) {
    content =
      'The Trading on the Dex is currently under maintainance and feature upgrade. Please check back in a few day!'
    tag = 'Trading Disabled'
  } else if (!enableWalletFunction && enableTrade) {
    content =
      'The Wallet on the Dex is currently under maintainance and feature upgrade. Please check back in a few day!'
    tag = 'Wallet Disabled'
  }
  return { content, tag }
}

const DisabledBanner = () => {
  return (
    <div className="banner-container disable-banner">
      <div className="body">
        <div className="header">
          {/* <div className='tag'>{renderContent().tag}</div> */}
          <div className="title">The Dex is currently disabled.</div>
        </div>
        <div className="content">{renderContent().content}</div>
      </div>
    </div>
  )
}

export default DisabledBanner
