import React, { Component } from 'react'

import { connect } from 'react-redux'

// Install Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  getUserBalances,
  getDexData,
  initBlockchainEnvironment,
  getBalanceInOldDex,
  getHODLRate
} from '../redux/actions'
import Router from './Router'
import Navigator from '../components/Navs/Navigator'
import connector from '../connector'
import Navbar from '../components/Navs/Navbar'
import Info from '../components/Navs/bottom'
import config from '../config'
import Modal from '../components/Modal/BaseModal'

import globalConfig from '../global-config'

class App extends Component {
  componentWillUnmount() {
    clearInterval(this.myinterval2)
  }

  state = {
    showNotice: true
  }

  setShowNotice = (val) => {
    this.setState({showNotice: val})
  }

  componentDidMount() {
    // init blockchain environment
    this.props
      .initBlockchainEnvironment()
      .then((data) => {
        const { web3 } = data
        this.props.getBalanceInOldDex(web3)
        connector.getCurrentRate(web3)
        // init
        setTimeout(() => {
          console.log('get rate!')
          this.props.getHODLRate(web3)
        }, 500)
        try {
          this.myinterval2 = setInterval(() => {
            this.props.getHODLRate(web3)
          }, config.rqstInteval.dexData) // request dex data every 5 minutes
        } catch (e) {
          console.log(e)
        }
      })
      .catch(console.error)
  }

  async UNSAFE_componentWillMount() {
    await connector.getHODLRate(this.props.web3)
  }

  render() {
    return (
      <div>
        {(this.state.showNotice && globalConfig.notice.enabled) && (
        <Modal
          mode="primary"
          size="sm"
          clickToClose
          pressToClose
          modalHeader={"Important Notice"}
          hideModal={() => this.setShowNotice(false)}
        >
          <div className="disclaimer-paragraph">
            <h3>{globalConfig.notice.heading}</h3>
            <p>{globalConfig.notice.message}</p>
            <p>Few wallet functions will still be operational.Please check the list bellow.</p>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
              <div>
                <p style={{margin:0}}><b>Disabled Wallet functions</b></p>
                <ul>
                  <li>Deposit ETH</li>
                  <li>Buy HODLC</li>
                  <li>Sell HODLC</li>
                  <li>HODLC to HODLT</li>
                  <li>HODLT to HODLT</li>
                  <li>Claim HODLC</li>
                </ul>
              </div>
              <div>
                <p style={{margin:0}}><b>Operational Wallet functions</b></p>
                <ul>
                  <li>Withdraw ETH</li>
                  <li>Claim ETH</li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{display:"flex", justifyContent: "center"}}>
            {
              globalConfig.vtbdexUrl ? 
            <button
              onClick={() => window.open(globalConfig.vtbdexUrl,"_blank")}
              className="btn secondary md"
            >
              Go to VTBDex
            </button>:
            <button
              onClick={() => this.setShowNotice(false)}
              className="btn secondary md"
              style={{ margin: 0 }}
            >
              Close
            </button>
            }
          </div>
        </Modal>
      )}
        <Navigator />
        <Navbar />
        <Router />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Info />
      </div>
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    account: blockchain.account,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps, {
  getUserBalances,
  getDexData,
  initBlockchainEnvironment,
  getBalanceInOldDex,
  getHODLRate
})(App)
