import React from 'react'
import EventList from '../../components/EventList'
import { connect } from 'react-redux'
import { getDexData } from '../../redux/actions'

class Explorer extends React.Component {
  componentDidMount() {
    document.title = 'HODL DEX | Explorer'
    this.props.getDexData()
  }

  render() {
    return (
      <div className="explorer-component">
        <div className="container">
          <div className="dashboard-session">
            <EventList scope="global" />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { getDexData })(Explorer)
