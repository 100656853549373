import moment from 'moment'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'

export const cleanData = (data) => {
  const web3 = new Web3()
  const asyncFunctions = []
  const newData = []
  const calculate = async (ele) => {
    const txnEth = await web3.utils.fromWei(ele.returnValues.txnEth, 'ether')
    const ETHUSD = await web3.utils.fromWei(ele.ETH_USD, 'ether')
    newData.push({
      txnEth: BigNumber(ETHUSD).times(txnEth).toString(),
      blockNumber: ele.blockNumber,
      timeStamp: ele.timeStamp
    })
  }
  for (let i = 0; i < data.length; i++) {
    const ele = data[i]
    asyncFunctions.push(calculate(ele))
  }
  const startingTimeStamp = 1603522800
  const dayIncrement = 86400
  const dailyEthData = []
  const txnCount = []
  return Promise.all(asyncFunctions).then(() => {
    let txnData = []
    let ETHTotal = 0
    let count = 1
    for (let i = 0; i < newData.length; i++) {
      const element = newData[i]
      const currentMin = startingTimeStamp + dayIncrement * (count - 1)
      const currentMax = startingTimeStamp + dayIncrement * count
      if (element.timeStamp > currentMin && element.timeStamp < currentMax) {
        ETHTotal += Number(element.txnEth)
        txnData.push(element)
      } else if (element.timeStamp > currentMax) {
        txnCount.push({
          ETHTransactions: txnData.length,
          timeStamp: moment(currentMin * 1000).format('YYYY-MM-DD'),
          time: currentMin * 1000
        })
        txnData = []
        dailyEthData.push({
          ETHTotal,
          timeStamp: moment(currentMin * 1000).format('YYYY-MM-DD'),
          time: currentMin * 1000
        })
        ETHTotal = 0
        count++
      }
    }
    return { dailyEthData, txnCount }
  })
}

export const mergeTwoData = (volume, price) => {
  const mergedData = []
  for (let i = 0; i < price.length; i++) {
    const element = price[i]
    const data = {
      ...element,
      ...volume.find((ele) => ele.timeStamp === element.timeStamp)
    }
    mergedData.push(data)
  }
  return mergedData
}

export const mergeEOSETHVolumeData = (ETHdata, EOSdata) => {
  const allData = []
  for (let i = 0; i < ETHdata.length; i++) {
    const data = ETHdata[i]
    const findObj = EOSdata.find((e) => e.timeStamp === data.timeStamp)
    if (findObj) {
      allData.push({
        ETHTotal: data.ETHTotal,
        EOSTotal: findObj.EOSTotal,
        timeStamp: data.timeStamp,
        time: data.time
      })
    } else {
      allData.push({
        ETHTotal: data.ETHTotal,
        EOSTotal: 0,
        timeStamp: data.timeStamp,
        time: data.time
      })
    }
  }
  return allData
}

export const mergeEOSETHTxnData = (ETHTxnData, EOSTxnData) => {
  const allTxnData = []
  for (let i = 0; i < ETHTxnData.length; i++) {
    const data = ETHTxnData[i]
    const findObj = EOSTxnData.find((e) => e.timeStamp === data.timeStamp)
    if (findObj) {
      allTxnData.push({
        ETHTransactions: data.ETHTransactions,
        EOSTransactions: findObj.EOSTransactions,
        timeStamp: data.timeStamp,
        time: data.time
      })
    } else {
      allTxnData.push({
        ETHTransactions: data.ETHTransactions,
        EOSTransactions: 0,
        timeStamp: data.timeStamp,
        time: data.time
      })
    }
  }
  return allTxnData
}

export const cleanEOSData = (data) => {
  const newData = []
  for (let i = 0; i < data.length; i++) {
    const ele = data[i]
    let txnUSD = 0
    if (ele.currency === 'EOS') {
      txnUSD = Number(ele.EOS_USD) * 0.0001 * Number(ele.volume)
    } else {
      txnUSD = Number(ele.HODLC_USD) * 0.0001 * Number(ele.volume)
    }
    newData.push({
      timeStamp: ele.dayStamp,
      blockNumber: ele.Blockid,
      Time: ele.Time,
      txnUSD
    })
  }
  const dailyEosData = []
  const txnCount = []
  const startingTimeStamp = 1618038000
  const dayIncrement = 86400
  let txnData = []
  let EOSTotal = 0
  let count = 1
  for (let i = 0; i < newData.length; i++) {
    const element = newData[i]
    const currentMin = startingTimeStamp + dayIncrement * (count - 1)
    const currentMax = startingTimeStamp + dayIncrement * count
    if (element.Time > currentMin && element.Time < currentMax) {
      EOSTotal += element.txnUSD
      txnData.push(element)
    } else if (element.Time > currentMax) {
      txnCount.push({
        EOSTransactions: txnData.length,
        timeStamp: moment(currentMin * 1000).format('YYYY-MM-DD')
      })
      txnData = []
      dailyEosData.push({
        EOSTotal,
        timeStamp: moment(currentMin * 1000).format('YYYY-MM-DD')
      })
      EOSTotal = 0
      count++
    }
  }
  return { dailyEosData, txnCount }
}

export const groupData = (data, time, type = 'volume') => {
  if (Number(time) === 0) {
    return data
  }
  const startMonth =
    data[0].timeStamp.slice(0, 7) === '2020-10' ? 1601535600000 : data[0].time // 10.1.2020
  const monthInMS = 2629743833
  let count = 1
  let txnData = []
  let ETHTotal = 0
  let EOSTotal = 0
  let ETHTransactions = 0
  let EOSTransactions = 0
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    const currentMin = startMonth + monthInMS * time * (count - 1)
    const currentMax = startMonth + monthInMS * time * count
    if (element.time > currentMin && element.time < currentMax) {
      if (type === 'volume') {
        ETHTotal += Number(element.ETHTotal)
        EOSTotal += Number(element.EOSTotal)
      } else {
        ETHTransactions += Number(element.ETHTransactions)
        EOSTransactions += Number(element.EOSTransactions)
      }
    } else if (element.time > currentMax || i === data.length - 1) {
      if (type === 'volume') {
        txnData.push({
          ETHTotal,
          EOSTotal,
          timeStamp: moment(currentMax).format('YYYY-MM-DD'),
          HODLprice: element.HODLprice,
          time: currentMax
        })
        ETHTotal = 0
        EOSTotal = 0
      } else {
        // here is txn
        txnData.push({
          ETHTransactions,
          EOSTransactions,
          timeStamp: moment(currentMax).format('YYYY-MM-DD'),
          HODLprice: element.HODLprice,
          time: currentMax
        })
        ETHTransactions = 0
        EOSTransactions = 0
      }
      count++
    }
  }
  return txnData
}

export const sliceData = (data, time) => {
  if (time === 'all') return data
  const slicedData = data.slice(data.length - time, data.length)
  return slicedData
}
