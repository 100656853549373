import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Translation } from 'react-i18next'
import { connect } from 'react-redux'
import Wallet from './Wallet'
import Explorer from './Explorer'
import Trade from './Trade'
import Calculator from './Calculator'
import Graph from '../shared-components/graph'
import Admin from './_Admin'
import TxnDetail from './TxnDetail'
import Ambassador from './Ambassador'
import networkConfig from '../global-config'

class Router extends React.Component {
  render() {
    return (
      <>
        <div className="dashboard-container">
          <Switch>
            <Route exact path="/">
              <Redirect to="/trade" />
            </Route>
            <Route
              path="/wallet/:type/:action"
              component={() => (
                <Translation>{(t) => <Wallet t={t} />}</Translation>
              )}
            />
            <Route
              path="/admin"
              component={() => <Translation>{(t) => <Admin />}</Translation>}
            />
            <Route
              path="/ambassador/:ambassadorID"
              component={({ match }) => (
                <Translation>{(t) => <Ambassador match={match} />}</Translation>
              )}
            />
            <Route
              path="/wallet"
              component={() => (
                <Translation>{(t) => <Wallet t={t} />}</Translation>
              )}
            />
            <Route
              exact
              path="/trade/myOrders"
              component={({ match }) => (
                <Translation>
                  {(t) => <Trade t={t} match={match} />}
                </Translation>
              )}
            />
            <Route
              exact
              path="/trade/global"
              component={({ match }) => (
                <Translation>
                  {(t) => <Trade t={t} match={match} />}
                </Translation>
              )}
            />
            <Route
              path="/trade"
              component={({ match }) => (
                <Translation>
                  {(t) => <Trade t={t} match={match} />}
                </Translation>
              )}
            />
            <Route
              path="/explorer/:txnHash"
              component={(props) => (
                <Translation>
                  {(t) => <TxnDetail t={t} {...props} />}
                </Translation>
              )}
            />
            <Route
              path="/explorer"
              component={() => (
                <Translation>{(t) => <Explorer t={t} />}</Translation>
              )}
            />
            {networkConfig.enableCalculator && (
              <Route
                path="/calculator"
                component={({ match }) => (
                  <Translation>
                    {(t) => (
                      <Calculator
                        t={t}
                        rates={this.props.rates}
                        web3={this.props.web3}
                        match={match}
                      />
                    )}
                  </Translation>
                )}
              />
            )}
            {networkConfig.enableGraph && (
              <Route
                path="/graph"
                component={({ match }) => (
                  <Translation>
                    {(t) => (
                      <Graph
                        t={t}
                        rates={this.props.rates}
                        web3={this.props.web3}
                        match={match}
                      />
                    )}
                  </Translation>
                )}
              />
            )}
            <Redirect to="/trade" />
          </Switch>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ trade, blockchain }) => {
  return { rates: trade.rates, web3: blockchain.web3 }
}

export default connect(mapStateToProps)(Router)
