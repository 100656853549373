import BigNumber from 'bignumber.js'
import {
  calRecurring,
  dailyDeposit,
  HODLCPriceSimulate,
  HODLCSimpleCashOut
} from './helper'
const timeIndex = { daily: 1, weekly: 7, monthly: 30 }
const timeDisc = { daily: 'days', weekly: 'weeks', monthly: 'months' }
const calculator = (
  amount = 500,
  length = 180,
  avgTransaction = 500,
  currentTime = [],
  calType = '',
  timePeriod = '',
  rates = 1,
  currency = 'USD',
  cashOutPeriod = 0
) => {
  let result = {}
  if (calType === 'holding') {
    // current HODL count
    const HODLCount = BigNumber(amount).dividedBy(rates).toFixed(18)
    const chartData = HODLCPriceSimulate(
      amount,
      rates,
      HODLCount,
      length,
      avgTransaction,
      currentTime,
      currency
    )
    const lastTimeStampItem = chartData[chartData.length - 1] || 0
    result.totalValueAtTheEnd = lastTimeStampItem.value
    result.totalGrowth = lastTimeStampItem.growth
    result.chartData = chartData
    return result
  } else if (calType === 'recurring') {
    const daysLevelsDict = Array.from(Array(Number(length + 1)).keys())
    daysLevelsDict.shift()
    let newChartData = []
    if (length > 0 && timePeriod === 'daily') {
      newChartData = dailyDeposit(
        amount,
        length * 30 - 1,
        avgTransaction,
        currentTime,
        timeIndex[timePeriod],
        timeDisc[timePeriod],
        rates,
        currency
      )
    } else {
      newChartData = calRecurring(
        amount,
        length * 30 - 1,
        avgTransaction,
        currentTime,
        timeIndex[timePeriod],
        timeDisc[timePeriod],
        rates,
        currency
      )
    }
    const lastTimeStampItem = newChartData[newChartData.length - 1] || 0
    result.totalValueAtTheEnd = lastTimeStampItem.value
    result.totalGrowth = lastTimeStampItem.growth || 0
    result.chartData = newChartData
    return result
  } else if (calType === 'cashOut') {
    const chartData = HODLCSimpleCashOut(
      amount,
      rates,
      length,
      avgTransaction,
      currentTime,
      currency,
      timeIndex[cashOutPeriod],
      timeDisc[cashOutPeriod]
    )
    const lastTimeStampItem = chartData[chartData.length - 1] || 0
    result.totalaccCashOutValue = lastTimeStampItem.accCashOutValue
    result.currentHODLCount = Number(lastTimeStampItem.HODLCount)
    result.avgCashOutValue =
      lastTimeStampItem.accCashOutValue / (length / timeIndex[cashOutPeriod])
    result.chartData = chartData
    return result
  }
}

export default calculator
