import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getAllETHPrice } from '../../redux/actions'
import { Link } from 'react-router-dom'

const Loader = () => {
  return (
    <div className="loading-component">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  )
}

const ExplorerMarketInfo = (props) => {
  const [ethPrice, setEthPrice] = useState(0)
  useEffect(() => {
    const price = localStorage.getItem('ethInUsd').split('@')[0]
    setEthPrice(Number(price).toFixed(2))
  }, [])
  const renderContractBalance = (eth) => {
    const ethVolume = Number(eth).toFixed(2)
    const usdVolume = (ethVolume * ethPrice).toFixed(2)
    return (
      <div>
        <div className="main">
          {usdVolume} <span className="unit">USD</span>
        </div>
        <div className="notes">
          = {ethVolume} <span className="unit">ETH</span>
        </div>
      </div>
    )
  }
  const renderDepositAmount = (events) => {
    const ethVolume = events
      .filter((e) => e.type === 'deposit')
      .reduce((acc, curr) => acc + Number(curr.amount), 0)
      .toFixed(2)
    const usdVolume = (ethVolume * ethPrice).toFixed(2)
    return (
      <div>
        <div className="main">
          {usdVolume} <span className="unit">USD</span>
        </div>
        <div className="notes">
          = {ethVolume} <span className="unit">ETH</span>
        </div>
      </div>
    )
  }
  const renderBuyAmount = (events) => {
    const ethVolume = events
      .filter((e) => e.type === 'SellOrderFilled')
      .reduce((acc, curr) => acc + Number(curr.amount), 0)
      .toFixed(2)
    const usdVolume = (ethVolume * ethPrice).toFixed(2)
    return (
      <div>
        <div className="main">
          {usdVolume} <span className="unit">USD</span>
        </div>
        <div className="notes">
          = {ethVolume} <span className="unit">ETH</span>
        </div>
      </div>
    )
  }
  const renderBuyTxns = (events) => {
    events = events.filter((e) => e.type === 'SellOrderFilled')
    const maxVolumeItem = events.reduce(
      (p, c) => (p.amount > c.amount ? p : c),
      0
    )
    const maxVolume = Number(maxVolumeItem.amount) || 0
    return events.map((e) => {
      return (
        <span
          key={e.transactionHash}
          className="bar"
          style={{ height: `${(e.amount / maxVolume) * 100}%` }}
        />
      )
    })
  }
  const { t, events } = props
  return (
    <div className="explorer-market-info">
      <div className="info-card-group">
        <div className="info-card">
          <div className="title">
            {t('explorer.insights.items.contractBalance')}
          </div>
          <div className="content">
            {props.contractBalanceEth ? (
              renderContractBalance(props.contractBalanceEth)
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="info-card">
          <div className="title">
            {t('explorer.insights.items.depositAmount')}
          </div>
          <div className="content">
            {events.length ? renderDepositAmount(events) : <Loader />}
          </div>
        </div>
        <div className="info-card">
          <div className="title">{t('explorer.insights.items.buyVolume')}</div>
          <div className="content">
            {events.length ? renderBuyAmount(events) : <Loader />}
          </div>
        </div>
        <div className="info-card">
          <div className="title">
            {t('explorer.insights.items.buyTransactions', {
              length: events.filter((e) => e.type === 'SellOrderFilled').length
            })}{' '}
            (<Link to="/graph">see graph</Link>)
          </div>
          <div className="content">
            <div className="buy-txn-graph">{renderBuyTxns(events)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ blockchain, trade }) => {
  return {
    web3: blockchain.web3,
    contractBalanceEth: trade.contractBalanceEth,
    hodlerCount: trade.hodlerCount
  }
}

export default connect(mapStateToProps, { getAllETHPrice })(
  withTranslation()(ExplorerMarketInfo)
)
