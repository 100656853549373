import { toast } from 'react-toastify'

const notify = {
  error: (txt) => {
    console.log('[notify] error txt', txt)
    toast.error(txt, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  },
  success: (txt) => {
    console.log('[notify] success txt', txt)
    toast.info(txt || 'Submit successfully!', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
}

export default notify
