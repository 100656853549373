import Modal from './Modal'
import ReactDOM from 'react-dom'
import React from 'react'

const BaseModal = (props) => {
  return ReactDOM.createPortal(
    <Modal {...props} />,
    document.querySelector('#modal')
  )
}

export default BaseModal
