import React, { useState, useEffect, useCallback } from 'react'
import calculator from './util/calculator'
import _ from 'lodash'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { useTranslation, Trans } from 'react-i18next'

import BigNumber from 'bignumber.js'
const count = {
  daily: 30,
  weekly: 4,
  monthly: 1
}
const CALCULATOR_MODE = 'recurring'
const RecurringCalculator = ({ avgDay = 7, avgTransaction, rates }) => {
  const [amount, setAmount] = useState(500)
  const [currency, setCurrency] = useState('USD')
  const [length, setLength] = useState(6)
  const [transactions, setTransactions] = useState(avgTransaction)
  const [result, setResult] = useState(0)
  const [chartData, setChartData] = useState([])
  const [timePeriod, setTimePeriod] = useState('monthly')
  const [totalGrowth, setTotalGrowth] = useState('0')
  const { t } = useTranslation()
  const getResult = () => {
    const currentDate = new Date()
    if (rates > 0) {
      const result =
        calculator(
          amount,
          length,
          transactions,
          currentDate.valueOf(),
          CALCULATOR_MODE,
          timePeriod,
          rates,
          currency
        ) || 0
      const endValueInUSD = result.totalValueAtTheEnd
      const chartData = result.chartData
      const totalGrowth = result.totalGrowth
      setChartData(chartData)
      setResult(endValueInUSD)
      setTotalGrowth(totalGrowth)
    }
  }

  const callBackGetResult = useCallback(_.debounce(getResult, 1000), [
    amount,
    length,
    transactions,
    timePeriod,
    currency
  ])
  useEffect(() => {
    callBackGetResult(amount, length, transactions, currency)
  }, [amount, length, transactions, timePeriod, currency, callBackGetResult])

  return (
    <>
      <div className="info-session">
        <form>
          <div>
            <>
              {t('calculator.recurring.form.inputAmount.1')}
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="HODLC">HODLC</option>
              </select>
              <select
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
              >
                <option value="daily">
                  {t('calculator.recurring.form.inputAmount.1-options.daily')}
                </option>
                <option value="weekly">
                  {t('calculator.recurring.form.inputAmount.1-options.weekly')}
                </option>
                <option value="monthly">
                  {t('calculator.recurring.form.inputAmount.1-options.monthly')}
                </option>
              </select>
              {t('calculator.recurring.form.inputAmount.2')}
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
              {t('calculator.recurring.form.inputAmount.3')}
              {/* {renderTimeLength(timePeriod)} */}
              <br />
            </>
            {t('calculator.recurring.form.aboutTransactionPerDay.1')}
            <div className="ui input">
              <input
                type="number"
                value={transactions}
                onChange={(e) => setTransactions(e.target.value)}
              />
            </div>
            {t('calculator.recurring.form.aboutTransactionPerDay.2')}
            <br />
            <small className="notes">
              <Trans
                i18nKey="calculator.recurring.form.references"
                values={{ avgTransaction, avgDay }}
                components={{ b: <b /> }}
              />
            </small>
          </div>
        </form>
        <div className="result-text">
          <Trans
            i18nKey="calculator.recurring.summary.endAmount"
            values={{
              result:
                result &&
                result.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })
            }}
            components={{ b: <b /> }}
          />
        </div>
        <div className="result-text">
          <Trans
            i18nKey="calculator.recurring.summary.endGrowth"
            values={{
              result:
                totalGrowth &&
                totalGrowth.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })
            }}
            components={{ b: <b /> }}
          />
        </div>
        <div className="result-text">
          <Trans
            i18nKey="calculator.recurring.summary.endPercentageIncrease"
            values={{
              result:
                totalGrowth &&
                amount &&
                BigNumber(totalGrowth)
                  .dividedBy(amount * count[timePeriod] * length)
                  .times(100)
                  .toFixed(2)
            }}
            components={{ b: <b /> }}
          />
        </div>
      </div>
      <div className="chart-container">
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 10,
              right: 20,
              left: 10,
              bottom: 10
            }}
          >
            <XAxis
              dataKey="timeLength"
              // tick={<CustomizedAxisTick/>}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              unit="USD"
              dataKey="value"
              strokeWidth="3px"
              stroke="#3a2bac"
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Line
              type="monotone"
              unit="USD"
              dataKey="growth"
              strokeWidth="3px"
              stroke="#00bfc7"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default RecurringCalculator
