import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Index extends Component {
  renderIcon = (wallet) => {
    if (wallet === 'metamask') {
      return (
        <img
          alt="wallet"
          className=""
          src={require('../assets/images/metamask.svg')}
          width="15px"
          style={{ display: 'inline-block' }}
        />
      )
    } else if (wallet === 'wallet-connect') {
      return (
        <img
          alt="wallet"
          className=""
          src={require('../assets/images/walletConnect.svg')}
          width="15px"
          style={{ display: 'inline-block' }}
        />
      )
    } else {
      return <span> {this.props.t('navigator.wallet')}</span>
    }
  }

  render() {
    return (
      <div>
        {this.renderIcon(localStorage.getItem('currentBlockchainWallet'))}
      </div>
    )
  }
}

export default withTranslation()(Index)
