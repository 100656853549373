import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { notify } from '../../utils'
import { withTranslation } from 'react-i18next'
import converter from '../../connector/converters'
import BigNumber from 'bignumber.js'
import {
  depositEth,
  withdrawEth,
  hodlTIssue,
  hodlTRedeem,
  approveHodlT
} from '../../redux/actions'

class Modal extends React.Component {
  state = {
    amount: 0,
    convertAmt: 0
  }

  onSubmit = async () => {
    const { t } = this.props
    if (!this.state.amount || this.state.amount <= 0) {
      return notify.error(t('wallet.notify.nothing'))
    }
    const { eth, hodlc, hodlt, redeemPower, web3, account } = this.props
    if (!this.props.actionName) return
    if (this.props.type === 'eth') {
      if (this.props.actionName === 'Deposit') {
        if (!account) {
          return notify.error(t('wallet.notify.notConnected'))
        }
        const ethBalanceInWei = await web3.eth.getBalance(account)
        const limitEthBalanceInEther = web3.utils.fromWei(
          ethBalanceInWei,
          'ether'
        )
        if (
          BigNumber(this.state.amount).isGreaterThan(limitEthBalanceInEther)
        ) {
          return notify.error(
            t('wallet.notify.depositeEthMax', {
              limit: limitEthBalanceInEther,
              type: this.props.type.toUpperCase()
            })
          )
        }
        this.props
          .depositEth(this.props.web3, this.state.amount)
          .then((res) => {
            if (res) {
              notify.error(this.props.t('system.blockchain.failed'))
            } else {
              notify.success(this.props.t('system.blockchain.confirmed'))
            }
          })
      } else if (this.props.actionName === 'Withdraw') {
        if (BigNumber(this.state.amount).isGreaterThan(eth)) {
          return notify.error(
            t('wallet.notify.withdrawEthMax', {
              eth,
              type: this.props.type.toUpperCase()
            })
          )
        }
        this.props
          .withdrawEth(this.props.web3, this.state.amount)
          .then((res) => {
            if (res) {
              notify.error(this.props.t('system.blockchain.failed'))
            } else {
              notify.success(this.props.t('system.blockchain.confirmed'))
            }
          })
      }
    } else if (
      this.props.type === 'hodlc' &&
      this.props.actionName === 'To HODLT'
    ) {
      const tToC = (
        await converter.convertHodlTToHodlC(this.props.web3, this.state.amount)
      ).toFixed(18)
      const zero18One = BigNumber(0).plus(1e-18).toFixed(18)
      if (BigNumber(tToC).isLessThanOrEqualTo(zero18One)) {
        return notify.error(t('wallet.notify.cannotRemoveDust'))
      }
      if (BigNumber(tToC).isGreaterThan(hodlc.toFixed(18))) {
        return notify.error(
          t('wallet.notify.toHodltMax', {
            hodlc,
            type: this.props.type.toUpperCase()
          })
        )
      }
      this.props.hodlTIssue(this.props.web3, this.state.amount).then((res) => {
        if (res) {
          notify.error(this.props.t('system.blockchain.failed'))
        } else {
          notify.success(this.props.t('system.blockchain.confirmed'))
        }
      })
    } else if (this.props.type === 'hodlt') {
      if (this.props.actionName === 'To HODLC') {
        const zero18One = BigNumber(0).plus(1e-18).toFixed(18)
        if (BigNumber(this.state.amount).isLessThanOrEqualTo(zero18One)) {
          return notify.error(t('wallet.notify.cannotRemoveDust'))
        }
        if (BigNumber(this.state.amount).isGreaterThan(redeemPower)) {
          return notify.error(
            t('wallet.notify.toHodlcMax', {
              redeemPower,
              type: this.props.type.toUpperCase()
            })
          )
        }
        this.props
          .hodlTRedeem(this.props.web3, this.state.amount)
          .then((res) => {
            if (res) {
              notify.error(this.props.t('system.blockchain.failed'))
            } else {
              notify.success(this.props.t('system.blockchain.confirmed'))
            }
          })
      } else if (this.props.actionName === 'Approve') {
        const limit = hodlt
        if (BigNumber(this.state.amount).isGreaterThan(limit)) {
          return notify.error(
            t('wallet.notify.approveMax', {
              limit,
              type: this.props.type.toUpperCase()
            })
          )
        }
        this.props
          .approveHodlT(this.props.web3, this.state.amount)
          .then((res) => {
            if (res) {
              notify.error(this.props.t('system.blockchain.failed'))
            } else {
              notify.success(this.props.t('system.blockchain.confirmed'))
            }
          })
      }
    }
    this.props.hideModal()
    if (this.props.actionName === 'To HODLC') {
      return notify.success(
        t('wallet.notify.toHodlc', {
          hodlcAmount: this.state.convertAmt,
          amount: this.state.amount
        })
      )
    } else if (this.props.actionName === 'To HODLT') {
      return notify.success(
        t('wallet.notify.toHodlt', {
          hodlcAmount: this.state.convertAmt,
          amount: this.state.amount
        })
      )
    } else {
      const actionI18N = this.props.t(
        `wallet.notify.otherActions.action.${this.props.actionName.toLowerCase()}`
      )
      const unitsI18N = this.props.t(
        `wallet.notify.otherActions.units.${this.props.type.toLowerCase()}`
      )
      return notify.success(
        t('wallet.notify.otherActions.template', {
          action: actionI18N,
          amount: this.state.amount,
          units: unitsI18N
        })
      )
      // `You ${this.props.actionName} ${this.state.amount} ${this.props.type}, waiting for Ethereum to confirm.`
    }
  }

  renderConvert = (value) => {
    const { t } = this.props
    if (this.props.actionName === 'Approve' || this.props.type === 'eth') {
      return ' '
    } else {
      return (
        <div className="field">
          <div className="ui huge transparent input sub-input">
            &#8776;<small>{t('wallet.modal.content.estimated')}</small>
            <input value={this.state.convertAmt} readOnly />
            <div className="unit" valign="middle">
              HODLC
            </div>
          </div>
        </div>
      )
    }
  }

  renderMax = (action) => {
    switch (action) {
      case 'To HODLT':
        return (
          <button
            className="btn max-btn"
            onClick={async () =>
              this.setState({
                amount: (
                  await converter.convertHodlCToHodlT(
                    this.props.web3,
                    this.props.max.toString()
                  )
                ).toFixed(18),
                convertAmt: this.props.max.toFixed(18)
              })
            }
          >
            {this.props.t('wallet.modal.max')}
          </button>
        )
      case 'To HODLC':
        return (
          <button
            className="btn max-btn"
            onClick={async () =>
              this.setState({
                amount: this.props.max.toFixed(18),
                convertAmt: (
                  await converter.convertHodlTToHodlC(
                    this.props.web3,
                    this.props.max.toString()
                  )
                ).toFixed(18)
              })
            }
          >
            {this.props.t('wallet.modal.max')}
          </button>
        )
      case 'Deposit':
        return <div></div>
      default:
        return (
          <button
            className="btn max-btn"
            onClick={() =>
              this.setState({
                amount: this.props.max,
                convertAmt: this.props.max
              })
            }
          >
            {this.props.t('wallet.modal.max')}
          </button>
        )
    }
  }

  render() {
    const { t } = this.props
    return ReactDOM.createPortal(
      <div
        className="ui dimmer modals visible active"
        onClick={() => this.props.hideModal()}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="ui small modal visible active main-modal"
        >
          <div className={'ui top attached big label modal-title'}>
            {t(`wallet.modal.title.${this.props.actionName}`)}
          </div>
          <div className="modal-body">
            <div className="top-group" onClick={(e) => e.stopPropagation()}>
              <div className="input-label">
                {this.props.type === 'eth'
                  ? this.props.type.toUpperCase()
                  : 'HODLT'}{' '}
                {t('wallet.modal.content.amount')}:
              </div>
              {this.renderMax(this.props.actionName)}
            </div>
            <form className="ui form" onSubmit={this.onSubmit}>
              <input
                className="modal-input"
                placeholder="Desired amount"
                value={this.state.amount}
                onChange={async (e) =>
                  this.setState({
                    amount: e.target.value,
                    convertAmt: BigNumber(
                      await converter.convertHodlTToHodlC(
                        this.props.web3,
                        e.target.value
                      )
                    ).toFixed(18)
                  })
                }
              />
              <div className="equivalent-container">
                {this.renderConvert(this.state.amount)}
              </div>
              <div
                className="modal-button-group"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="ui button bg-modal-button cancel"
                  onClick={() => this.props.hideModal()}
                >
                  {t('wallet.modal.btn.cancel')}
                </button>
                <button
                  className="ui button bg-modal-button highlight"
                  type="submit"
                >
                  {t('wallet.modal.btn.yes')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  }
}

const mapStateToProps = ({ wallet, blockchain, trade }) => {
  return {
    eth: wallet.eth,
    hodlc: wallet.hodlc,
    hodlt: wallet.hodlt,
    redeemPower: wallet.redeemPower,
    ethinUSD: wallet.ethinUSD,
    hodlcinUSD: wallet.hodlcinUSD,
    account: blockchain.account,
    web3: blockchain.web3,
    rates: trade.rates
  }
}
export default connect(mapStateToProps, {
  depositEth,
  withdrawEth,
  hodlTIssue,
  hodlTRedeem,
  approveHodlT
})(withTranslation()(Modal))
