import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { connect } from 'react-redux'
import { getAllETHPrice } from '../../redux/actions'
import web3instance from '../../connector/web3'

const TOTAL_SUPPLY = 20000000
const MILIION = 1000000

const MobileMarketInfo = ({
  t,
  reserveBalanceHodlc,
  rates,
  getAllETHPrice,
  web3
}) => {
  const [percentage, setPercentage] = useState(0)
  useEffect(() => {
    const localWeb3 = web3instance(web3)
    getAllETHPrice(localWeb3, { return: true, lengthInDays: 7 }).then((res) => {
      const change = (((Number(rates) - res) / res) * 100).toFixed(3)
      setPercentage(change)
    })
  }, [rates])
  return (
    <div className="dashboard-session dashboard-market-info">
      <div className="ui header session-header">Market Info</div>
      <div className="info-card-group">
        <div className="info-card">
          <div className="ui header">{t('marketInfo.items.reserve')}</div>
          <div className="content">
            {BigNumber(reserveBalanceHodlc).dividedBy(MILIION).toFixed(2)}M
            HODLC
          </div>
        </div>
        <div className="info-card">
          <div className="ui header">{t('marketInfo.items.marketcap')}</div>
          <div className="content">
            $
            {BigNumber(TOTAL_SUPPLY).times(rates).dividedBy(MILIION).toFixed(2)}
            M
          </div>
        </div>
      </div>
      <div className="info-card-group">
        <div className="info-card">
          <div className="ui header">{t('marketInfo.items.circulation')}</div>
          <div className="content">
            {BigNumber(TOTAL_SUPPLY)
              .minus(reserveBalanceHodlc)
              .dividedBy(MILIION)
              .toFixed(2)}
            M HODLC ($
            {BigNumber(TOTAL_SUPPLY)
              .minus(reserveBalanceHodlc)
              .dividedBy(MILIION)
              .times(rates)
              .toFixed(2)}
            M)
          </div>
        </div>
        <div className="info-card">
          <div className="ui header">{t('marketInfo.items.hodlcPrice')}</div>
          <div className="content">
            ${BigNumber(rates).toFixed(4)}
            <span className="price-percentage-indicator">{`+${percentage}%`}</span>
            <span className="price-percentage-indicator-length">7d</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ graph, blockchain }) => {
  const data = graph.price
  const lastPrice = data[data.length - 1].HODOLprice
  return {
    price: lastPrice,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps, { getAllETHPrice })(
  withTranslation()(MobileMarketInfo)
)
