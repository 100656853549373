import Web3 from 'web3'
import config from './config'

const web3instance = (web3) => {
  const { INFURA_ENDPOINT } = config.refreshBlockchainInfraInfo()
  if (!web3) {
    if (INFURA_ENDPOINT.includes('http')) {
      return new Web3(new Web3.providers.HttpProvider(INFURA_ENDPOINT))
    } else {
      // it's wss
      return new Web3(new Web3.providers.WebsocketProvider(INFURA_ENDPOINT))
    }
  } else {
    return web3
  }
}

export default web3instance
