import { combineReducers } from 'redux'
import orderReducer from './orderReducer'
import walletReducer from './walletReducer'
import tradeReducer from './tradeReducer'
import blockchainReducer from './blockchainReducer'
import graphReducer from '../../shared-components/graph/redux/reducer'
import navigatorReducer from './navigatorReducer'
import txnReducer from './txnReducer'
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  navigator: navigatorReducer,
  order: orderReducer,
  wallet: walletReducer,
  trade: tradeReducer,
  blockchain: blockchainReducer,
  form: formReducer,
  graph: graphReducer,
  txn: txnReducer
})
