import React from 'react'
import { connect } from 'react-redux'
import connector from '../../connector'
import {
  buyHodlC,
  sellHodlC,
  getDexData,
  getUserBalances,
  depositEthReturnImmediately
} from '../../redux/actions'
import notify from '../../utils/notify'
import OrderBook from '../../components/OrderBook'
import { withTranslation } from 'react-i18next'
import WalletIcon from '../../components/WalletIcons'
import ConnectModal from '../../components/Profile/ConnectModal'
import DisabledBanner from '../../components/DisableBanner'
import BigNumber from 'bignumber.js'
import config from '../../config'
import networkConfig from '../../global-config'
import MarketInfo from '../../components/MarketInfo/MobileMarketInfo'

const menuLink = [
  {
    name: 'Global Orders',
    state: true,
    path: '/trade/global',
    key: 'global'
  },
  {
    name: 'My Orders',
    state: false,
    path: '/trade/myOrders',
    key: 'myOrders'
  }
]

class Trade extends React.Component {
  state = {
    HodlcAmount: 0,
    EthAmount: 0,
    show: true,
    curForm: 'Buy',
    ethUSD: 0,
    hodlcUSD: 0,
    minUSD: 50,
    maxUSD: 5000,
    showGlobal: true,
    mainUnit: 'ETH',
    subUnit: 'HODLC',
    oneHodlInUsd: 0,
    oneEthInUsd: 0,
    noEvents: false
  }

  constructor(props) {
    super(props)
    this.form = {
      Buy: {
        valueTarget: 'EthAmount',
        convertFunc: (value) => {
          if (value === 0) {
            return 0
          }

          return BigNumber(this.state.ethUSD)
            .times(value)
            .dividedBy(this.state.hodlcUSD)
            .toFixed(18)
        },
        subTarget: 'HodlcAmount'
      },
      Sell: {
        valueTarget: 'HodlcAmount',
        convertFunc: (value) => {
          if (value === 0) {
            return 0
          }
          return BigNumber(this.state.hodlcUSD)
            .times(value)
            .dividedBy(this.state.ethUSD)
            .toFixed(18)
        },
        subTarget: 'EthAmount'
      }
    }
  }

  async componentDidMount() {
    document.title = 'HODL DEX | Trade'
    const rate = await connector.getCurrentRate(this.props.web3)
    this.props.getUserBalances(this.props.web3, this.props.account)
    this.props.getDexData()
    this.setState({
      ethUSD: rate.eth,
      hodlcUSD: rate.hodlc,
      EthAmount: this.hodlcToEth(this.state.HodlcAmount)
    })
  }

  hodlcToEth = (hodlc) => {
    if (hodlc === 0) {
      return 0
    }
    return BigNumber(hodlc)
      .times(this.state.hodlcUSD)
      .dividedBy(this.state.ethUSD)
      .toString()
  }

  async setMaxMinValue(type) {
    const oneHodlInUsd = await connector.converters.convertHodlToUsd(
      this.props.web3,
      '1'
    )
    const oneEthInUsd = await connector.converters.convertEthToUsd(
      this.props.web3,
      '1'
    )
    const maxLimit = await connector.getOrderMaxLimit(this.props.web3)
    this.setState({ oneHodlInUsd, oneEthInUsd })
    const cur = this.state.curForm
    if (cur === 'Sell') {
      const limit = {
        min: BigNumber(50)
          .times(1 + config.marginRate)
          .dividedBy(oneHodlInUsd)
          .toFixed(18),
        max: BigNumber(maxLimit)
          .times(1 - config.marginRate)
          .dividedBy(oneHodlInUsd)
          .toFixed(18)
      }
      let value = limit[type]

      if (type === 'max') {
        if (
          BigNumber(value).isGreaterThan(this.props.hodlc) ||
          maxLimit === 0
        ) {
          value = BigNumber(this.props.hodlc).toFixed(18)
        }
      }
      this.setState({
        [this.form[cur].valueTarget]: value,
        [this.form[cur].subTarget]: this.form[cur].convertFunc(value)
      })
    } else if (cur === 'Buy') {
      const limit = {
        min: BigNumber(50)
          .times(1 + config.marginRate)
          .dividedBy(oneEthInUsd)
          .toFixed(18),
        max: BigNumber(maxLimit)
          .times(1 - config.marginRate)
          .dividedBy(oneEthInUsd)
          .toFixed(18)
      }
      let value = limit[type]
      if (type === 'max' && maxLimit === 0) {
        value = BigNumber(this.props.eth).toFixed(18)
      }
      this.setState({
        [this.form[cur].valueTarget]: value,
        [this.form[cur].subTarget]: this.form[cur].convertFunc(value)
      })
    }
  }

  onFormSubmit = async (e) => {
    e.preventDefault()
    if (!this.props.account) {
      return notify.error('Connect with your wallet first.')
    }
    if (
      BigNumber(this.state.HodlcAmount).isGreaterThan(0) ||
      BigNumber(this.state.EthAmount).isGreaterThan(0)
    ) {
      const cur = this.state.curForm
      const maxLimit = await connector.getOrderMaxLimit(this.props.web3)
      // Sell HODLC
      if (cur === 'Sell') {
        const oneHodlInUsd = await connector.converters.convertHodlToUsd(
          this.props.web3,
          '1'
        )
        const min = BigNumber(50)
          .times(1 + config.marginRate)
          .dividedBy(oneHodlInUsd)
          .toFixed(18)
        const max = BigNumber(maxLimit)
          .times(1 - config.marginRate)
          .dividedBy(oneHodlInUsd)
          .toFixed(18)

        if (BigNumber(min).isGreaterThan(this.state.HodlcAmount)) {
          return notify.error(
            this.props.t('trade.notify.sellMin', {
              min: BigNumber(min).toFixed(18)
            })
          )
        }

        if (
          BigNumber(this.state.HodlcAmount).isGreaterThan(this.props.hodlc) &&
          BigNumber(this.state.HodlcAmount).isGreaterThan(max) &&
          BigNumber(this.props.hodlc).isGreaterThan(max)
        ) {
          return notify.error(
            this.props.t('trade.notify.sellMax', { max: max })
          )
        }

        if (
          BigNumber(this.state.HodlcAmount).isGreaterThan(this.props.hodlc) &&
          BigNumber(this.state.HodlcAmount).isGreaterThan(max) &&
          BigNumber(this.props.hodlc).isLessThan(max)
        ) {
          return notify.error(
            this.props.t('trade.notify.sellMax', {
              max: this.props.hodlc.toFixed(18)
            })
          )
        }

        if (
          BigNumber(this.state.HodlcAmount).isGreaterThan(this.props.hodlc) &&
          BigNumber(this.state.HodlcAmount).isGreaterThan(max)
        ) {
          return notify.error(
            this.props.t('trade.notify.sellMax', {
              max: this.props.hodlc.toFixed(18)
            })
          )
        }

        if (
          (BigNumber(this.state.HodlcAmount).isLessThan(this.props.hodlc) ||
            BigNumber(this.state.HodlcAmount).isEqualTo(this.props.hodlc)) &&
          BigNumber(this.state.HodlcAmount).isGreaterThan(max)
        ) {
          return notify.error(
            this.props.t('trade.notify.sellMax', { max: max })
          )
        }

        if (
          BigNumber(this.state.HodlcAmount).isLessThan(this.props.hodlc) &&
          BigNumber(this.state.HodlcAmount).isLessThan(max) &&
          BigNumber(this.state.HodlcAmount).isLessThan(min)
        ) {
          return notify.error(
            this.props.t('trade.notify.sellMin', { min: min })
          )
        }

        this.props
          .sellHodlC(
            this.props.web3,
            BigNumber(this.state.HodlcAmount).toFixed(18)
          )
          .then((res) => {
            if (res) {
              notify.error(this.props.t('system.blockchain.failed'))
            } else {
              const system = this.props.t('system.blockchain.confirmed')
              notify.success(`${system}`)
            }
          })
        return notify.success(
          this.props.t('trade.notify.successSell', {
            cur: cur.toLowerCase(),
            hodlc: this.state.HodlcAmount
          })
        )
      } else if (cur === 'Buy') {
        // buy HODLC
        const oneEthInUsd = await connector.converters.convertEthToUsd(
          this.props.web3,
          '1'
        )
        const min = BigNumber(50)
          .times(1 + config.marginRate)
          .dividedBy(oneEthInUsd)
          .toFixed(18)
        const max = BigNumber(maxLimit)
          .times(1 - config.marginRate)
          .dividedBy(oneEthInUsd)
          .toFixed(18)

        if (BigNumber(min).isGreaterThan(this.state.EthAmount)) {
          return notify.error(this.props.t('trade.notify.buyMin', { min: min }))
        }

        if (BigNumber(this.state.EthAmount).isGreaterThan(max)) {
          return notify.error(this.props.t('trade.notify.buyMax', { max: max }))
        }
        const diff = BigNumber(this.state.EthAmount).minus(this.props.eth)

        // trigger one-button-buy
        if (diff.isGreaterThan(0)) {
          // const confirmation = window.confirm(`You don't have enough ether, do you want to deposit ${diff} ETH now to buy ${((this.state.EthAmount * this.state.ethUSD) / this.state.hodlcUSD).toFixed((6))} HODLC?`)
          const ethBalanceInWei = await this.props.web3.eth.getBalance(
            this.props.account
          )
          const limitEthBalanceInEther = this.props.web3.utils.fromWei(
            ethBalanceInWei,
            'ether'
          )
          if (diff.isGreaterThan(limitEthBalanceInEther)) {
            return notify.error(
              this.props.t('wallet.notify.depositeEthMax', {
                limit: limitEthBalanceInEther,
                type: 'ETH'
              })
            )
          }
          this.props
            .depositEthReturnImmediately(
              this.props.web3,
              diff.toFixed(18),
              BigNumber(this.state.EthAmount).toFixed(18),
              BigNumber(this.state.HodlcAmount).toFixed(18),
              this.props.t,
              this.props.top10OrderData
            )
            .then((res) => {
              if (res) {
                notify.error(this.props.t('system.blockchain.failed'))
              } else {
                const system = this.props.t('system.blockchain.confirmed')
                notify.success(`${system}`)
              }
            })
        } else {
          // pass in orderbook from redux
          this.props
            .buyHodlC(
              this.props.web3,
              BigNumber(this.state.EthAmount).toFixed(18),
              BigNumber(this.state.HodlcAmount).toFixed(18),
              this.props.top10OrderData
            )
            .then((res) => {
              if (res) {
                notify.error(this.props.t('system.blockchain.failed'))
              } else {
                const system = this.props.t('system.blockchain.confirmed')
                notify.success(`${system}`)
              }
            })
          return notify.success(
            this.props.t('trade.notify.successBuy', {
              hodlc: BigNumber(this.state.HodlcAmount).toFixed(4)
            })
          )
        }
      } else {
        this.setState({ show: false })
      }
    }
  }

  renderForm = (form, type) => {
    return (
      <form className="trade-form" onSubmit={this.onFormSubmit}>
        <div className={`ui fluid transparent input main-input ${type}`}>
          <div
            onClick={this.setMaxMinValue.bind(this, 'min')}
            className="max-min-btn"
          >
            {this.props.t('trade.minimumBtn')}
          </div>
          <div className="divider">/</div>
          <div
            onClick={this.setMaxMinValue.bind(this, 'max')}
            className="max-min-btn"
          >
            {this.props.t('trade.maximumBtn')}
          </div>
          <div className="max-min-btn-devider" />
          <input
            placeholder={this.props.t('trade.form.placeholder')}
            value={this.state[form[type].valueTarget]}
            onChange={(e) => {
              this.setState({
                [form[type].valueTarget]: e.target.value,
                [form[type].subTarget]: form[type].convertFunc(e.target.value)
              })
            }}
          />
          <div className="">{this.state.mainUnit}</div>
        </div>
        <div className="ui fluid transparent input sub-input">
          {this.props.t('trade.form.equivalent')}
          <small>({this.props.t('trade.form.estimate')})</small>
          <input
            value={
              this.state[form[type].subTarget] === 0
                ? '0'
                : this.state[form[type].subTarget]
            }
            readOnly
          />
          <div className="">{this.state.subUnit}</div>
        </div>
        <div>
          <button
            className={`ui ${type} fluid button trade-action-button`}
            type="submit"
            disabled={!networkConfig.enableTrade}
          >
            {type.toLowerCase() === 'buy' &&
              BigNumber(this.state.EthAmount).isGreaterThan(this.props.eth) && (
                <span>
                  {this.props.t('trade.form.action.deposit')}
                  <span> & </span>
                </span>
              )}
            <span>
              {this.props.t(`trade.form.action.${type.toLowerCase()}`)}
            </span>
          </button>
        </div>
      </form>
    )
  }

  renderMenu = () => {
    return menuLink.map((ele, id) => {
      let className = 'item'
      if (this.state.showGlobal && ele.key === 'global') {
        className = 'item active'
      } else if (!this.state.showGlobal && ele.key === 'myOrders') {
        className = 'item active'
      }
      if (ele.key.includes('my') && !this.props.account) return <></>
      return (
        <div
          className={className}
          onClick={() => this.setState({ showGlobal: ele.state })}
          key={id}
        >
          {this.props.t(`trade.orderBook.${ele.key}`)}
        </div>
      )
    })
  }

  hideWalletModal = () => {
    this.setState({ showWalletModal: false })
  }

  showWalletModal = () => {
    this.setState({ showWalletModal: true })
  }

  connectWithWallet = async () => {
    this.showWalletModal()
  }

  renderOrders = () => {
    if (this.state.noEvents) {
      return (
        <div className="order-section" style={{ 'text-align': 'center' }}>
          No data
        </div>
      )
    }
    if (this.props.rates) {
      if (this.state.showGlobal && this.props.match.path === '/trade') {
        return (
          <div className="order-section">
            <OrderBook type="sell" />
            <OrderBook type="buy" />
          </div>
        )
      } else if (!this.state.showGlobal && this.props.account.length) {
        return (
          <div className="order-section">
            <OrderBook type="userSell" address={this.props.account} />
            <OrderBook type="userBuy" address={this.props.account} />
          </div>
        )
      }
    } else {
      return (
        <div className="order-section">
          <div className="no-event">
            <div className="order-section">
              <div>
                <div className="loading-component">
                  <div className="sk-chase">
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                  </div>
                </div>
                <div className="progress-indicator">Initializing</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  handleClick = () => {
    const { web3 } = this.props
    connector.withDrawFromOldDex(web3)
  }

  render() {
    const { t } = this.props
    return (
      <div className="trade-container">
        <div className="container">
          <MarketInfo
            reserveBalanceHodlc={this.props.reserveBalanceHodlc}
            rates={this.props.rates}
          />
          {!networkConfig.enableTrade && <DisabledBanner />}
          <div className="trade-info-container">
            {this.props.account && this.props.oldEthBalance > 0 ? (
              <div className="upgrade">
                <div className="title">
                  You still have {this.props.oldEthBalance} ETH in the old Dex.
                </div>
                <button
                  className="btn upgrade-btn"
                  onClick={() => this.handleClick()}
                >
                  Withdraw
                </button>
              </div>
            ) : (
              <>
                <div className={`trade-info-blocker ${!this.props.account}`}>
                  <button
                    className="ui button"
                    onClick={this.connectWithWallet}
                  >
                    {t('trade.promptToConnect.connect')}
                    <WalletIcon />
                  </button>
                </div>
                <div className="trade-card trade-action">
                  <div className="btn-group">
                    <button
                      className={`ui fluid button left ${
                        this.state.curForm === 'Buy'
                      }`}
                      onClick={() =>
                        this.setState({
                          show: true,
                          curForm: 'Buy',
                          mainUnit: 'ETH',
                          subUnit: 'HODLC',
                          EthAmount: 0,
                          HodlcAmount: 0
                        })
                      }
                    >
                      {t('trade.switcher.buy')}
                    </button>
                    <button
                      className={`ui fluid button right ${
                        this.state.curForm === 'Sell'
                      }`}
                      onClick={() =>
                        this.setState({
                          show: true,
                          curForm: 'Sell',
                          mainUnit: 'HODLC',
                          subUnit: 'ETH',
                          HodlcAmount: 0,
                          EthAmount: 0
                        })
                      }
                    >
                      {t('trade.switcher.sell')}
                    </button>
                  </div>
                  {this.state.show &&
                    this.renderForm(this.form, this.state.curForm)}
                </div>
                <div className="trade-card trade-info">
                  <div className="ui header info-header">
                    {t('trade.info.balances')}
                  </div>
                  <div className="info">
                    <div className="title">HODLC</div>
                    <span>{BigNumber(this.props.hodlc).toFixed(18)}</span>
                  </div>
                  <div className="info">
                    <div className="title">ETH</div>
                    <span>{BigNumber(this.props.eth).toFixed(18)}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="order-book order-switcher">
            <div className="ui secondary menu">{this.renderMenu()}</div>
            <div className="orders-container">{this.renderOrders()}</div>
          </div>
        </div>
        {this.state.showWalletModal && (
          <ConnectModal hideModal={this.hideWalletModal} />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ wallet, trade, blockchain }) => {
  const hodlc =
    wallet.hodlc < 0.0000000001
      ? Math.round(wallet.hodlc).toFixed(18)
      : wallet.hodlc
  return {
    eth: wallet.eth,
    hodlc: hodlc,
    hodlt: wallet.hodlt,
    contractBalanceEth: trade.contractBalanceEth,
    reserveBalanceHodlc: trade.reserveBalanceHodlc,
    rates: trade.rates,
    account: blockchain.account,
    web3: blockchain.web3,
    oldEthBalance: wallet.oldEthBalance,
    top10OrderData: trade.top10OrderData
  }
}

export default connect(mapStateToProps, {
  getUserBalances,
  buyHodlC,
  sellHodlC,
  getDexData,
  depositEthReturnImmediately
})(withTranslation()(Trade))
