import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  connectWithMetaMask,
  connectWithWalletConnect,
  disconnectWithWalletConnect,
  disconnectWithWalletLink,
  connectWithWalletLink,
  disconnectWithFortmatic,
  connectWithFortmatic,
  disconnectWithPortis,
  connectWithPortis
} from '../../redux/actions'

class ConnectModal extends React.Component {
  walletConnectOnClick = (blockchain) => {
    console.log('walletConnectOnClick')
    if (blockchain.walletConnect.connected) {
      const yes = window.confirm('Disconnect with walletConnect?')
      if (yes) {
        this.props.disconnectWithWalletConnect()
      }
    } else {
      this.props.connectWithWalletConnect(blockchain)
    }
  }
  walletLinkOnClick = (blockchain) => {
    console.log('walletLinkOnClick')
    if (blockchain.walletLink.connected) {
      const yes = window.confirm('Disconnect with walletLink?')
      if (yes) {
        this.props.disconnectWithWalletLink()
      }
    } else {
      this.props.connectWithWalletLink(blockchain)
    }
  }

  fortmaticOnClick = (blockchain) => {
    console.log('fortmaticOnClick')
    if (blockchain.fortmatic.connected) {
      const yes = window.confirm('Disconnect with Fortmatic?')
      if (yes) {
        this.props.disconnectWithFortmatic()
      }
    } else {
      this.props.connectWithFortmatic(blockchain)
    }
  }

  portisOnClick = (blockchain) => {
    console.log('portisOnClick')
    if (blockchain.portis.connected) {
      const yes = window.confirm('Disconnect with Portis?')
      if (yes) {
        this.props.disconnectWithPortis()
      }
    } else {
      this.props.connectWithPortis(blockchain)
    }
  }

  render() {
    const { t } = this.props
    return ReactDOM.createPortal(
      <div
        className="ui dimmer modals visible active"
        onClick={() => this.props.hideModal()}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="ui small modal visible active main-modal connect-modal"
        >
          <div className="ui top attached big label modal-title">
            {t('wallet-connect.choose')}
          </div>
          <div className="modal-body">
            <div className="connect-group">
              <div
                className={`item ${
                  this.props.blockchain.metamask.connected &&
                  !this.props.blockchain.walletConnect.connected
                }`}
                onClick={() =>
                  this.props.connectWithMetaMask(this.props.blockchain)
                }
              >
                <div className="left-item-container">
                  <div className="connected" />
                  <div className="title">MetaMask</div>
                </div>
                <img
                  alt="metamask"
                  className="metamask-icon"
                  src={require('../../assets/images/metamask.svg')}
                  width="25px"
                />
              </div>
              <div
                className={`item ${this.props.blockchain.walletConnect.connected}`}
                onClick={() => this.walletConnectOnClick(this.props.blockchain)}
              >
                <div className="left-item-container">
                  <div className="connected" />
                  <div className="title">WalletConnect</div>
                </div>
                <img
                  alt="walletConnect"
                  className="walletConnect-icon"
                  src={require('../../assets/images/walletConnect.svg')}
                  width="25px"
                />
              </div>
              <div
                className={`item ${this.props.blockchain.walletLink.connected}`}
                onClick={() => this.walletLinkOnClick(this.props.blockchain)}
              >
                <div className="left-item-container">
                  <div className="connected" />
                  <div className="title">Coinbase Wallet</div>
                </div>
                <img
                  alt="walletLink"
                  className="walletLink-icon"
                  src={require('../../assets/images/walletLink.svg')}
                  width="25px"
                />
              </div>
              <div
                className={`item ${this.props.blockchain.fortmatic.connected}`}
                onClick={() => this.fortmaticOnClick(this.props.blockchain)}
              >
                <div className="left-item-container">
                  <div className="connected" />
                  <div className="title">Fortmatic</div>
                </div>
                <img
                  alt="fortmatic"
                  className="fortmatic-icon"
                  src={require('../../assets/images/fortmatic.png')}
                  width="25px"
                />
              </div>
              {/*<div*/}
              {/*  className={`item ${this.props.blockchain.portis.connected}`}*/}
              {/*  onClick={() => this.portisOnClick(this.props.blockchain)}*/}
              {/*>*/}
              {/*  <div className="left-item-container">*/}
              {/*    <div className="connected" />*/}
              {/*    <div className="title">Portis</div>*/}
              {/*  </div>*/}
              {/*  <img*/}
              {/*    alt="portis"*/}
              {/*    className="portis-icon"*/}
              {/*    src={require('../../assets/images/portis.png')}*/}
              {/*    width="25px"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  }
}

const mapStateToProps = ({ blockchain }) => {
  return {
    blockchain
  }
}

export default connect(mapStateToProps, {
  connectWithMetaMask,
  connectWithWalletConnect,
  disconnectWithWalletConnect,
  disconnectWithWalletLink,
  connectWithWalletLink,
  disconnectWithFortmatic,
  connectWithFortmatic,
  disconnectWithPortis,
  connectWithPortis
})(withTranslation()(ConnectModal))
