import React, { useState, useEffect } from 'react'
import globalConfig from '../../global-config'
const DexSeletor = () => {
  const [dex, setDex] = useState('eth')

  useEffect(() => {
    if (dex === 'eos') {
      const hodlDexInfraInfo = JSON.parse(
        localStorage.getItem('hodlDexInfraInfo')
      ) || {
        blockchain: 'eth',
        version: 'v2',
        network: process.env.REACT_APP_DEFAULT_NETWORK
      }
      if (hodlDexInfraInfo.network === 'main') {
        window.location.href = globalConfig.eosLink[hodlDexInfraInfo.network]
      } else {
        window.location.href = globalConfig.eosLink.test
      }
    }
  }, [dex])

  return (
    <select
      value={dex}
      onChange={(e) => setDex(e.target.value)}
      className="dex-selection"
    >
      <option value="eos">EOS</option>
      <option value="eth">ETH</option>
    </select>
  )
}

export default DexSeletor
