import React from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import { withTranslation } from 'react-i18next'

import config from '../../config'
import OrderBook from '../../connector/orderBook'
import List, { labels } from './list'
import { getOrderBookData } from '../../redux/actions'
import web3instance from '../../connector/web3'
import contracts from '../../connector/config'

const unlimitedItems = 10000
const lastUpdatedIndicatorIntervalInSeconds = 30 // in seconds
const INIT_STATE = {
  book: [],
  total: 0,
  type: '',
  limit: 10, // limit per page
  disabled: false, // when loading, disable prev/next buttons
  page: 1, // page counter
  cachedBook: [],
  lastUpdated: 0
}

const USER_INIT_STATE = {
  book: [],
  total: 0,
  type: '',
  limit: unlimitedItems,
  disabled: false,
  page: 1,
  cachedBook: []
}

let book
class Book extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.address ? USER_INIT_STATE : INIT_STATE
  }

  componentDidMount() {
    this.fetchOrderBook(this.props.type, this.state.limit)
    this.lastUpdatedInterval = setInterval(() => {
      const lastLastUpdated = this.state.lastUpdated
      this.setState({
        lastUpdated:
          (lastLastUpdated + 1) % lastUpdatedIndicatorIntervalInSeconds
      })
    }, 1000)
  }

  fetchAgain = () => {
    const web3 = web3instance(this.props.web3)
    const instance = contracts.HodlDex(web3)
    const { type } = this.props
    if (instance && instance.methods[`${type}OrderCount`]) {
      instance.methods[`${type}OrderCount`]()
        .call()
        .then((total) => this.setState({ total }))
        .catch(console.log)
    }

    const leaderIndex =
      this.state.page === 1 ? 0 : (this.state.page - 1) * this.state.limit - 1
    if (this.state.cachedBook[leaderIndex]) {
      this.fetchOrderBook(
        this.props.type,
        this.state.limit,
        this.state.cachedBook[leaderIndex].orderId,
        false
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.myinterval)
    clearInterval(this.lastUpdatedInterval)
  }

  removeOrderFromBookAfterCanceling = (id) => {
    const newCachedBook = this.state.cachedBook.filter(
      (item) => item.orderId !== id
    )
    const newBook = this.state.book.filter((item) => item.orderId !== id)
    this.setState({
      cachedBook: newCachedBook,
      book: newBook
    })
  }

  reset = () => {
    this.setState(INIT_STATE)
    this.fetchOrderBook(this.props.type, INIT_STATE.limit)
  }

  fetchOrderBook = (
    type = 'sell',
    limit,
    leadOrderId,
    loadOnDemand = true,
    action
  ) => {
    clearInterval(this.myinterval)
    let localBook = []
    limit = Number(limit)
    this.disablePagination()
    book = new OrderBook(this.props.web3)
    book.removeAllListeners()
    book.on('init', (payload) => {
      if (payload === '0') this.enablePagination()
      if (action === 'lastPage') {
        this.setState({ cachedBook: INIT_STATE.cachedBook })
      }
      this.setState({ total: payload })
    })
    book.on('firstOrder', (payload) => {
      const temp = this.state.cachedBook.slice(0)
      const accumulatedPage = (this.state.page - 1) * this.state.limit
      temp[payload.count + accumulatedPage] = payload
      if (loadOnDemand) {
        this.setState({
          book: [payload],
          cachedBook: temp
        })
      } else if (action === 'lastPage') {
        this.setState({
          book: [],
          cachedBook: [payload]
        })
      } else {
        localBook = [payload]
        this.setState({
          cachedBook: temp
        })
      }
    })
    book.on('newOrder', (payload) => {
      const temp = this.state.cachedBook.slice(0)
      const accumulatedPage = (this.state.page - 1) * this.state.limit
      temp[payload.count + accumulatedPage] = payload
      if (loadOnDemand) {
        this.setState({
          book: [...this.state.book, payload],
          cachedBook: temp
        })
      } else if (action === 'lastPage') {
        localBook = [...localBook, payload]
        this.setState({
          cachedBook: [...this.state.cachedBook, payload]
        })
      } else {
        localBook = [...localBook, payload]
        this.setState({
          cachedBook: temp
        })
      }
    })
    book.on('finishAndReturnNextLeadOrderId', (id) => {
      this.props.getOrderBookData(this.state.cachedBook.slice(0, 10))
      this.enablePagination()
      const temp = this.state.cachedBook.slice(0)
      temp[this.state.page * this.state.limit] = { orderId: id }
      this.setState({
        cachedBook: temp
      })
      if (!loadOnDemand) {
        if (action === 'lastPage') {
          this.setState({
            book: this.state.cachedBook.slice(
              this.state.cachedBook.length - this.state.limit
            )
          })
        } else {
          this.setState({
            book: localBook
          })
        }
      }
    })
    book.on('end', () => {
      this.enablePagination()
      this.props.getOrderBookData(this.state.cachedBook.slice(0, 10))
      if (!loadOnDemand) {
        const data = this.state.cachedBook.slice(
          this.state.cachedBook.length - this.state.limit
        )
        if (action === 'lastPage') {
          this.setState({
            book: data
          })
        } else {
          this.setState({
            book: localBook
          })
        }
      }
    })
    book.subscribe(type, limit, leadOrderId)
    this.myinterval = setInterval(() => {
      this.fetchAgain()
    }, config.rqstInteval.orderBook)
  }

  enablePagination() {
    this.setState({
      disabled: false
    })
  }

  disablePagination() {
    this.setState({
      disabled: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.limit < prevState.book.length) return
    if (prevProps.type !== this.props.type && this.props.address) {
      const { limit, leadOrderId } = USER_INIT_STATE
      book.removeAllListeners()
      return this.fetchOrderBook(this.props.type, limit, leadOrderId)
    }
    if (prevProps.type !== this.props.type && prevProps.address) {
      this.reset()
    }
  }

  changeLimit(limit) {
    limit = Number(limit)
    book.removeAllListeners()
    this.setState({
      ...INIT_STATE,
      limit,
      page: 1
    })
    this.fetchOrderBook(this.props.type, limit)
  }

  prevPage() {
    this.disablePagination()
    const prevPageNum = this.state.page - 1
    const limit = this.state.limit
    const leadOrderIndex = (prevPageNum - 1) * limit
    const newBook = this.state.cachedBook
      .slice(0)
      .slice(leadOrderIndex, leadOrderIndex + limit)
    this.setState({
      page: prevPageNum,
      book: newBook,
      disabled: false
    })
  }

  nextPage() {
    this.disablePagination()
    const nextPageNum = this.state.page + 1
    this.setState({
      book: [],
      page: nextPageNum
    })
    const fromIndex = this.state.page * this.state.limit
    const toIndex = (this.state.page + 1) * this.state.limit
    const checkCachedBook = this.state.cachedBook
      .slice(0)
      .slice(fromIndex, toIndex)
    if (checkCachedBook.length === this.state.limit) {
      this.setState({
        book: checkCachedBook,
        disabled: false
      })
    } else {
      this.fetchOrderBook(
        this.props.type,
        this.state.limit,
        this.state.cachedBook[(nextPageNum - 1) * this.state.limit].orderId
      )
    }
  }

  lastPage() {
    this.disablePagination()
    this.setState({
      book: [],
      page: Math.ceil(this.state.total / this.state.limit)
    })
    const fromIndex = this.state.page * this.state.limit
    const toIndex = (this.state.page + 1) * this.state.limit
    const checkCachedBook = this.state.cachedBook
      .slice(0)
      .slice(fromIndex, toIndex)
    if (checkCachedBook.length === this.state.limit) {
      this.setState({
        book: checkCachedBook,
        disabled: false
      })
    } else {
      this.fetchOrderBook(
        this.props.type,
        unlimitedItems,
        undefined,
        false,
        'lastPage'
      )
    }
  }

  firstPage() {
    this.setState({
      page: 1
    })
    const fromIndex = 0
    const toIndex = this.state.limit
    const checkCachedBook = this.state.cachedBook
      .slice(0)
      .slice(fromIndex, toIndex)
    if (checkCachedBook.length === this.state.limit) {
      this.setState({
        book: checkCachedBook,
        disabled: false
      })
    } else {
      this.fetchOrderBook(this.props.type, this.state.limit)
    }
  }

  renderCount = (amount) => {
    if (BigNumber(amount).isEqualTo(1) || BigNumber(amount).isEqualTo(0)) {
      return this.props.t('trade.orderBook.singleOrder', {
        amount,
        units: labels[this.props.type]
      })
    } else {
      return this.props.t('trade.orderBook.multiOrders', {
        amount,
        units: labels[this.props.type]
      })
    }
  }

  noEvents = () => {
    const length = this.state.cachedBook.length || 0
    const total = this.state.total || 1
    const progress = length / total
    const { address } = this.props
    if (address) {
      return <div className="content">No orders found</div>
    } else if (this.state.disabled) {
      return (
        <div>
          <div className="loading-component">
            <div className="sk-chase">
              <div className="sk-chase-dot" />
              <div className="sk-chase-dot" />
              <div className="sk-chase-dot" />
              <div className="sk-chase-dot" />
              <div className="sk-chase-dot" />
              <div className="sk-chase-dot" />
            </div>
          </div>
          <div className="progress-indicator">
            loading {`${Math.floor(progress * 100)}%`}
          </div>
          <div className="progress-indicator">
            <small>pulling data from smart contract</small>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  render() {
    const { address } = this.props
    const orders = address
      ? this.state.book.filter((b) => b[0] === address)
      : this.state.book
    const hasItems = orders.length > 0
    if (hasItems) {
      const accumulatedPage = (this.state.page - 1) * this.state.limit
      return (
        <div className="book">
          <div className="info-container">
            {!this.props.address && (
              <>
                <div className="order-count">
                  {this.renderCount(this.state.total)}
                  <span className="last-updated-indicator">
                    <img
                      className={`spin-${this.state.lastUpdated}`}
                      alt="lastUpdatedSpinner"
                      src={require('../../assets/images/lastUpdatedSpinner.svg')}
                    />
                    <span className="text">
                      Last updated {this.state.lastUpdated} secs ago
                    </span>
                  </span>
                </div>
                <div className="limit-selector">
                  <select
                    disabled={this.state.disabled}
                    value={this.state.limit}
                    onChange={(e) => this.changeLimit(e.target.value)}
                  >
                    {/*<option value={2}>2 orders</option>*/}
                    <option value={10}>10 orders</option>
                    <option value={15}>15 orders</option>
                    <option value={20}>20 orders</option>
                    <option value={50}>50 orders</option>
                    <option value={unlimitedItems}>ALL orders</option>
                  </select>
                  <div className="button-group-container">
                    <button
                      className={`disabled-${
                        this.state.disabled || this.state.page === 1
                      }`}
                      disabled={this.state.disabled || this.state.page === 1}
                      onClick={() => this.firstPage()}
                    >
                      <span>first</span>
                    </button>
                    <button
                      className={`prev disabled-${
                        this.state.disabled || this.state.page === 1
                      }`}
                      disabled={this.state.disabled || this.state.page === 1}
                      onClick={() => this.prevPage()}
                    >
                      <span>&#9650;</span>
                    </button>
                    {this.state.book[0] && (
                      <div>
                        {!this.state.disabled && (
                          <span className="pagination-indicator">
                            {this.state.page}/
                            {Math.ceil(this.state.total / this.state.limit)}
                          </span>
                        )}
                        {this.state.disabled && (
                          <span className="pagination-indicator">...</span>
                        )}
                      </div>
                    )}
                    <button
                      className={`next disabled-${
                        this.state.disabled ||
                        this.state.book.length < this.state.limit ||
                        this.state.page ===
                          Math.ceil(this.state.total / this.state.limit)
                      }`}
                      disabled={
                        this.state.disabled ||
                        this.state.book.length < this.state.limit ||
                        this.state.page ===
                          Math.ceil(this.state.total / this.state.limit)
                      }
                      onClick={() => this.nextPage()}
                    >
                      <span>&#9650;</span>
                    </button>
                    <button
                      className={`disabled-${
                        this.state.disabled ||
                        this.state.book.length < this.state.limit ||
                        this.state.page ===
                          Math.ceil(this.state.total / this.state.limit)
                      }`}
                      disabled={
                        this.state.disabled ||
                        this.state.book.length < this.state.limit ||
                        this.state.page ===
                          Math.ceil(this.state.total / this.state.limit)
                      }
                      onClick={() => this.lastPage()}
                    >
                      <span>last</span>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <List
            accumulatedPage={accumulatedPage}
            type={this.props.type}
            removeOrderFromBookAfterCanceling={
              this.removeOrderFromBookAfterCanceling
            }
            orders={orders}
          />
        </div>
      )
    } else if (this.props.type === 'sell' && !hasItems) {
      return <Book type="buy" />
    } else {
      return <div className="no-event">{this.noEvents()}</div>
    }
  }
}

const mapStateToProps = ({ blockchain, trade }) => {
  return {
    web3: blockchain.web3,
    rates: trade.rates
  }
}

export default connect(mapStateToProps, { getOrderBookData })(
  withTranslation()(Book)
)
