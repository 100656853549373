import { DEPOSIT_ETH } from '../types'

const INITIAL_STATE = {
  balanceEth: 0,
  balanceHodl: 0,
  balanceHodlt: 0,
  status: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEPOSIT_ETH:
      return { ...state, status: action.payload }
    default:
      return state
  }
}
