const options = {
  fallbackLng: 'en',
  debug: process.env.REACT_APP_APP_ENV !== 'prod',
  backend: {
    crossDomain: true,
    loadPath: 'locales/{{lng}}.json'
    // process.env.REACT_APP_APP_ENV !== "prod"
    //   ? `locales/{{lng}}.json`
    //   : `https://raw.githubusercontent.com/amazyyy/hodldex.io-i18n/master/{{lng}}.json`
  },
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
}

export default options
