import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'
import config from '../../connector/config'
import globalConfig from '../../global-config'
import Fortmatic from 'fortmatic'
// import Portis from '@portis/web3'

const METAMASK = 'metamask-compatible'
const WALLETCONNECT = 'wallet-connect'
const WALLETLINK = 'wallet-link'
const FORTMATIC = 'fortmatic-connect'
const PORTIS = 'portis-connect'
const INFURAKEY = globalConfig.INFURA_KEY
const FORTMATICKEY = globalConfig.FORTMATIC_KEY
// const PORTISKEY = globalConfig.PORTIS_KEY

if (localStorage.getItem('refreshBlockchainEnvironmentAgain') === 'true') {
  console.log('refreshBlockchainEnvironmentAgain is true')
}

const logger = {
  log: (msg) => console.log('[ethereum-auth]', msg),
  warn: (msg) => console.warn('[ethereum-auth]', msg),
  error: (msg) => console.error('[ethereum-auth]', msg)
}

const connectWithMetaMask = async (blockchain) => {
  if (!window.ethereum) return window.alert('Metamask wallet is not installed!')
  // prompt user to login metamask
  try {
    const provider = window.ethereum
    const accounts = await provider.request({
      method: 'eth_requestAccounts'
    })
    const connected = accounts.length > 0
    if (connected) {
      logger.log('connected with METAMASK')
      localStorage.setItem('currentBlockchainWallet', METAMASK)
      // disconnect with truswallet
      if (blockchain.walletConnect.connected) {
        await disconnectWithWalletConnect()
      } else if (blockchain.walletLink.connected) {
        blockchain.walletLink.connected = false
        await disconnectWithWalletLink()
      } else {
        // update environment
        refreshBlockchainEnvironment()
      }
    }
  } catch (e) {
    logger.error(e)
  }
}

const disconnectWithWalletConnect = async () => {
  localStorage.removeItem('currentBlockchainWallet')
  const provider = new WalletConnectProvider({ infuraId: INFURAKEY })
  await provider.close()
  refreshBlockchainEnvironment()
}

const disconnectWithWalletLink = async () => {
  localStorage.removeItem('currentBlockchainWallet')
  const walletLink = new WalletLink({
    appName: 'HODL Dex',
    appLogoUrl: 'https://svgshare.com/i/TpS.svg',
    darkMode: false
  })
  const CHAIN_ID = 1
  const provider = walletLink.makeWeb3Provider(INFURAKEY, CHAIN_ID)
  await provider.close()
  refreshBlockchainEnvironment()
}

const disconnectWithFortmatic = async () => {
  localStorage.removeItem('currentBlockchainWallet')
  const fm = new Fortmatic(FORTMATICKEY)
  fm.user.logout()
  // NOTE: https://docs.fortmatic.com/fortmatic-native/log-out
  refreshBlockchainEnvironment()
}

// const disconnectWithPortis = async () => {
//   localStorage.removeItem('currentBlockchainWallet')
//   const network =
//     process.env.REACT_APP_APP_ENV === 'prod' ? 'mainnet' : 'goerli'
//   // const portis = new Portis(PORTISKEY, network)
//   // portis.logout()
//   // NOTE: https://docs.portis.io/#/methods
//   refreshBlockchainEnvironment()
// }

const refreshBlockchainEnvironment = () => {
  setTimeout(() => {
    window.location.reload()
  }, 100)
}

const initBlockchainEnvironment = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      logger.log('initBlcochainEnvironment')
      window.web3 = null
      const blockchain = {
        account: '',
        balance: 0,
        network: '',
        metamask: {
          installed: false,
          connected: false,
          web3: null
        },
        walletConnect: {
          connected: false,
          web3: null
        },
        walletLink: {
          connected: false,
          web3: null
        },
        fortmatic: {
          connected: false,
          web3: null
        },
        portis: {
          connected: false,
          web3: null
        },
        web3: null
      }
      if (window.ethereum) {
        blockchain.metamask.installed = true
        const provider = window.ethereum
        const localWeb3 = new Web3(provider)
        const accounts = await localWeb3.eth.getAccounts()
        if (accounts.length > 0) {
          blockchain.account = accounts[0]
          const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
          blockchain.balance = localWeb3.utils.fromWei(ethBalanceInWei, 'ether')
          blockchain.metamask.web3 = localWeb3
          if (localStorage.getItem('currentBlockchainWallet') === METAMASK) {
            blockchain.web3 = localWeb3
            // Subscribe to accounts change
            provider.on('accountsChanged', () => refreshBlockchainEnvironment())
            // Subscribe to chainId change
            provider.on('chainChanged', () => refreshBlockchainEnvironment())
            // Subscribe to session connection/open
            provider.on('open', () => console.log('open'))
            // Subscribe to session disconnection/close
            provider.on('close', (code, reason) => {
              localStorage.removeItem('currentBlockchainWallet')
              refreshBlockchainEnvironment()
            })
          }
        } else {
          if (localStorage.getItem('currentBlockchainWallet') === METAMASK) {
            localStorage.removeItem('currentBlockchainWallet')
          }
        }
        blockchain.metamask.connected = accounts.length > 0
      }
      if (localStorage.getItem('currentBlockchainWallet') === WALLETCONNECT) {
        try {
          const provider = new WalletConnectProvider({
            infuraId: INFURAKEY // Required
          })
          provider
            .enable()
            .then(() => {})
            .catch(() => {
              localStorage.removeItem('currentBlockchainWallet')
              refreshBlockchainEnvironment()
            })

          // Subscribe to accounts change
          provider.on('accountsChanged', () => refreshBlockchainEnvironment())
          // Subscribe to chainId change
          provider.on('chainChanged', () => {
            if (process.env.REACT_APP_APP_ENV === 'prod') return
            refreshBlockchainEnvironment()
          })
          // Subscribe to session connection/open
          provider.on('open', () => console.log('open'))
          // Subscribe to session disconnection/close
          provider.on('close', (code, reason) => {
            localStorage.removeItem('currentBlockchainWallet')
            refreshBlockchainEnvironment()
          })

          const localWeb3 = new Web3(provider)
          // inject web3 if the currentWallet is WALLETCONNECT
          const accounts = await localWeb3.eth.getAccounts()
          if (accounts.length > 0) {
            blockchain.walletConnect.connected = true
            blockchain.walletLink.connected = false
            blockchain.metamask.connected = false
            blockchain.fortmatic.connected = false
            blockchain.portis.connected = false
            blockchain.account = accounts[0]
            const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
            blockchain.balance = localWeb3.utils.fromWei(
              ethBalanceInWei,
              'ether'
            )
            blockchain.walletConnect.web3 = localWeb3
            blockchain.web3 = localWeb3
            localStorage.setItem('currentBlockchainWallet', WALLETCONNECT)
          } else {
            localStorage.removeItem('currentBlockchainWallet')
          }
        } catch (e) {
          console.error(e)
          // localStorage.removeItem('currentBlockchainWallet')
          // refreshBlockchainEnvironment()
        }
      }
      if (localStorage.getItem('currentBlockchainWallet') === WALLETLINK) {
        try {
          const walletLink = new WalletLink({
            appName: 'HODL Dex',
            appLogoUrl: 'https://svgshare.com/i/TpS.svg',
            darkMode: false
          })
          const CHAIN_ID = 1
          const provider = walletLink.makeWeb3Provider(INFURAKEY, CHAIN_ID)
          provider
            .enable()
            .then(() => {})
            .catch(() => {
              localStorage.removeItem('currentBlockchainWallet')
              refreshBlockchainEnvironment()
            })
          const localWeb3 = new Web3(provider)
          // inject web3 if the currentWallet is WALLETLINK
          const accounts = await localWeb3.eth.getAccounts()
          if (accounts.length > 0) {
            blockchain.walletLink.connected = true
            blockchain.metamask.connected = false
            blockchain.walletConnect.connected = false
            blockchain.fortmatic.connected = false
            blockchain.portis.connected = false
            blockchain.account = accounts[0]
            const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
            blockchain.balance = localWeb3.utils.fromWei(
              ethBalanceInWei,
              'ether'
            )
            blockchain.walletLink.web3 = localWeb3
            blockchain.web3 = localWeb3
            localStorage.setItem('currentBlockchainWallet', WALLETLINK)
          } else {
            localStorage.removeItem('currentBlockchainWallet')
          }
        } catch (e) {
          console.error(e)
          // localStorage.removeItem('currentBlockchainWallet')
          // refreshBlockchainEnvironment()
        }
      }
      if (localStorage.getItem('currentBlockchainWallet') === FORTMATIC) {
        try {
          const fm = new Fortmatic(FORTMATICKEY)
          const provider = fm.getProvider()
          provider
            .enable()
            .then(() => {})
            .catch(() => {
              localStorage.removeItem('currentBlockchainWallet')
              refreshBlockchainEnvironment()
            })
          const localWeb3 = new Web3(provider)
          // inject web3 if the currentWallet is WALLETLINK
          const accounts = await localWeb3.eth.getAccounts()
          if (accounts.length > 0) {
            blockchain.walletLink.connected = false
            blockchain.metamask.connected = false
            blockchain.walletConnect.connected = false
            blockchain.fortmatic.connected = true
            blockchain.portis.connected = false
            blockchain.account = accounts[0]
            const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
            blockchain.balance = localWeb3.utils.fromWei(
              ethBalanceInWei,
              'ether'
            )
            blockchain.walletLink.web3 = localWeb3
            blockchain.web3 = localWeb3
            localStorage.setItem('currentBlockchainWallet', FORTMATIC)
          } else {
            localStorage.removeItem('currentBlockchainWallet')
          }
        } catch (e) {
          console.error(e)
          // localStorage.removeItem('currentBlockchainWallet')
          // refreshBlockchainEnvironment()
        }
      }
      if (localStorage.getItem('currentBlockchainWallet') === PORTIS) {
        try {
          // const network =
          //   process.env.REACT_APP_APP_ENV === 'prod' ? 'mainnet' : 'goerli'
          // const portis = new Portis(PORTISKEY, network)
          // const provider = portis.provider
          // provider
          //   .enable()
          //   .then(() => {})
          //   .catch(() => {
          //     localStorage.removeItem('currentBlockchainWallet')
          //     refreshBlockchainEnvironment()
          //   })
          // const localWeb3 = new Web3(provider)
          // // inject web3 if the currentWallet is WALLETLINK
          // const accounts = await localWeb3.eth.getAccounts()
          // if (accounts.length > 0) {
          //   blockchain.walletLink.connected = false
          //   blockchain.metamask.connected = false
          //   blockchain.walletConnect.connected = false
          //   blockchain.fortmatic.connected = false
          //   blockchain.portis.connected = true
          //   blockchain.account = accounts[0]
          //   const ethBalanceInWei = await localWeb3.eth.getBalance(accounts[0])
          //   blockchain.balance = localWeb3.utils.fromWei(
          //     ethBalanceInWei,
          //     'ether'
          //   )
          //   blockchain.walletLink.web3 = localWeb3
          //   blockchain.web3 = localWeb3
          //   localStorage.setItem('currentBlockchainWallet', PORTIS)
          // } else {
          //   localStorage.removeItem('currentBlockchainWallet')
          // }
        } catch (e) {
          console.error(e)
          // localStorage.removeItem('currentBlockchainWallet')
          // refreshBlockchainEnvironment()
        }
      }
      if (blockchain.metamask.connected) {
        blockchain.web3 = blockchain.metamask.web3
      }
      if (blockchain.walletConnect.connected) {
        blockchain.web3 = blockchain.walletConnect.web3
      }
      if (blockchain.walletLink.connected) {
        blockchain.web3 = blockchain.walletLink.web3
      }
      if (blockchain.fortmatic.connected) {
        blockchain.web3 = blockchain.fortmatic.web3
      }
      if (blockchain.portis.connected) {
        blockchain.web3 = blockchain.portis.web3
      }
      let defaultNSubdomain =
        process.env.REACT_APP_DEFAULT_NETWORK === 'main'
          ? 'mainnet'
          : process.env.REACT_APP_DEFAULT_NETWORK
      const { INFURA_KEY } = config.refreshBlockchainInfraInfo()
      const INFURA_ENDPOINT = `wss://${defaultNSubdomain}.infura.io/ws/v3/${INFURA_KEY}`
      if (blockchain.web3 == null) {
        if (INFURA_ENDPOINT.includes('http')) {
          blockchain.web3 = new Web3(
            new Web3.providers.HttpProvider(INFURA_ENDPOINT)
          )
        } else {
          blockchain.web3 = new Web3(
            new Web3.providers.WebsocketProvider(INFURA_ENDPOINT)
          )
        }
      }
      blockchain.network = await blockchain.web3.eth.net.getNetworkType()
      // window.web3 = blockchain.web3
      console.log('blockchain', blockchain)
      const isProd = process.env.REACT_APP_APP_ENV === 'prod'
      if (isProd && blockchain.network !== 'main') {
        return window.alert('You are not on mainnet! Switch back to mainnet!')
      }
      const networkToUse = isProd
        ? process.env.REACT_APP_DEFAULT_NETWORK
        : blockchain.network || process.env.REACT_APP_DEFAULT_NETWORK
      const hodlDexInfraInfo = {
        blockchain: process.env.REACT_APP_BLOCKCHAIN,
        version: process.env.REACT_APP_CONTRACT_VERSION,
        network: networkToUse
      }
      localStorage.setItem('hodlDexInfraInfo', JSON.stringify(hodlDexInfraInfo))
      return resolve(blockchain)
    } catch (e) {
      const hodlDexInfraInfo = {
        blockchain: process.env.REACT_APP_BLOCKCHAIN,
        version: process.env.REACT_APP_CONTRACT_VERSION,
        network: process.env.REACT_APP_DEFAULT_NETWORK
      }
      localStorage.setItem('hodlDexInfraInfo', JSON.stringify(hodlDexInfraInfo))
      reject(e)
    }
  })
}

const connectWithWalletConnect = async (blockchain) => {
  try {
    const provider = new WalletConnectProvider({ infuraId: INFURAKEY })
    const connected = await provider.enable() // Enable session (triggers QR Code modal)
    if (connected) {
      logger.log('connected with WALLETCONNECT')
      localStorage.setItem('currentBlockchainWallet', WALLETCONNECT)
      disconnectWithMetaMask()
      // await disconnectWithWalletLink()
      // update environment
      refreshBlockchainEnvironment()
    }
  } catch (e) {
    logger.error(e)
  }
}

const connectWithWalletLink = async (blockchain) => {
  try {
    const walletLink = new WalletLink({
      appName: 'HODL Dex',
      appLogoUrl: 'https://svgshare.com/i/TpS.svg',
      darkMode: false
    })
    const CHAIN_ID = 1
    const provider = walletLink.makeWeb3Provider(INFURAKEY, CHAIN_ID)
    const connected = await provider.enable() // Enable session (triggers QR Code modal)
    if (connected) {
      logger.log('connected with WALLETLINK')
      localStorage.setItem('currentBlockchainWallet', WALLETLINK)
      // disconnect with metamask
      disconnectWithMetaMask()
      // disconnect with wallet connect
      // await disconnectWithWalletConnect()
      // update environment
      refreshBlockchainEnvironment()
    }
  } catch (e) {
    logger.error(e)
  }
}

const connectWithFortmatic = async (blockchain) => {
  try {
    const fm = new Fortmatic(FORTMATICKEY)
    const provider = fm.getProvider()
    const connected = await provider.enable() // Enable session (triggers QR Code modal)
    if (connected) {
      logger.log('connected with Fortmatic')
      localStorage.setItem('currentBlockchainWallet', FORTMATIC)
      disconnectWithMetaMask()
      refreshBlockchainEnvironment()
    }
  } catch (e) {
    logger.error(e)
  }
}

// const connectWithPortis = async (blockchain) => {
//   try {
//     const network =
//       process.env.REACT_APP_APP_ENV === 'prod' ? 'mainnet' : 'goerli'
//     // const portis = new Portis(PORTISKEY, network)
//     // const provider = portis.provider
//     // const connected = await provider.enable() // Enable session (triggers QR Code modal)
//     // if (connected) {
//     //   logger.log('connected with Portis')
//     //   localStorage.setItem('currentBlockchainWallet', PORTIS)
//     //   disconnectWithMetaMask()
//     //   refreshBlockchainEnvironment()
//     // }
//   } catch (e) {
//     logger.error(e)
//   }
// }

const disconnectWithMetaMask = () => {
  logger.log('disconnectWithMetaMask')
  // localStorage.removeItem('currentBlockchainWallet')
}
export default {
  initBlockchainEnvironment,
  connectWithMetaMask,
  connectWithWalletConnect,
  disconnectWithWalletConnect,
  connectWithWalletLink,
  disconnectWithWalletLink,
  connectWithFortmatic,
  disconnectWithFortmatic
  // connectWithPortis,
  // disconnectWithPortis
}
