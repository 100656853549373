import config from './config'
import converters from './converters'
import web3instance from './web3'
import BigNumber from 'bignumber.js'
import { getEstimatedGas } from '../utils/trade/buyHodlc'
import InputDataDecoder from 'ethereum-input-data-decoder'
import _ from 'lodash'
// userData.balanceeth is a string already
// fromWei eats String and return string
import {
  depositEth,
  withdrawEth,
  hodlTIssue,
  approveHodlT,
  hodlTRedeem,
  claimDistributionEmitter,
  depositEthReturnImmediately
} from './main/wallet'
const HodlDexContract = require('./contracts/eth/v1/HodlDex.json')
const decoder = new InputDataDecoder(HodlDexContract.abi)
const { DEFAULT_LOW_GAS, DEFAULT_SELL_GAS } = config

const connector = {
  converters,
  depositEth,
  withdrawEth,
  hodlTIssue,
  approveHodlT,
  hodlTRedeem,
  claimDistributionEmitter,
  depositEthReturnImmediately,
  getBalanceInOldDex: async (web3) => {
    web3 = web3instance(web3)
    const accounts = await web3.eth.getAccounts()
    if (accounts.length === 0) return 0
    const HodlDexContract = require('./contracts/eth/v1/HodlDex.json')
    const HODLDEX_ADDR = '0x56b9d34F9f4E4A1A82D847128c1B2264B34D2fAe'
    if (HODLDEX_ADDR.length < 10) return
    if (web3.utils) {
      const address = web3.utils.toChecksumAddress(HODLDEX_ADDR)
      if (address) {
        const oldDexInstance = new web3.eth.Contract(
          HodlDexContract.abi,
          address
        )
        const userData = await oldDexInstance.methods.user(accounts[0]).call()
        return BigNumber(
          web3.utils.fromWei(userData.balanceEth, 'ether')
        ).toFixed(18)
      } else {
        // window.alert('Old HodlDex contract not deployed to detected network.')
        return { error: true }
      }
    }
  },
  withDrawFromOldDex: (web3) => {
    return new Promise(async (resolve, reject) => {
      web3 = web3instance(web3)
      const HodlDexContract = require('./contracts/eth/v1/HodlDex.json')
      const HODLDEX_ADDR = '0x56b9d34F9f4E4A1A82D847128c1B2264B34D2fAe'
      try {
        if (web3.utils) {
          const address = web3.utils.toChecksumAddress(HODLDEX_ADDR)
          const accounts = await web3.eth.getAccounts()
          if (address) {
            const oldDexInstance = new web3.eth.Contract(
              HodlDexContract.abi,
              address
            )
            const userData = await oldDexInstance.methods
              .user(accounts[0])
              .call()
            const ethBalanceInOldDex = web3.utils.fromWei(
              userData.balanceEth.toString(),
              'ether'
            )
            const etherInWei = web3.utils.toWei(
              ethBalanceInOldDex.toString(),
              'ether'
            )
            oldDexInstance.methods
              .withdrawEth(etherInWei)
              .send({ from: accounts[0] })
              .then(() => {
                return resolve(ethBalanceInOldDex)
              })
              .catch(reject)
          }
        }
      } catch (err) {
        console.log('[withdrawEth]', err)
        reject(err)
      }
    })
  },
  getCurrentRate: (web3) => {
    // This is directlty called in the Trade page
    web3 = web3instance(web3)
    try {
      return new Promise(async (resolve, reject) => {
        try {
          const eth = await converters.convertEthToUsd(web3, '1')
          const hodlc = await converters.convertHodlToUsd(web3, '1')
          if (eth && hodlc) {
            resolve({ eth, hodlc })
          }
        } catch (e) {
          reject(e)
        }
      })
    } catch (err) {
      console.log('[getCurrentRate]', err)
    }
  },
  getHODLRate: (web3) => {
    web3 = web3instance(web3)
    try {
      return new Promise(async (resolve, reject) => {
        try {
          const instance = config.HodlDex(web3)
          const ratesFromContract = await instance.methods.rates().call()
          const ratesFromContractInDollars = web3.utils.fromWei(
            ratesFromContract.hodlUsd.toString(),
            'ether'
          )
          const reserveBalanceFromContract = await instance.methods
            .user(config.refreshBlockchainInfraInfo().HODLDEX_ADDR)
            .call()
          const reserveBalanceFromContractInHodlc = web3.utils.fromWei(
            reserveBalanceFromContract.balanceHodl.toString(),
            'ether'
          )
          if (ratesFromContractInDollars) {
            resolve({
              rates: ratesFromContractInDollars,
              reserveBalanceHodlc: reserveBalanceFromContractInHodlc
            })
          }
        } catch (e) {
          reject(e)
        }
      })
    } catch (err) {
      console.log('[getHODLRate', err)
    }
  },
  getUserDistributionBalances: (web3, account) => {
    web3 = web3instance(web3)
    return new Promise(async (resolve, reject) => {
      const instance = config.HodlDex(web3)
      const hodlTinstance = config.HodlT(web3)
      if (instance && hodlTinstance) {
        try {
          const ethClaim = await instance.methods
            .nextUserDistributionDetails(
              account,
              '0x4c10068c4e8f0b2905447ed0a679a3934513092c8a965b7a3d1ea67ea1cd0698'
            )
            .call()
          const ethDistAmt = web3.utils.fromWei(ethClaim[0], 'ether')
          const hodlClaim = await instance.methods
            .nextUserDistributionDetails(
              account,
              '0xf0a5429dabc7f51d218d3119bc4ca776cb42abb5725ccd62a1577f01f196508e'
            )
            .call()
          const hodlDistAmt = web3.utils.fromWei(hodlClaim[0], 'ether')
          const balances = {
            ethDistAmt: new BigNumber(ethDistAmt),
            hodlDistAmt: new BigNumber(hodlDistAmt)
          }
          resolve(balances)
        } catch (err) {
          reject(err)
        }
      }
    })
  },
  getUserBalances: (web3, account) => {
    web3 = web3instance(web3)
    try {
      return new Promise(async (resolve, reject) => {
        const instance = config.HodlDex(web3)
        const hodlTinstance = config.HodlT(web3)
        if (instance && hodlTinstance) {
          try {
            const userData = await instance.methods.user(account).call()
            const hodlTBalance = await hodlTinstance.methods
              .balanceOf(account)
              .call()
            const { HTOKEN_RESERVE_PROXY } = config.refreshBlockchainInfraInfo()
            const redeemPower = await hodlTinstance.methods
              .allowance(account, HTOKEN_RESERVE_PROXY)
              .call()
            const ethBalance = web3.utils.fromWei(userData.balanceEth, 'ether')
            const hodlcBalance = web3.utils.fromWei(
              userData.balanceHodl,
              'ether'
            )
            const hodlt = web3.utils.fromWei(hodlTBalance, 'ether')
            const redeem = web3.utils.fromWei(redeemPower, 'ether')

            const wallet = {
              eth: new BigNumber(ethBalance),
              ethinUSD: await converters.convertEthToUsd(web3, ethBalance),
              hodlc: new BigNumber(hodlcBalance),
              hodlcinUSD: await converters.convertHodlToUsd(web3, hodlcBalance),
              hodlt: new BigNumber(hodlt),
              redeemPower: new BigNumber(redeem)
            }
            resolve(wallet)
          } catch (err) {
            reject(err)
          }
        }
      })
    } catch (err) {
      console.log('[getUserBalances]', err)
    }
  },
  getEvents: (web3, from = 0, to = 'latest', addr = 'my') => {
    web3 = web3instance(web3)
    const TYPES = {
      UserDepositEth: 'deposit',
      UserWithdrawEth: 'withdraw',
      HodlTIssued: 'To HODLT',
      HodlTRedeemed: 'To HODLC',
      SellOrderFilled: 'SellOrderFilled', // buy event happended
      BuyOrderFilled: 'BuyOrderFilled', // sell event happended
      SellOrderRefunded: 'SellOrderRefunded',
      BuyOrderRefunded: 'BuyOrderRefunded',
      SellOrderOpened: 'SellOrderOpened',
      BuyOrderOpened: 'BuyOrderOpened',
      BuyFromReserve: 'BuyFromReserve',
      SellOrderCancelled: 'SellOrderCancelled',
      BuyOrderCancelled: 'BuyOrderCancelled'
    }
    const UNIT = {
      UserDepositEth: 'ETH',
      UserWithdrawEth: 'ETH',
      HodlTIssued: 'HODLC',
      HodlTRedeemed: 'HODLC',
      SellOrderFilled: 'ETH', // buy event happended
      BuyOrderFilled: 'HODLC', // sell event happended
      SellOrderRefunded: 'HODLC',
      BuyOrderRefunded: 'ETH',
      SellOrderOpened: 'HODLC',
      BuyOrderOpened: 'ETH',
      BuyFromReserve: 'HODLC',
      SellOrderCancelled: 'HODLC',
      BuyOrderCancelled: 'ETH'
    }
    const USER = {
      UserDepositEth: 'user',
      UserWithdrawEth: 'user',
      HodlTIssued: 'user',
      HodlTRedeemed: 'user',
      SellOrderFilled: 'buyer',
      BuyOrderFilled: 'seller',
      SellOrderRefunded: 'seller',
      BuyOrderRefunded: 'seller',
      SellOrderOpened: 'seller',
      BuyOrderOpened: 'buyer',
      BuyFromReserve: 'buyer',
      SellOrderCancelled: 'userAddr',
      BuyOrderCancelled: 'userAddr'
    }
    const AMOUNT = {
      UserDepositEth: (a) =>
        web3.utils.fromWei(a.returnValues['amountEth'].toString(), 'ether'),
      UserWithdrawEth: (a) =>
        web3.utils.fromWei(a.returnValues['amountEth'].toString(), 'ether'),
      HodlTIssued: (a) =>
        web3.utils.fromWei(a.returnValues['amountHodl'].toString(), 'ether'),
      HodlTRedeemed: (a) =>
        web3.utils.fromWei(a.returnValues['amountHodl'].toString(), 'ether'),
      SellOrderFilled: (a) =>
        web3.utils.fromWei(a.returnValues['txnEth'].toString(), 'ether'),
      BuyOrderFilled: (a) =>
        web3.utils.fromWei(a.returnValues['txnHodl'].toString(), 'ether'),
      SellOrderRefunded: (a) =>
        web3.utils.fromWei(a.returnValues['refundedHodl'].toString(), 'ether'),
      BuyOrderRefunded: (a) =>
        web3.utils.fromWei(a.returnValues['refundedEth'].toString(), 'ether'),
      SellOrderOpened: (a) =>
        web3.utils.fromWei(a.returnValues['quantityHodl'].toString(), 'ether'),
      BuyOrderOpened: (a) =>
        web3.utils.fromWei(a.returnValues['amountEth'].toString(), 'ether'),
      BuyFromReserve: (a) =>
        web3.utils.fromWei(a.returnValues['txnHodl'].toString(), 'ether'),
      SellOrderCancelled: async (a) => {
        const orderId = a.returnValues['orderId']
        const blockNumber = a.blockNumber - 1
        const instance = config.HodlDex(web3)
        const details = await instance.methods
          .sellOrder(orderId)
          .call({}, blockNumber)
        const amount = details[1].toString()
        return web3.utils.fromWei(amount, 'ether')
      },
      BuyOrderCancelled: async (a) => {
        const orderId = a.returnValues['orderId']
        const blockNumber = a.blockNumber - 1
        const instance = config.HodlDex(web3)
        const details = await instance.methods
          .buyOrder(orderId)
          .call({}, blockNumber)
        const amount = details[1].toString()
        return web3.utils.fromWei(amount, 'ether')
      }
    }
    return new Promise(async (resolve, reject) => {
      try {
        const history = []
        const instance = config.HodlDex(web3)
        const accounts = await web3.eth.getAccounts()
        const filter = {
          fromBlock: from,
          toBlock: to,
          filter: {}
        }
        if (addr === 'my' && accounts.length === 0) return resolve([])
        if (addr === 'my' && accounts.length > 0) {
          filter.filter = {
            user: accounts[0],
            seller: accounts[0],
            buyer: accounts[0],
            userAddr: accounts[0] // for SellOrderCancelled/BuyOrderCancelled events
          }
        }
        if (instance) {
          // Event getPastEvents DOCS: https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#getpastevents
          instance
            .getPastEvents('UserDepositEth', filter, (err, evts) =>
              history.push(...evts)
            )
            .then(() =>
              instance.getPastEvents('UserWithdrawEth', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('HodlTIssued', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('HodlTRedeemed', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('SellOrderFilled', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('BuyOrderFilled', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('SellOrderRefunded', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('BuyOrderRefunded', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('SellOrderOpened', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('BuyOrderOpened', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('BuyFromReserve', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents(
                'SellOrderCancelled',
                filter,
                (err, evts) => history.push(...evts)
              )
            )
            .then(() =>
              instance.getPastEvents('BuyOrderCancelled', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(async () => {
              const cleanup = []
              let d = {}
              // eslint-disable-next-line no-unused-vars
              for (const e of history) {
                const userAddr = e.returnValues[USER[e.event]]
                  ? e.returnValues[USER[e.event]].toString()
                  : ''
                d = {
                  type: TYPES[e.event],
                  transactionHash: e.transactionHash,
                  txlink: `${config.explorerBaseURI()}/tx/${e.transactionHash}`,
                  blocklink: `${config.explorerBaseURI()}/block/${
                    e.blockNumber
                  }`,
                  amount: await AMOUNT[e.event](e),
                  blockNumber: e.blockNumber,
                  unit: UNIT[e.event],
                  userAddr: userAddr,
                  userAddrlink: `${config.explorerBaseURI()}/address/${userAddr}`
                }
                cleanup.push(d)
              }
              const r = cleanup.sort((a, b) => b.blockNumber - a.blockNumber)
              r.total = r.length
              // from latest to oldest
              return resolve(r)
            })
            .catch(console.error)
        } else {
          return resolve([])
        }
      } catch (err) {
        console.log('[getEvents]', err)
      }
    })
  },
  getBuyEvents: (web3, from = 0, to = 'latest', addr = 'my') => {
    web3 = web3instance(web3)
    return new Promise(async (resolve, reject) => {
      try {
        const history = []
        const instance = config.HodlDex(web3)
        const accounts = await web3.eth.getAccounts()
        const filter = {
          fromBlock: from,
          toBlock: to,
          filter: {}
        }
        if (addr === 'my' && accounts.length === 0) return resolve([])
        if (addr === 'my' && accounts.length > 0) {
          filter.filter = {
            user: accounts[0],
            seller: accounts[0],
            buyer: accounts[0]
          }
        }
        if (instance) {
          // Event getPastEvents DOCS: https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#getpastevents
          instance
            .getPastEvents('SellOrderFilled', filter, (err, evts) =>
              history.push(...evts)
            )
            .then(() =>
              instance.getPastEvents('BuyOrderFilled', filter, (err, evts) =>
                history.push(...evts)
              )
            )
            .then(() => {
              const cleanup = []
              let d = {}
              // eslint-disable-next-line no-unused-vars
              for (const e of history) {
                d = {
                  transactionHash: e.transactionHash
                }
                cleanup.push(d)
              }
              const r = cleanup.sort((a, b) => b.blockNumber - a.blockNumber)
              r.total = r.length
              // from latest to oldest
              return resolve(r.length)
            })
            .catch(console.error)
        } else {
          return resolve([])
        }
      } catch (err) {
        console.log('[getEvents]', err)
      }
    })
  },
  // feed from component - get current orders - top 10
  buyHodlC: async (web3, amountInEther = 0, amountInHodl, orderData) => {
    return new Promise(async (resolve, reject) => {
      const instance = config.HodlDex(web3)
      const etherInWei = web3.utils.toWei(amountInEther, 'ether')
      const accounts = await web3.eth.getAccounts()
      if (accounts.length === 0) return
      instance.methods
        .buyHodlC(etherInWei, DEFAULT_LOW_GAS)
        .send({
          from: accounts[0],
          gas: getEstimatedGas(web3, amountInHodl, orderData)
        })
        .on('receipt', function (receipt) {
          resolve(receipt)
        })
        .on('error', function (err) {
          console.log('error', err)
          reject(err)
        })
    })
  },
  getOrderMaxLimit: async (web3) => {
    try {
      const instance = config.HodlDex(web3)
      const inWei = await instance.methods.orderLimit().call()
      if (inWei === 0) return Number.MAX_SAFE_INTEGER
      return web3.utils.fromWei(inWei.toString(), 'ether')
    } catch (err) {
      console.log('[getOrderMaxLimit]', err)
    }
  },
  sellHodlC: async (web3, amountHodlc = 0, lowGas = DEFAULT_LOW_GAS) => {
    console.log('sellHodl')
    return new Promise(async (resolve, reject) => {
      const instance = config.HodlDex(web3)
      const etherInWei = web3.utils.toWei(amountHodlc, 'ether')
      const accounts = await web3.eth.getAccounts()
      if (accounts.length === 0) return
      instance.methods
        .sellHodlC(etherInWei, lowGas)
        .send({
          from: accounts[0],
          gas: DEFAULT_SELL_GAS
        })
        .on('receipt', function (receipt) {
          resolve(receipt)
        })
        .on('error', function (err) {
          console.log('error', err)
          reject(err)
        })
    })
  },
  cancelbuy: async (web3, orderId) => {
    console.log('cancelbuy')
    return new Promise(async (resolve, reject) => {
      const instance = config.HodlDex(web3)
      const accounts = await web3.eth.getAccounts()
      if (accounts.length === 0) return
      instance.methods
        .cancelBuy(orderId)
        .send({
          from: accounts[0]
        })
        .on('receipt', function (receipt) {
          console.log('receipt', receipt)
          resolve(receipt)
        })
        .on('error', function (err) {
          console.log('error', err)
          reject(err)
        })
    })
  },
  cancelsell: async (web3, orderId) => {
    console.log('cancelsell')
    return new Promise(async (resolve, reject) => {
      const instance = config.HodlDex(web3)
      const accounts = await web3.eth.getAccounts()
      if (accounts.length === 0) return
      instance.methods
        .cancelSell(orderId)
        .send({
          from: accounts[0]
        })
        .on('receipt', function (receipt) {
          console.log('receipt', receipt)
          resolve(receipt)
        })
        .on('error', function (err) {
          console.log('error', err)
          reject(err)
        })
    })
  },
  getDexData: async (web3) => {
    web3 = web3instance(web3)
    return new Promise(async (resolve, reject) => {
      try {
        const instance = config.HodlDex(web3)
        const reserveBalanceFromContract = await instance.methods
          .user(config.refreshBlockchainInfraInfo().HODLDEX_ADDR)
          .call()
        const reserveBalanceFromContractInHodlc = web3.utils.fromWei(
          reserveBalanceFromContract.balanceHodl.toString(),
          'ether'
        )
        const ratesFromContract = await instance.methods.rates().call()
        const ratesFromContractInDollars = web3.utils.fromWei(
          ratesFromContract.hodlUsd.toString(),
          'ether'
        )
        // Explorer might need these
        const { HODLDEX_ADDR } = config.refreshBlockchainInfraInfo()
        // const hodlerCount = await instance.methods.hodlerCount().call();
        const contractBalanceFromContract = await web3.eth.getBalance(
          HODLDEX_ADDR
        )
        const contractBalanceEth = BigNumber(
          web3.utils.fromWei(contractBalanceFromContract.toString(), 'ether')
        ).toFixed(2)
        // const distributionsLength = await instance.methods.distributionsLength().call()
        const result = {
          // Explorer might need these
          // hodlerCount,
          contractBalanceEth,
          rates: ratesFromContractInDollars,
          reserveBalanceHodlc: reserveBalanceFromContractInHodlc
        }
        return resolve(result)
      } catch (err) {
        console.log('[getDexData]', err)
      }
    })
  },
  getTransaction: async (web3, hash) => {
    web3 = web3instance(web3)
    return await web3.eth
      .getTransaction(hash)
      .then((res) => {
        // starting with event names, event variable names, values, for all events even internal ones or ones called from other contracts, plus other information like blocknumber, blocktime, msg.sender, to address.
        const input = decoder.decodeData(res.input)
        const HODLCAmt = web3.utils.fromWei(input.inputs[0], 'ether')
        const eventName = input.method
        const txnData = {
          event: eventName,
          HODLCvalue: HODLCAmt,
          gasPrice: [
            web3.utils.fromWei(res.gasPrice, 'ether'),
            Number(res.gasPrice) / 1000000000
          ],
          ..._.pick(res, ['blockHash', 'blockNumber', 'from', 'to', 'gas'])
        }
        return txnData
      })
      .catch((err) => console.log(err))
  }
}

export default connector
