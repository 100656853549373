import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart,
  ResponsiveContainer
} from 'recharts'
const TxnChart = ({ txnData, t }) => {
  return (
    <ResponsiveContainer height={400}>
      <BarChart
        data={txnData}
        // margin={{
        //   top: 20
        // }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="timeStamp" />
        <YAxis yAxisId="left" orientation="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend
          margin={{
            top: 20
          }}
        />
        <Bar
          barSize={20}
          stackId="a"
          dataKey="ETHTransactions"
          fill="#967cf3"
          yAxisId="left"
          name={t('graph.graph2.dataKey1')}
        />
        <Bar
          barSize={20}
          stackId="a"
          dataKey="EOSTransactions"
          fill="#474747"
          yAxisId="left"
          name={t('graph.graph2.dataKey2')}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default withTranslation()(TxnChart)
