import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { connect } from 'react-redux'
import { getAllETHPrice } from '../../redux/actions'
import web3instance from '../../connector/web3'
import globalConfig from '../../global-config'

const TOTAL_SUPPLY = 20000000
const MILIION = 1000000

const MarketInfo = ({
  t,
  reserveBalanceHodlc,
  rates,
  getAllETHPrice,
  web3
}) => {
  const [percentage, setPercentage] = useState(0)
  useEffect(() => {
    const localWeb3 = web3instance(web3)
    getAllETHPrice(localWeb3, { return: true, lengthInDays: 7 }).then((res) => {
      const change = (((Number(rates) - res) / res) * 100).toFixed(3)
      setPercentage(change)
    })
  }, [rates])

  return (
    <>
      <div className="item">
        <div className="category">{t('marketInfo.items.reserve')}</div>
        <div className="data">
          {BigNumber(reserveBalanceHodlc).dividedBy(MILIION).toFixed(2)}M HODLC
        </div>
      </div>
      <div className="item">
        <div className="category">{t('marketInfo.items.marketcap')}</div>
        <div className="data">
          ${BigNumber(TOTAL_SUPPLY).times(rates).dividedBy(MILIION).toFixed(2)}M
        </div>
      </div>
      <div className="item">
        <div className="category">{t('marketInfo.items.circulation')}</div>
        <div className="data">
          {`${BigNumber(TOTAL_SUPPLY)
            .minus(reserveBalanceHodlc)
            .dividedBy(MILIION)
            .toFixed(2)}M HODLC ($${BigNumber(TOTAL_SUPPLY)
            .minus(reserveBalanceHodlc)
            .dividedBy(MILIION)
            .times(rates)
            .toFixed(2)}M)`}
        </div>
      </div>
      <div className="item">
        <div className="category">{t('marketInfo.items.hodlcPrice')}</div>
        <div className="data">
          {/* ${BigNumber(rates).toFixed(4)}{' '} */}
          ${globalConfig.hodlcPrice}{' '}
          <span className="price-percentage-indicator">{`+${percentage} %`}</span>
          <span className="price-percentage-indicator-length">7d</span>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ graph, blockchain }) => {
  const data = graph.price
  const lastPrice = data[data.length - 1].HODOLprice
  return {
    price: lastPrice,
    web3: blockchain.web3
  }
}

export default connect(mapStateToProps, { getAllETHPrice })(
  withTranslation()(MarketInfo)
)
